/**
 *
 * CartSummary
 *
 */

import React from "react";

//import { Container, Row, Col } from 'reactstrap';

const CartSummary = (props) => {
  const { cartTotal } = props;

  return (
    <div className="cart-summary">
      <div class="container">
        <div className="mb-2 summary-item row">
          <div xs="9" class="col-9">
            <p className="summary-label">Free Shippling</p>
          </div>
          <div xs="3" className="text-right col=3">
            <p className="summary-value">$0</p>
          </div>
        </div>
        <div className="mb-2 summary-item row">
          <div xs="9" class="col-9">
            <p className="summary-label">Total</p>
          </div>
          <div xs="3" className="text-right col-3">
            <p className="summary-value">${cartTotal}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
