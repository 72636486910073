import React, { useContext, useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";
import SelectProductType from "../../../components/SelectProductType";
import SelectProductVidFolders from "../../../components/SelectProductVidFolders";

import { useMediaQuery } from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";

import { Card, CardContent, Box } from "@mui/material";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";

import { getURLAPI } from "../../../utils/getURL";

import { default as Spinner } from "../../../components/Spinner";

const ProductVidItem = () => {
  const productvid_item = useSelector(
    (state) => state.app.body.productvid_item
  );
  const auth = useContext(AuthContext);
  const body = useSelector((state) => state.app.body);
  const dispatch = useDispatch();
  const translate = useTranslate();
  const access = useSelector((state) => state.app.access.access);

  const nameTheme = access && access.user && access.user.theme;

  let greybkcolor = "rgba(255,255,255,.3)";

  const [subPages, setSubPages] = useState({ page: "main" });

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')
  const [startRefreshFilter, setStartRefreshFilter] = useState(true);
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  const item_id = body.item_id;

  const readonly = body.readonly;

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result

    if (tableName === "Tovs" && action.metod && action.metod === "getOne") {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "productvid_item",
      });
      dispatch({
        type: "fetch/get_add",
        payload: readonly,
        ob: "readonly",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true);
  };

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Vts",
        //where: { id: item_id },
        action: {
          metod: "get",
          // metod: 'getCustomerMessages',
        },
      },
    ],
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Tovs",
        where: { id: item_id },
        action: {
          metod: "getOne",
          isBlocked: true,
        },
      },
    ],
  };

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client && ws_client.deblock({ tableName: "Tovs", id: item_id });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && startRefreshFilter) {
    setStartRefreshFilter(false);
    ws_client.open_process(paramsFilter);
  }

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    setStartRefresh(false);
    ws_client.open_process(params);
  }

  //END get all str from table Roles

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  const getChange = () => {
    if (productvid_item && productvid_item.change === true) return "success";
    else return "outline-success";
  };
  return (
    <Form>
      <Box maxWidth="50em">
        <Card>
          <CardContent>
            <>
              <div
                //            className="hstack gap-2"
                style={{
                  marginTop: -20,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  //id="outlined-basic"
                  label="Виды номенклатуры"
                  //variant="standard"
                  className="title h6"
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {translate("resources.product.title")}
                </Typography>
              </div>

              <div
                //            className="hstack gap-2"
                style={
                  {
                    //            marginTop: -20,
                    //            display: 'flex',
                    //          justifyContent: 'space-between',
                  }
                }
              >
                {productvid_item && (
                  <>
                    <TextField
                      id="outlined-basic1"
                      label={translate("resources.product.fields.vid")} //"Коментарии"
                      //variant="standard"
                      variant="outlined"
                      style={{
                        marginBottom: 15,
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: greybkcolor,
                      }}
                      value={
                        ((productvid_item &&
                          productvid_item.vts &&
                          productvid_item.vts.parent &&
                          productvid_item.vts.parent.name + "    *") ||
                          "") +
                          (productvid_item.vts && productvid_item.vts.name) ||
                        ""
                      }
                    ></TextField>
                    <TextField
                      id="outlined-basic2"
                      label={translate("resources.productvid.fields.name")} //"Наименование"
                      //variant="standard"
                      variant="outlined"
                      style={{
                        marginBottom: 15,
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: greybkcolor,
                      }}
                      value={(productvid_item && productvid_item.name) || ""}
                    ></TextField>
                  </>
                )}
              </div>
              <Button
                style={{ marginLeft: 5 }}
                variant="outline-success"
                as={Link}
                to="/pages/productvid/list"
              >
                {translate("resources.button.return")}
              </Button>
            </>
          </CardContent>
        </Card>
      </Box>
    </Form>
  );
};

export default ProductVidItem;
