import React, { useContext, useState, useEffect } from "react";
//import { Button, Form } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";
import { AuthContext } from "../../../context/AuthContext";

import Button from "@mui/material/Button";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

import { Card, CardContent, Box } from "@mui/material";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";

import { getURLAPI } from "../../../utils/getURL";

import { default as Spinner } from "../../../components/Spinner";

const TovList = () => {
  const tov_item = useSelector((state) => state.app.body.tov_item);
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();

  const config = auth.config;
  let URLAPI = getURLAPI(config);

  //START get all str from table Roles
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  const item_id = body.item_id;

  const readonly = body.readonly || false;

  //  const [startWss, setstartWss] = useState(true)

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result)
    dispatch({
      type: "fetch/get_add",
      payload: result,
      ob: "tov_item",
    });
    dispatch({
      type: "fetch/get_add",
      payload: readonly,
      ob: "readonly",
    });
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true);
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Tovs",
        where: { id: item_id },
        action: {
          metod: "getOne",
          isBlocked: true,
        },
      },
    ],
  };

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client && ws_client.deblock({ tableName: "Tovs", id: item_id });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params);
    setStartRefresh(false);
  }

  //END get all str from table Roles

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  const getChange = () => {
    if (tov_item && tov_item.change === true) return "contained";
    else return "outlined";
    //   if (tov_item && tov_item.change === true) return 'success'
    //    else return 'outline-success'
  };
  const getAccessToChange = () => {
    return readonly || false;
  };

  const ready = () => {
    if (tov_item) return true;
    else return false;
  };

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="30em">
            <Card>
              <CardContent>
                <>
                  <div
                    //            className="hstack gap-2"
                    style={{
                      marginTop: -20,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      //id="outlined-basic"
                      label="Тип"
                      //variant="standard"
                      className="title h6"
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        marginRight: 150,
                        //display: 'flex',
                        //justifyContent: 'space-between',
                      }}
                    >
                      {translate("resources.tov.title")}
                    </Typography>

                    {readonly && (
                      <Typography
                        //id="outlined-basic"
                        label="Title"
                        //variant="standard"
                        //className="title h6"
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          //  marginRight: 275,
                          color: "red",
                        }}
                        //                      {translate('resources.workers.readonly')}
                      >
                        Readonly
                      </Typography>
                    )}
                  </div>

                  <div
                    //            className="hstack gap-2"
                    style={
                      {
                        //            marginTop: -20,
                        //            display: 'flex',
                        //          justifyContent: 'space-between',
                      }
                    }
                  >
                    {tov_item && (
                      <>
                        <div
                          //            className="hstack gap-2"
                          style={{
                            marginTop: 0,
                            display: "flex",
                            //justifyContent: 'space-between',
                          }}
                        >
                          <TextField
                            id="outlined-basic1"
                            label={translate("resources.tov.fields.name")} //"Тип работ"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                            }}
                            value={(tov_item && tov_item.name) || ""}
                            onChange={(item) => {
                              if (!getAccessToChange()) {
                                tov_item.change = true;
                                dispatch({
                                  type: "app/set",
                                  payload: {
                                    id: tov_item,
                                    field: "name",
                                    value: item.target.value,
                                  },
                                  ob: "tov_item",
                                });
                              }
                            }}
                          ></TextField>
                          <FormControlLabel
                            style={{
                              marginRight: isXSmall ? 10 : 10,
                              marginLeft: isXSmall ? 20 : 20,
                              marginBottom: 10,
                            }}
                            control={
                              <Switch
                                id={tov_item && tov_item.id}
                                name={"DopInfo"}
                                color="primary"
                                //onChange={handleChangeDopInfo}
                                onChange={(item) => {
                                  if (!getAccessToChange()) {
                                    tov_item.change = true;
                                    dispatch({
                                      type: "app/set",
                                      payload: {
                                        id: tov_item,
                                        field: "top",
                                        value: !tov_item.top,
                                      },
                                      ob: "tov_item",
                                    });
                                  }
                                }}
                                //                          onFocus={onFocus}
                                checked={tov_item && tov_item.top}
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                              />
                            }
                            label={translate("resources.switch.top")}
                          />
                        </div>
                        <div
                          //            className="hstack gap-2"
                          style={{
                            marginTop: 0,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <TextField
                            id="outlined-basic2"
                            label={translate("resources.tov.fields.stock")} //"Тип работ"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            value={(tov_item && tov_item.stock) || ""}
                            onChange={(item) => {
                              if (!getAccessToChange()) {
                                tov_item.change = true;
                                dispatch({
                                  type: "app/set",
                                  payload: {
                                    id: tov_item,
                                    field: "stock",
                                    value: item.target.value,
                                  },
                                  ob: "tov_item",
                                });
                              }
                            }}
                          ></TextField>
                          <TextField
                            id="outlined-basic3"
                            label={translate("resources.tov.fields.tovunit")} //"Тип работ"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            value={(tov_item && tov_item.tovunit) || ""}
                            onChange={(item) => {
                              if (!getAccessToChange()) {
                                tov_item.change = true;
                                dispatch({
                                  type: "app/set",
                                  payload: {
                                    id: tov_item,
                                    field: "tovunit",
                                    value: item.target.value,
                                  },
                                  ob: "tov_item",
                                });
                              }
                            }}
                          ></TextField>
                        </div>
                      </>
                    )}
                  </div>
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    //variant="outlined"
                    sx={{ display: "inline-flex", alignItems: "center" }}
                    variant={getChange()} //"outline-success"
                    to={{
                      pathname: "/pages/tov/list",
                    }}
                    //state={tov_item}
                    disabled={getAccessToChange()}
                    onClick={async () => {
                      try {
                        if (!getAccessToChange())
                          await ws_client.wss_setItem({
                            tableName: "Tovs",
                            item: tov_item,
                            call_nm: "Model_update",
                            where: { id: tov_item.id },
                            //action: { metod: 'set' },
                          });
                      } catch (e) {
                        console.log("errr e", e);
                      }
                    }}
                  >
                    {translate("resources.button.save")}
                  </Button>

                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    //variant="outlined"
                    sx={{ display: "inline-flex", alignItems: "center" }}
                    style={{ marginLeft: 5 }}
                    variant="outlined" //"outline-danger"
                    to="/pages/tov/list"
                  >
                    {translate("resources.button.cancel")}
                  </Button>
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="30em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default TovList;
