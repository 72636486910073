import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

//import {  ListGroup, Row, Col } from 'react-bootstrap'
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { useTable } from "react-table";
import { useMediaQuery } from "@mui/material";
import styled from "styled-components";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { Card, CardContent, Box } from "@mui/material";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";
import { AuthContext } from "../../../context/AuthContext";
import { getURLAPI } from "../../../utils/getURL";

import { default as Spinner } from "../../../components/Spinner";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

var columns = [];
var data = [];

const UnitList = () => {
  const body = useSelector((state) => state.app.body);
  const auth = useContext(AuthContext);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();

  const config = auth && auth.config;
  let URLAPI = getURLAPI(config);

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')
  const [startRefreshFilter, setStartRefreshFilter] = useState(true);
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result

    if (tableName === "UnitTypes") {
      dispatch({
        type: "fetch/filter", //'fetch/get_add',
        payload: result,
        ob: "unittype",
      });
    }

    if (tableName === "Units") {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "unit",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true);
  };

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "UnitTypes",
        action: {
          //          metod: 'get',
        },
      },
    ],
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Units",
        action: {
          metod: "get",
          //GetStateServer: true,
        },
      },
    ],
  };

  // close page
  useEffect(
    () => () => {
      try {
        dispatch({
          type: "fetch/get",
          payload: true,
          ob: "close",
        });
      } catch (err) {
        console.log("fetch close error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && startRefreshFilter) {
    setStartRefreshFilter(false);
    ws_client.open_process(paramsFilter);
  }

  if (ws_client && ws_client.ws_login && startRefresh) {
    setStartRefresh(false);
    ws_client.open_process(params);
  }

  //END get all str from table Roles

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  const [unittype2, setUnittype2] = useState([]); //auth.Unitype || [])

  const UnitTypes = useSelector((state) => state.app.filter.unittype_filter);
  let unittypesid = [];
  //  if (unittype2['0']) unittypeid = unittype2['0'].value || ' '
  for (let t in unittype2) unittypesid.push({ id: unittype2[t].value });

  var unittypes = [];
  for (var i in UnitTypes) {
    unittypes.push({
      label: UnitTypes[i].name,
      value: UnitTypes[i].id,
    });
  }

  //Table
  columns = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.unit.fields.unit"), //'Объект',
      accessor: "unitname",
    },
    {
      Header: translate("resources.unit.fields.unittype"), //'Тип доступа',
      accessor: "unittypename",
    },
  ];

  if (!isXSmall) {
    columns.push({
      Header: translate("resources.unit.fields.code"), //'Код',
      accessor: "code",
    });
    columns.push({
      Header: translate("resources.unit.fields.comments"), //'Коментарии',
      accessor: "comments",
    });
  }

  data = [];
  //          body.workplace.map((Item) => <ItemList Item={Item} />)}

  const findUnits = (item, unittypesid) => {
    let rez = false;
    for (let t in unittypesid)
      if (item.unittypeid === unittypesid[t].id) rez = true;

    return rez;
  };

  let units = body.unit;

  if (units) {
    if (unittypesid)
      //unittype2)//date1 || date2 || workerid || customerid || typeworkid || workid)
      units = units.filter((item) => {
        //        console.log(item)

        return (
          findUnits(item, unittypesid) || unittypesid.length === 0 || false
        );
      });
  }

  let num2 = 1;
  for (var iw2 in units) {
    data.push({
      num: num2++,
      unitname: units[iw2].name,
      unittypename: units[iw2].unittype && units[iw2].unittype.name,
      code: units[iw2].code,
      comments: units[iw2].comments,
      unit_id: units[iw2].id,
      unit: units[iw2],
      units: units,
      //itemsworks: items[iw].works,
    });
  }
  const changeUnitType = (event, value) => {
    setUnittype2(value);
    //    auth.setWorker(value)
  };

  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  let v = cell.row.original;

                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "timeline" && (
                        <Link
                          as={Link}
                          variant="outline-primary"
                          //style={{ marginBottom: 5 }}

                          to={{
                            pathname: "/auth/unit/item",
                            /*                                      
                              state: {
                              action: {
                                mode: 'edit',
                                parent: cell.row.original.worktime_item,
                                id: cell.row.original.items.id,
                              },
                            },
  */
                            //items: cell.row.original.items,
                            //                            id: cell.row.original.items.id, //cell.row.original['worktime_id'],
                            //                            itemsworks: cell.row.original.items.works,
                            //                            mode: 'edit',
                            //parent: cell.row.original.worktime_item,
                          }}
                          state={{
                            id: cell.row.original.unit_id,
                            //                          id: cell.row.original.role_id,
                            //                          id: cell.row.original.auth_item.id,
                            //                        role_id: cell.row.original.role_id,
                            //                            items: cell.row.original.items,
                          }}
                          onClick={() => {
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.unit_id,
                              ob: "item_id",
                            });
                          }}
                        >
                          {cell.render("Cell")}
                        </Link>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  const ready = () => {
    if (body && body.unit) return true;
    else return false;
  };

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="60em">
            <Card>
              <CardContent>
                <>
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    //style={{ marginBottom: 10 }}
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        //backgroundColor: 'primary.dark',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    to={{
                      pathname: "/auth/unit/item",
                    }}
                    onClick={() => {
                      dispatch({
                        type: "fetch/get", //_add',
                        payload: -1, //новая запись!!!
                        ob: "item_id",
                      });
                    }}
                  >
                    {translate("resources.unit.addaccess")}
                  </Button>{" "}
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    //style={{ marginBottom: 10 }}
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        //backgroundColor: 'primary.dark',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    to="/"
                  >
                    {translate("resources.button.return")}
                  </Button>
                  <Autocomplete
                    multiple
                    id="size-small-outlined" //      id="checkboxes-tags"
                    options={unittypes}
                    onChange={changeUnitType}
                    size="small"
                    value={[...unittype2]}
                    //                defaultValue={(worker2 && worker2[0]) || []}
                    limitTags={4}
                    //              filterSelectedOptions
                    isOptionEqualToValue={(props, option) => {
                      let rez = false;
                      if (props.value === option.value) rez = true;
                      return rez;
                    }}
                    selectOnFocus
                    clearOnBlur
                    onFocus={(e) => {
                      //            console.log(e.target.readOnly)
                      return (e.target.readOnly = true);
                    }} //выключение клавиатуры при выборе      handleHomeEndKeys
                    //      disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      );
                    }}
                    style={{ width: isXSmall ? 210 : 400 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={translate("resources.unit.selectaccesstype")} //"Выберите Тип доступа"
                        variant="outlined"
                        //          variant="standard"
                        //          placeholder="Выберите Мастера"
                      />
                    )}
                  />
                  <Styles>
                    <br />
                    <Table
                      columns={columns}
                      data={data}
                      getHeaderProps={
                        (nameTheme &&
                          nameTheme !== "dark" &&
                          ((row) => {
                            return {
                              style: {
                                //                   background:
                                //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                              },
                            };
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              color: "#ffffffb3",
                              //                    background:
                              //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                            },
                          };
                        })
                      }
                      /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                      getRowProps={
                        (nameTheme &&
                          nameTheme !== "dark" &&
                          ((row) => {
                            return {
                              style: {
                                background:
                                  row.index % 2 === 0
                                    ? "rgba(0,0,0,.1)"
                                    : "white",
                              },
                            };
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              background:
                                row.index % 2 === 0
                                  ? "rgba(0,0,0,.1)"
                                  : "#616161e6", //'#ffffffb3',
                            },
                          };
                        })
                      }
                      getCellProps={(cellInfo) => ({
                        //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                        //            style: {
                        //              backgroundColor: `hsl(${
                        //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                        //              }, 100%, 67%)`,
                        //            },
                      })}
                    />
                  </Styles>
                  <br />
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="60em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default UnitList;
