/**
 *
 * CartIcon
 *
 */

import React from "react";

import { BagIcon } from "../Icon";
import Button from "../Button";

const CartIcon = (props) => {
  const { className, onClick, cartItems } = props;

  const Icon = (
    <span
      className="cart-icon"
      style={{
        position: "relative",
        lineHeight: "100%",
        padding: "8px ",
        //border: "none",
        //background: "transparent",
        //color: "white",//"inherit", //"#323232 !important",
        minWidth: 60,
      }}
    >
      <BagIcon fillcolor={"white"} />
      {cartItems?.length > 0 && (
        <span
          className="cart-badge"
          style={{
            display: "flex",
            borderRadius: "100%",
            fontSize: ".6rem",
            fontWeight: 600,
            position: "absolute",
            top: -2,
            right: -4,
            textAlign: "center",
            width: 20,
            height: 20,
            backgroundColor: "#ff2950",
            color: "#fff",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {cartItems?.length >= 99 ? "99+" : cartItems?.length}
        </span>
      )}
    </span>
  );

  const items = cartItems?.length;

  return (
    <Button
      borderless
      variant="empty"
      className={className}
      ariaLabel={
        items > 0 ? `your cart have ${items} items` : "your cart is empty"
      }
      icon={Icon}
      onClick={onClick}
    />
  );
};

export default CartIcon;
