import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { useTable } from "react-table";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import { Avatar } from "@mui/material";
//import { push } from 'connected-react-router'
import { Card, CardContent, Box } from "@mui/material";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";
import { AuthContext } from "../../../context/AuthContext";
//import { getURLAPI } from '../../../utils/getURL'
import Typography from "@material-ui/core/Typography";

import { getURLAPI } from "../../../utils/getURL";

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const StylesOld = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

var columns = [];
var data = [];

const Workers = () => {
  const app = useSelector((state) => state.app);
  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const issuper = (access && access.issuper) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();

  //START get all str from table Roles
  //console.log("Refresh!!! workers...");
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result
    if (tableName === "Users") {
      dispatch({
        type: "fetch/get_add",
        payload: result,
        ob: "workers",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    console.log(
      "Refresh_handle ",
      event,
      new Date().toLocaleTimeString(),
      result
    );
    setStartRefresh(true);
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Users",
        action: {
          //GetStateServer: true,
        },
      },
    ],
  };

  if (ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params);
    setStartRefresh(false);
  }

  //END get all str from table Roles

  //***************************** */

  //const config = auth.config
  //let URLAPI = getURLAPI(config)
  const image = require(`../../../logo.png`);

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  columns = [
    //    {
    //    Header: '№',
    //  accessor: 'num',
    //},
    {
      Header: translate("resources.workers.fields.fullname"), //'ФИО',
      accessor: "fullname",
    },
    {
      Header: translate("resources.workers.fields.extents"), //'Внутренний номер',
      accessor: "extents",
    },
    {
      Header: translate("resources.workers.fields.mobtel"), //'Телефоны',
      accessor: "mobtel",
    },
    {
      Header: "email",
      accessor: "email",
    },
  ];

  if (false && !isXSmall) {
    columns.push({
      Header: translate("resources.workers.fields.jobtitle"), //'Должность',
      accessor: "jobTitle",
    });

    columns.push({
      Header: translate("resources.workers.fields.workplace"), //'Рабочее место',
      accessor: "workplace",
    });
    columns.push({
      Header: translate("resources.workers.fields.avatar"), //'Аватар',
      accessor: "avatar",
    });
  }

  data = [];

  const workers = body.workers;
  //  const users = body.avatar //body.auth //app.filter && app.filter.auth_filter
  let num = 1;
  //  let user
  //let tel2
  for (var iw in workers) {
    //    user = null
    //    for (var iu in users) {
    //      if (workers[iw].id === users[iu].workerid) user = users[iu]
    //    }
    if (
      (!workers[iw].issuper &&
        (!workers[iw].isadmin || workers[iw].id === auth.userId)) ||
      issuper
    )
      data.push({
        num: num++,
        fullname: workers[iw].fullName,
        extents: workers[iw].internal,
        mobtel: workers[iw].phone,
        email: workers[iw].email,
        worker: workers[iw],
        auth: auth,
        image: image,
        //      workplace: workers[iw].workplaces && workers[iw].workplaces.name,
        //      jobTitle: workers[iw].jobTitle,
      });
  }

  //{body.worker && body.worker.map((Item) => <ItemList Item={Item} />)}

  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  const avatar = cell.row.original.avatar;
                  let v = cell.row.original;
                  let UserAvatar = "";
                  if (avatar && avatar.data) {
                    UserAvatar = "";
                    for (let i in avatar.data) {
                      UserAvatar =
                        UserAvatar + String.fromCharCode(avatar.data[i]);
                    }
                  }

                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {cell.column.id === "avatar" && UserAvatar !== "" && (
                        <div>
                          <img
                            className="image-gallery-thumbnail-image"
                            src={UserAvatar}
                            height={"50px"}
                            width={"50px"} //                          style={(width = 100)}
                          ></img>
                        </div>
                      )}
                      {cell.column.id === "avatar" && UserAvatar === "" && (
                        <Avatar />
                      )}
                      {cell.column.id === "extents" && v.extents && (
                        <>
                          <img
                            className="image-gallery-thumbnail-image"
                            src={v.image}
                            height={"16px"}
                            width={"16px"} //                          style={(width = 100)}
                            //            onClick={() => setModalShow(true)}
                            //onMouseOver
                            onClick={() => {
                              v.auth.setWebPhone((state) => ({
                                ...state,
                                isPhoneOpen: true,
                                PhoneNumber: v.extents,
                              }));
                            }}
                            /*
                            onMouseOver={(item) => {
                              v.auth.setWebPhone((state) => ({
                                ...state,
                                isPhoneOpen: true,
                                PhoneNumber: v.extents,
                             }))
  
                
                              //              setModalShow(true)
                            }}
                            */
                          ></img>
                        </>
                      )}
                      {(cell.column.id === "fullname" && (
                        <Link
                          as={Link}
                          variant="outline-primary"
                          //style={{ marginBottom: 5 }}

                          to={{
                            pathname: "/pages/workers/item",
                          }}
                          state={{
                            id: cell.row.original.worker.id, //cell.row.original['worktime_id'],
                          }}
                          onClick={() => {
                            dispatch({
                              type: "fetch/get_add",
                              payload: cell.row.original.worker.id,
                              ob: "item_id",
                            });
                          }}
                        >
                          {cell.render("Cell")}
                        </Link>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <Form>
      <Box maxWidth="55em">
        <Card>
          <CardContent>
            <>
              <Typography
                id="outlined-basic"
                label="title"
                //variant="standard"
                className="title h6"
                style={{
                  //                  marginTop: 15,
                  marginBottom: 15,
                  marginRight: 275,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {translate("resources.workers.title")}
              </Typography>

              <Styles>
                <Table
                  columns={columns}
                  data={data}
                  getHeaderProps={
                    (nameTheme &&
                      nameTheme !== "dark" &&
                      ((row) => {
                        return {
                          style: {
                            //                   background:
                            //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                          },
                        };
                      })) ||
                    ((row) => {
                      return {
                        style: {
                          color: "#ffffffb3",
                          //                    background:
                          //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                        },
                      };
                    })
                  }
                  /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                  getRowProps={
                    (nameTheme &&
                      nameTheme !== "dark" &&
                      ((row) => {
                        return {
                          style: {
                            background:
                              row.index % 2 === 0 ? "rgba(0,0,0,.1)" : "white",
                          },
                        };
                      })) ||
                    ((row) => {
                      return {
                        style: {
                          background:
                            row.index % 2 === 0
                              ? "rgba(0,0,0,.1)"
                              : "#616161e6", //'#ffffffb3',
                        },
                      };
                    })
                  }
                  getCellProps={(cellInfo) => ({
                    //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                    //            style: {
                    //            backgroundColor: `hsl(${
                    //            120 * ((120 - cellInfo.value) / 120) * -1 + 120
                    //        }, 100%, 67%)`,
                    //    },
                  })}
                />
              </Styles>
            </>
          </CardContent>
        </Card>
      </Box>
    </Form>
  );
};

export default Workers;
