import React, { useContext, useCallback, useEffect } from 'react'
import { Fragment, useState } from 'react'
//import { Button, Form } from 'react-bootstrap'
import { Button, Form, Col, Row, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
//import { format_date_time } from '../../utils/formatDate'
import Grid from '@mui/material/Grid'
import { useMediaQuery, Divider, Tabs, Tab, Theme } from '@mui/material'
import { DropzoneArea, DropzoneDialogBase } from 'material-ui-dropzone'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { Avatar } from '@mui/material'
import { Button as Button2 } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useTranslate } from '../../lib/translate/i18n/useTranslate'
import { Card, CardContent, Box, Typography } from '@mui/material'
import { getAccessCode } from '../../access'
import { wss_get_access } from '../../utils/get_access'

import { default as Spinner } from '../../components/Spinner'

import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

import { AuthContext } from '../../context/AuthContext'
import { getURLAPI } from '../../utils/getURL'

const boxstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const ProfilePage = () => {
  const auth_item = useSelector((state) => state.app.body.auth_item)
  const body = useSelector((state) => state.app.body)
  const access = useSelector((state) => state.app.access.access)
  const isadmin = (access && access.isadmin) || false
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const translate = useTranslate()

  const dispatch = useDispatch()

  const auth = useContext(AuthContext)
  //START get all str from table Roles
  const [startRefresh, setStartRefresh] = useState(true)

  //замыкание
  const ws_client = auth.ws_client

  const item_id = body.item_id

  const readonly = body.readonly || false

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result
    //console.log('result handle1 ', result)
    if (tableName === 'Users') {
      dispatch({
        type: 'fetch/get_add',
        payload: result,
        ob: 'auth_item',
      })
      dispatch({
        type: 'fetch/get_add',
        payload: readonly,
        ob: 'readonly',
      })
    }
  }

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true)
  }

  const params = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'Users',
        where: { id: item_id },
        action: { metod: 'profile', isBlocked: true },
      },
    ],
  }

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client && ws_client.deblock({ tableName: 'Users', id: item_id })
      } catch (err) {
        console.log('deblock error ', err)
      }
    },
    []
  )

  if (ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params)
    setStartRefresh(false)
  }

  //END get all str from table Roles

  const access2 = useSelector((state) => state.app.access)
  let Avatars =
    access2 &&
    access2.avatar &&
    access2.avatar.avatar &&
    access2.avatar.avatar.avatar
  if (!Avatars)
    Avatars =
      access2.access && access2.access.user && access2.access.user.avatar

  let UserAvatar = ''

  if (auth_item)
    if (Avatars && !auth_item.user_avatar) {
      if (Avatars.data && typeof Avatars.data === 'object') {
        //Blob перевоим в String один раз
        for (let ii in Avatars.data) {
          UserAvatar = UserAvatar + String.fromCharCode(Avatars.data[ii])
        }

        dispatch({
          type: 'app/set',
          payload: {
            id: auth_item,
            field: 'user_avatar',
            value: UserAvatar,
          },
          ob: 'auth_item',
        })
      } //    UserAvatar = uAvatar
    } else if (auth_item && auth_item.user_avatar) {
      //
      UserAvatar = auth_item.user_avatar
    }
  const defaultIcon = <AccountCircle />

  const icon = defaultIcon

  const [open, setOpen] = React.useState(false)
  const [fileObjects, setFileObjects] = React.useState([])

  const dialogTitle = () => (
    <>
      <span>Upload file</span>
      <IconButton
        style={{ right: '12px', top: '8px', position: 'absolute' }}
        onClick={() => setOpen(false)}
      >
        <CloseIcon />
      </IconButton>
    </>
  )

  const getAvatar = () => {
    const avatar =
      (auth_item && auth_item.user_avatar && UserAvatar) ||
      (fileObjects.length > 0 && fileObjects[0].data) ||
      ''
    return avatar
  }
  const getChange = () => {
    if (auth_item && auth_item.change === true) return 'success'
    else return 'outline-success'
  }

  const saveProfile = function (_result, _parent) {
    //console.log(_result)

    /* dispatch({
      type: 'fetch/access',
      payload: null,
      ob: 'access',
    })*/

    auth.setdispatchAccess(false)
  }

  const [modalopen, setModalOpen] = React.useState(false)
  const handleOpen = () => setModalOpen(true)
  const handleClose = () => setModalOpen(false)

  const [pswopen, setPswlOpen] = React.useState(false)
  const handlePswOpen = () => setPswlOpen(true)
  const handlePswClose = () => setPswlOpen(false)

  const [showAlert, setShowAlert] = React.useState(false)
  const [AlertMessage, setAlertMessage] = React.useState(null)
  const [AlertType, setAlertType] = React.useState('error')

  const handlePswSave = (result) => {
    //    console.log(result)
    const { status, message } = result && result.result
    if (status && status === 'err') {
      setAlertType('error')
      setAlertMessage(message)
      setShowAlert(true)
    } else {
      setAlertType('success')
      setAlertMessage('Пароль успешно изменен !!!')
      setShowAlert(true)
    }
  }

  if (isadmin || getAccessCode('CHANGE_PSW', access && access.access)) {
    //    console.log('PasswordPage auth_item', auth_item)
    if (false && auth_item && !auth_item.CHANGE_PSW)
      dispatch({
        type: 'app/set',
        payload: {
          id: auth_item,
          field: 'CHANGE_PSW',
          value: true,
        },
        ob: 'auth_item',
      })
  }

  const ready = () => {
    if (auth_item) return true
    else return false
  }

  return (
    <>
      {ready() && (
        <>
          {!pswopen && (
            <>
              <Box maxWidth="45em">
                <Card>
                  <CardContent>
                    <>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          marginTop: -20,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          id="outlined-basic"
                          label="Пользователь"
                          variant="standard"
                          style={{
                            marginTop: 15,
                            marginBottom: 15,
                            //                    display: 'flex',
                            //                  justifyContent: 'space-between',
                          }}
                        >
                          {translate('resources.profile.title')}
                        </Typography>
                        {readonly && (
                          <Typography
                            id="outlined-basic"
                            label="Title"
                            //variant="standard"
                            className="title h6"
                            style={{
                              marginTop: 15,
                              marginBottom: 15,
                              //marginLeft: 275,
                              //marginRight: 275,
                              color: 'red',
                            }}
                            //                      {translate('resources.workers.readonly')}
                          >
                            ReadOnly
                          </Typography>
                        )}
                      </div>
                      <Form.Group className="mb-3" controlId="formName">
                        <TextField
                          readOnly
                          id="outlined-basic"
                          label={translate('resources.profile.login')} //"login"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          //defaultValue={(auth_item && auth_item.login) || ''}
                          value={(auth_item && auth_item.login) || ''}
                          onChange={(item) => {
                            auth_item.change = true
                            setShowAlert(false)
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: auth_item,
                                field: 'login',
                                value: item.target.value,
                              },
                              ob: 'auth_item',
                            })
                          }}
                        />
                        <TextField
                          id="outlined-basic"
                          label={translate('resources.profile.email')} //"email"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          //defaultValue={(auth_item && auth_item.email) || ''}
                          value={(auth_item && auth_item.email) || ''}
                          onChange={(item) => {
                            auth_item.change = true
                            setShowAlert(false)
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: auth_item,
                                field: 'email',
                                value: item.target.value,
                              },
                              ob: 'auth_item',
                            })
                          }}
                        />
                        <TextField
                          id="outlined-basic"
                          label={translate('resources.profile.name')} //"Имя"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          //defaultValue={(auth_item && auth_item.firstName) || ''}
                          value={(auth_item && auth_item.firstName) || ''}
                          onChange={(item) => {
                            auth_item.change = true
                            setShowAlert(false)
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: auth_item,
                                field: 'firstName',
                                value: item.target.value,
                              },
                              ob: 'auth_item',
                            })
                          }}
                        />
                        <TextField
                          id="outlined-basic"
                          label={translate('resources.profile.lastname')} //"Фамилия"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          //defaultValue={(auth_item && auth_item.lastName) || ''}
                          value={(auth_item && auth_item.lastName) || ''}
                          onChange={(item) => {
                            auth_item.change = true
                            setShowAlert(false)
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: auth_item,
                                field: 'lastName',
                                value: item.target.value,
                              },
                              ob: 'auth_item',
                            })
                          }}
                        />
                        {readonly && (
                          <Button2
                            size="small"
                            color="primary"
                            disabled
                            //variant="secondary"
                            variant="outlined"
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                            startIcon={
                              getAvatar() ? <Avatar src={getAvatar()} /> : icon
                            }
                          >
                            {translate('resources.profile.load_avatar')}
                          </Button2>
                        )}
                        {!readonly && (
                          <Button2
                            size="small"
                            color="primary"
                            variant="outlined"
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                            startIcon={
                              getAvatar() ? <Avatar src={getAvatar()} /> : icon
                            }
                            onClick={() => {
                              setOpen(true)
                            }}
                          >
                            {translate('resources.profile.load_avatar')}
                          </Button2>
                        )}
                        <DropzoneDialogBase
                          dialogTitle={dialogTitle()}
                          acceptedFiles={['image/*']}
                          fileObjects={fileObjects}
                          cancelButtonText={'cancel'}
                          submitButtonText={'submit'}
                          maxFileSize={50000000}
                          filesLimit={0}
                          open={open}
                          onAdd={(newFileObjs) => {
                            //console.log('onAdd', newFileObjs)
                            if (fileObjects.length === 0)
                              setFileObjects(
                                [].concat(fileObjects, newFileObjs)
                              )
                            //console.log('onAdd fileObjects', fileObjects)
                          }}
                          onDelete={(deleteFileObj, fileIndex) => {
                            console.log('onDelete', deleteFileObj, fileIndex)
                            fileObjects.splice(fileIndex, 1)
                          }}
                          onClose={() => setOpen(false)}
                          onSave={() => {
                            //console.log('onSave', fileObjects)
                            setOpen(false)

                            auth_item.change = true
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: auth_item,
                                field: 'user_avatar',
                                value: fileObjects[0].data,
                              },
                              ob: 'auth_item',
                            })
                          }}
                          showPreviews={true}
                          showFileNamesInPreview={false}
                          showPreviewsInDropzone={false}
                        />
                      </Form.Group>
                      <>
                        <div className="hstack gap-2">
                          {readonly && (
                            <Button disabled variant="secondary">
                              {translate('resources.button.save')}
                            </Button>
                          )}
                          {!readonly && (
                            <Button
                              variant={getChange()} //"outline-primary"
                              as={Link}
                              to={{
                                pathname: '/', //auth/auth/save',
                              }}
                              onClick={async () => {
                                setShowAlert(false)
                                try {
                                  await ws_client.wss_setItem({
                                    tableName: 'Users',
                                    item: auth_item,
                                    call_nm: 'Model_update',
                                    where: { id: auth_item.id },
                                    action: {},
                                    callback: saveProfile,
                                  })
                                } catch (e) {
                                  console.log('errr e', e)
                                }
                              }}

                              // state={auth_item}
                            >
                              {translate('resources.button.save')}
                            </Button>
                          )}
                          {readonly && (
                            <Button disabled variant="secondary">
                              {translate('resources.profile.changepassword')}
                            </Button>
                          )}
                          {!readonly && (
                            <Button
                              variant="outline-primary"
                              //as={Link}
                              //to={{
                              //  pathname: '/auth/auth/setpsw',
                              //}}
                              //state={auth_item}
                              onClick={handlePswOpen}
                            >
                              {translate('resources.profile.changepassword')}
                            </Button>
                          )}
                          <Button as={Link} variant="outline-primary" to="/">
                            {translate('resources.button.return')}
                          </Button>
                        </div>
                        <br />
                      </>
                      <br />
                      {showAlert && (
                        <Stack sx={{ width: '100%' }} spacing={2}>
                          <Alert
                            severity={AlertType}
                            onClose={() => {
                              setShowAlert(false)
                              setAlertMessage('')
                            }}
                          >
                            <strong>{AlertMessage}</strong>
                          </Alert>
                        </Stack>
                      )}
                    </>
                  </CardContent>
                </Card>
              </Box>
            </>
          )}
          {pswopen && (
            <>
              <Box maxWidth="45em">
                <Card>
                  <CardContent>
                    <>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          marginTop: -20,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          id="outlined-basic"
                          label="Пользователь"
                          variant="standard"
                          style={{
                            marginTop: 15,
                            marginBottom: 15,
                            //                    display: 'flex',
                            //                  justifyContent: 'space-between',
                          }}
                          //                  {translate('resources.profile.title')}
                        >
                          {translate('resources.passwordpage.password')}
                        </Typography>
                        {readonly && (
                          <Typography
                            id="outlined-basic"
                            label="Title"
                            //variant="standard"
                            className="title h6"
                            style={{
                              marginTop: 15,
                              marginBottom: 15,
                              //marginLeft: 275,
                              //marginRight: 275,
                              color: 'red',
                            }}
                            //                      {translate('resources.workers.readonly')}
                          >
                            ReadOnly
                          </Typography>
                        )}
                      </div>
                      <TextField
                        readOnly
                        id="outlined-basic"
                        label="login"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        //defaultValue={(auth_item && auth_item.login) || ''}
                        value={(auth_item && auth_item.login) || ''}
                        /*              onChange={(item) =>
                dispatch({
                  type: 'app/set',
                  payload: {
                    id: auth_item,
                    field: 'login',
                    value: item.target.value,
                  },
                  ob: 'auth_item',
                })
  
              }
  */
                      />
                      {!isadmin &&
                        !getAccessCode(
                          'CHANGE_PSW',
                          access && access.access
                        ) && (
                          <TextField
                            id="outlined-basic"
                            label={translate(
                              'resources.passwordpage.oldpassword'
                            )} //"Введите старый password"
                            //variant="standard"
                            variant="outlined"
                            style={{
                              marginBottom: 15,
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                            onChange={(item) =>
                              dispatch({
                                type: 'app/set',
                                payload: {
                                  id: auth_item,
                                  field: 'oldpassword',
                                  value: item.target.value,
                                },
                                ob: 'auth_item',
                              })
                            }
                          />
                        )}
                      <TextField
                        id="outlined-basic"
                        label={translate('resources.passwordpage.newpassword')} //"Введите новый password"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        placeholder="Введите новый password"
                        onChange={(item) =>
                          dispatch({
                            type: 'app/set',
                            payload: {
                              id: auth_item,
                              field: 'newpassword',
                              value: item.target.value,
                            },
                            ob: 'auth_item',
                          })
                        }
                      />
                      <Form>
                        <Button
                          //            changeHandler
                          variant={getChange()} //"primary"
                          //as={Link}
                          //to={{
                          //  pathname: '/auth/auth/profile',
                          //                  pathname: '/auth/auth/changepsw',
                          //}}
                          onClick={async () => {
                            try {
                              await ws_client.wss_setItem({
                                tableName: 'Users',
                                item: auth_item,
                                call_nm: 'Model_update',
                                where: { id: auth_item.id },
                                action: {
                                  metod: 'changepassword',
                                  deblock: false,
                                },
                                callback: handlePswSave,
                              })
                            } catch (e) {
                              console.log('errr e', e)
                            }
                            handlePswClose()
                          }}

                          //state={auth_item}
                        >
                          {translate('resources.passwordpage.changepassword')}
                        </Button>{' '}
                        <Button
                          variant="outline-primary"
                          //as={Link}
                          //to={{
                          //  pathname: '/auth/auth/profile',
                          //}}
                          //state={auth_item}
                          onClick={handlePswClose}
                        >
                          {translate('resources.button.cancel')}
                        </Button>
                      </Form>
                    </>
                  </CardContent>
                </Card>
              </Box>
            </>
          )}
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="45em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  )
}

export default ProfilePage
