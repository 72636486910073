import * as React from "react";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState, FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { Children, memo } from "react";
import PropTypes from "prop-types";
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Box,
  Link as MuiLink,
  Toolbar,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { Button, Menu, MenuItem } from "@mui/material";
import WifiIcon from "@mui/icons-material/Wifi";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import ComponentPropType from "./ComponentPropType";
import { AuthContext } from "../context/AuthContext";

import Tooltip from "@mui/material/Tooltip";

import { SidebarToggleButton } from "./SidebarToggleButton";
import { LoadingIndicator } from "./LoadingIndicator";
import { LocaleIndicator } from "./LocaleIndicator";
import { UserMenu } from "./UserMenu";
import { LocaleMenu } from "./LocaleMenu";
import { HideOnScroll } from "./HideOnScroll";
import ConfigMenu from "./ConfigMenu";
import ProfileMenu from "./ProfileMenu";
import LogoutMenu from "./LogoutMenu";
import { getAccessCode } from "../access";
import { useTranslate } from "../lib/translate/i18n/useTranslate";

import CartIcon from "../components/Common/CartIcon";

export const AppBar: FC<AppBarProps> = memo((props) => {
  let {
    children,
    className,
    color = "primary",
    open,
    title,
    userMenu = DefaultUserMenu,
    container: Container = HideOnScroll,

    ...rest
  } = props;

  const navigation = useSelector((state: any) => state.navigation);
  const cart = useSelector((state: any) => state.cart);
  const cartItems = cart.cartItems;
  const dispatch = useDispatch();
  const translate = useTranslate();
  const TOGGLE_CART = "src/Navigation/TOGGLE_CART";
  const toggleCart = () => {
    dispatch({
      type: TOGGLE_CART,
    });
  };
  const auth = useContext(AuthContext);
  let ws_client: any = auth.ws_client || null;

  const handleStatus = (status: string) => {
    //    let newstatus
    //    if (ws_client.saveStatus !== status) {
    //console.log('CHANGE STATUS ', status)
    //      newstatus = status

    dispatch({
      type: "fetch/get_add",
      payload: status,
      ob: "connectStatus",
    });

    //    }
  };

  if (ws_client && !ws_client.saveStatus) {
    ws_client.saveStatus = handleStatus;
  }
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const access = useSelector((state: any) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;

  const [Title, setTitle] = useState("");
  // setTitle('')
  let UserName = access && access.UserName;
  const isLargeEnough = true;
  /*= useMediaQuery<Theme>(
    (theme) => theme.breakpoints.up('sm')
  )
  */
  const getColorStatus = () => {
    let colorstatus = "#cccccc"; //'red'
    let currentstatus = ws_client && ws_client.connectStatus;
    if (ws_client) {
      if (currentstatus === "open") colorstatus = "#0dcaf0";
      if (currentstatus === "login") colorstatus = "lime";
      if (currentstatus === "process") colorstatus = "lime";
      if (currentstatus === "setItem") colorstatus = "lime";
      if (currentstatus === "getItem") colorstatus = "lime";
      if (currentstatus === "error") colorstatus = "red";
      if (currentstatus === "reconnect") colorstatus = "yellow";
    }
    return colorstatus;
  };

  return (
    <Container className={className}>
      <StyledAppBar className={AppBarClasses.appBar} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? "regular" : "dense"}
          className={AppBarClasses.toolbar}
        >
          <SidebarToggleButton className={AppBarClasses.menuButton} />
          {Children.count(children) === 0 ? (
            <Typography
              variant="h6"
              color="inherit"
              className={AppBarClasses.title}
              id="title"

              /*          <Typography
            variant="h6"
            color="inherit"
            sx={{
              flex: 1,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
            id="react-admin-title"
          />
          {isLargeEnough && <Logo />}
          {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}

          {isXSmall ? <div style={{ marginLeft: 10 }}>{UserName}</div> : <></>}

          */
            >
              {title}
            </Typography>
          ) : (
            children
          )}
          <div style={{ marginRight: 30 }}>
            <CartIcon cartItems={cartItems} onClick={toggleCart} />
          </div>
          <LoadingIndicator />
          <LocaleMenu>ru</LocaleMenu>

          <UserMenu>
            {(isadmin ||
              getAccessCode("MENU_CONFIG", access && access.access)) && (
              <ConfigMenu
                to=""
                onClick={() => setTitle(" / Конфиг")}
              ></ConfigMenu>
            )}{" "}
            {(isadmin ||
              getAccessCode("MENU_PROFILE", access && access.access)) && (
              <ProfileMenu
                to=""
                onClick={() => setTitle(" / Профайл")}
              ></ProfileMenu>
            )}{" "}
            <LogoutMenu />
          </UserMenu>
          {typeof userMenu === "boolean" ? (
            userMenu === true ? (
              <UserMenu />
            ) : null
          ) : (
            userMenu
          )}

          <Tooltip
            title={translate("resources.tooltip.serveraccess")} //"Доступ к серверу"
            placement="bottom" //"left-end" //top-start"
          >
            <Brightness1Icon
              fontSize="small"
              style={{ marginRight: -15, color: getColorStatus() }}
            />
          </Tooltip>
        </Toolbar>
      </StyledAppBar>
    </Container>
  );
});

AppBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "default",
    "inherit",
    "primary",
    "secondary",
    "transparent",
  ]),
  //  container: ComponentPropType,
  // @deprecated
  open: PropTypes.bool,
  userMenu: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

const DefaultUserMenu = <UserMenu />;

export interface AppBarProps extends Omit<MuiAppBarProps, "title"> {
  container?: React.ElementType<any>;
  // @deprecated
  open?: boolean;
  title?: string | JSX.Element;
  userMenu?: JSX.Element | boolean;
}

const PREFIX = "AppBar";

export const AppBarClasses = {
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonIconClosed: `${PREFIX}-menuButtonIconClosed`,
  menuButtonIconOpen: `${PREFIX}-menuButtonIconOpen`,
  title: `${PREFIX}-title`,
};

const StyledAppBar = styled(MuiAppBar, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${AppBarClasses.toolbar}`]: {
    paddingRight: 24,
  },

  [`& .${AppBarClasses.menuButton}`]: {
    marginLeft: "0.2em",
    marginRight: "0.2em",
  },

  [`& .${AppBarClasses.title}`]: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));
