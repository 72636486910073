import Image from "next/image";
//import Link from 'next/link';
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//import { WishlistButton } from '@/components/wishlist';
import { Product } from "../../../types/Product";
//import { Product } from '@/types';
//import formatPrice from '@/utils/formatPrice';
import Card from "@mui/material/Card";
//import CardActions from '@mui/material/CardActions'
import CardContent from "@mui/material/CardContent";
import Typography from "@material-ui/core/Typography";

//import styles from './ProductCard.module.css';
import "./ProductCard.module.css";
import { getImageSize } from "react-image-size";
import { BagIcon } from "../../Common/Icon";
import Button from "../../Common/Button";
//import * as actions from "../../../containers/Cart/actions.js";
import { handle_AddToCart } from "../../../containers/Cart/cartactions.js";
import {
  HANDLE_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  HANDLE_CART_TOTAL,
  SET_CART_ID,
  CLEAR_CART,
} from "../../../containers/Cart/constants";

interface Props {
  product: Product;
  isPr3: boolean;
}

const ProductCard = (props: Props) => {
  const { product, isPr3 } = props;

  const dispatch = useDispatch();
  const cart = useSelector((state: any) => state.cart);

  const [ImageSize, setupImageSize] = useState<any>({
    width: 400,
    height: 400,
  });

  let aspectratio: any = null;

  try {
    aspectratio = JSON.parse(product.aspectratio || { width: 100, hight: 100 });
  } catch (err) {
    aspectratio = { width: 100, height: 100 };
  }

  const [ImageRatio, setupImageRatio] = useState<any>({
    RWidth: 100,
    RHeight: 100,
  });
  //    fetchImageSize(product.imageURL)
  //  );
  /*{
    width: 400,
    height: 400,
  });
*/

  const prod = {
    taxable: false,
    isActive: true,
    brand: {
      isActive: true,
      _id: "60136d36a44ceeaf670e7903",
      name: "Apple",
      slug: "apple",
    },
    _id: product._id, //"629d661f8bbadc6c80b02a9c",
    sku: "macbook-pro",
    name: product.name, //"Macbook Laptop",
    description: product.name, //"macbook pro 16-inch",
    quantity: 1,
    price: product.price,
    imageUrl: product.imageURL,
    // "http://192.168.1.56:3027/files?dirname=1.KissMe/Sweaters&name=A5095.jpg",
    //"https://mernstore-bucket.s3.us-east-2.amazonaws.com/mbp-spacegray-select-202011_GEO_US.jpeg",
    imageKey: "mbp-spacegray-select-202011_GEO_US.jpeg",
    created: "2022-06-06T02:27:43.139Z",
    slug: "macbook-pro",
    __v: 0,
    inventory: 11398,
    totalPrice: product.price,
  };

  const Icon = (
    <span
      className="cart-icon"
      style={{
        position: "relative",
        lineHeight: "100%",
        padding: "10px 16px",
        border: "none",
        background: "transparent",
        color: "inherit", //"#323232 !important",
        width: 60,
      }}
    >
      <BagIcon />
      {/*   {cartItems?.length > 0 && (
      <span className="cart-badge">
        {cartItems?.length >= 99 ? "99+" : cartItems?.length}
      </span>
    )}
      */}
    </span>
  );

  async function fetchImageSize(url: any) {
    try {
      const dimensions = await getImageSize(url);
      //console.log(dimensions);
      setupImageSize(dimensions);

      setupImageRatio({
        RWidth:
          dimensions.width < dimensions.height
            ? Math.floor((100 * dimensions.width) / dimensions.height)
            : 100,
        RHeight:
          dimensions.width > dimensions.height
            ? Math.floor((100 * dimensions.height) / dimensions.width)
            : 100,
      });

      /*
      if (dimensions.width === dimensions.height) {
        setupImageRatio({
          RWidth: 100,
          RHeight: 100,
        });
      }
      if (di9mensions.width > dimensions.height) {
        setupImageRatio({
          RWidth: 100,
          RHeight: Math.floor((100 * dimensions.height) / dimensions.width),
        });
      }
      if (dimensions.width < dimensions.height) {
        setupImageRatio({
          RWidth: Math.floor((100 * dimensions.width) / dimensions.height),
          RHeight: 100,
        });
      }
        */
    } catch (error) {
      console.error(error);
      return {
        width: 300,
        height: 300,
      };
    }
  }
  let borderbox = "1px solid black";
  //console.log(product);

  /*
  useEffect(() => {
    if (product.imageURL) {
      fetchImageSize(product.imageURL);
    }

    //    modalShow && dispatch({ type: 'FETCH_LIST', urlapi: URLAPI, ob: name })
  }, [product.imageURL]);
*/
  /*
                    "data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27" +
                    ImageSize.width +
                    "%27%20height=%27" +
                    ImageSize.height +
                    "%27/%3e"

                    'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="' +
                    ImageSize.width +
                    '" height="' +
                    ImageSize.height +
                    '"/>'
                    'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="500" height="500"/>'


                  "data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27300%27%20=%27400%27/%3e"


                  */

  return (
    <Card
      style={{
        //marginTop: 10,
        marginLeft: 10,
        borderSpacing: 0,
        border: borderbox,
      }}
    >
      <CardContent
        style={{
          //marginTop: 10,
          marginLeft: 10,
          borderSpacing: 0,
          //border: borderbox,
        }}
      >
        <a>
          <div
            className={"ProductCard_productImgWrapper"}
            style={{
              position: "relative",
            }}
          >
            <span
              style={{
                boxSizing: "border-box",
                display: "inline-block",
                overflow: "hidden",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: "1",
                border: "0px",
                margin: "0px",
                padding: "0px",
                position: "relative",
                maxWidth: "100%",
              }}
            >
              <span
                style={{
                  boxSizing: "border-box",
                  display: "block",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: "1",
                  border: "0px",
                  margin: "0px",
                  padding: "0px",
                  maxWidth: "100%",
                }}
              >
                <img
                  alt=""
                  aria-hidden="true"
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0px",
                    margin: "0px",
                    padding: "0px",
                  }}
                  src={
                    'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="500" height="500"/>'
                  }
                ></img>
              </span>
              <img
                src={product.imageURL}
                alt={product.name}
                style={{
                  position: "absolute",
                  inset: "0px",
                  boxSizing: "border-box",
                  padding: "0px",
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: "0px",
                  height: "0px",
                  minWidth: String(aspectratio.width) + "%",
                  maxWidth: String(aspectratio.width) + "%",
                  minHeight: String(aspectratio.height) + "%",
                  maxHeight: String(aspectratio.height) + "%",
                }}
              />
            </span>
          </div>
        </a>
      </CardContent>
      <div
        className={"productInfo"}
        style={{
          marginLeft: 10,
          fontSize: "1.20rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{ fontSize: "1.60rem", marginLeft: 10 }}
          className={"productName"}
        >
          {product.name}
        </Typography>
        <Typography
          style={{ fontSize: "1.60rem", marginRight: 10, marginBottom: 10 }}
          className={"productPrice"}
        >
          {product.price + "  грн."}
        </Typography>
        <div style={{ marginRight: 30 }}>
          <Button
            borderless
            variant="empty"
            //className={className}
            //ariaLabel={
            //  items > 0 ? `your cart have ${items} items` : "your cart is empty"
            //}
            icon={Icon}
            //onClick={onClick}

            onClick={() => {
              //const callhandleAddToCart: any = handleAddToCart(prod);
              //callhandleAddToCart(dispatch, getState);
              handle_AddToCart(prod, dispatch);
              //dispatch({
              // type: ADD_TO_CART,
              // payload: prod,
              //});
            }}
          />
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
