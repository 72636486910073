import React, { useContext, useState, useEffect } from "react";
//import { Form, ListGroup, Row, Col } from 'react-bootstrap'
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";

import moment from "moment";
import { useTable } from "react-table";
import styled from "styled-components";
import { getAccessCode } from "../../../access";
import { useCallback } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

import Checkbox from "@mui/material/Checkbox";
import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import "dayjs/locale/uk";

import { Card, CardContent, Box } from "@mui/material";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";

import { getURLAPI } from "../../../utils/getURL";

import { default as Spinner } from "../../../components/Spinner";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

function Table({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),

                  getHeaderProps(column),
                ])}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                //                console.log(cell)
                return (
                  <td
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      //  getCellProps(cell),
                    ])}
                    align="center"
                    //                    width="140px"
                  >
                    {(cell.column.id !== "timeline" && (
                      <Link
                        as={Link}
                        variant="outline-primary"
                        //style={{ marginBottom: 5 }}

                        to={{
                          pathname: "/pages/worktime/item",
                        }}
                        state={{
                          id: cell.row.original.worktime.id, //cell.row.original['worktime_id'],
                          arg: {
                            parenthtml: "/pages/customers/item",
                            parentid: cell.row.original.id,
                          },
                        }}
                      >
                        {cell.render("Cell")}
                      </Link>
                    )) ||
                      cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

var columns = [];
var data = [];

//History
function Table2({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),

                  getHeaderProps(column),
                ])}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                let colorbutton = "primary";
                let variant = "outlined";
                if (cell.row.original.color2 === "green") {
                  colorbutton = "success";
                  variant = "contained";
                }
                return (
                  <td
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      //  getCellProps(cell),
                    ])}
                    align="center"
                    //                    width="140px"
                  >
                    {(cell.column.id === "times" &&
                      !cell.row.original.worktime && (
                        <Button
                          size="small"
                          //component={Link}
                          color={colorbutton} //"primary"
                          variant={variant} //"outlined"
                          sx={{ display: "inline-flex", alignItems: "center" }}
                          //                        as={Link}
                          //                          variant="outline-primary"
                          style={{ marginBottom: 5 }}
                          onClick={() => {
                            //                        console.log('CLICK!!!', cell.row.original)
                            cell.row.original.fn(
                              cell.row.original.time1,
                              cell.row.original.time2
                            );
                          }}
                          /*
                          onClick={(item) => {
                            const change = true
                            const dispatch = useDispatch()

                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: cell.row.original.worktime_item,
                                field: 'time1',
                                value: cell.row.original.time1,
                              },
                              ob: 'worktime_item',
                            })
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: cell.row.original.worktime_item,
                                field: 'time2',
                                value: cell.row.original.time2,
                              },
                              ob: 'worktime_item',
                            })
                          }}

                          /*                          to={{
                            pathname: '/pages/worktime/item',
                          }}
                          /*                        state={{
                          id: cell.row.original.worktime.id, //cell.row.original['worktime_id'],
                          arg: { parenthtml: '/pages/worktime/list' },
                        }}
  */
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                      cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

let columns2 = [];
let data2 = [];

//Messages
function Table3({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),

                  getHeaderProps(column),
                ])}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                let colorbutton = "primary";
                let variant = "outlined";
                if (cell.row.original.color2 === "green") {
                  colorbutton = "success";
                  variant = "contained";
                }
                return (
                  <td
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      //  getCellProps(cell),
                    ])}
                    align="center"
                    //                    width="140px"
                  >
                    {(cell.column.id === "times" &&
                      !cell.row.original.worktime && (
                        <Button
                          size="small"
                          //component={Link}
                          color={colorbutton} //"primary"
                          variant={variant} //"outlined"
                          sx={{ display: "inline-flex", alignItems: "center" }}
                          //                        as={Link}
                          //                          variant="outline-primary"
                          style={{ marginBottom: 5 }}
                          onClick={() => {
                            //                        console.log('CLICK!!!', cell.row.original)
                            cell.row.original.fn(
                              cell.row.original.time1,
                              cell.row.original.time2
                            );
                          }}
                          /*
                          onClick={(item) => {
                            const change = true
                            const dispatch = useDispatch()

                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: cell.row.original.worktime_item,
                                field: 'time1',
                                value: cell.row.original.time1,
                              },
                              ob: 'worktime_item',
                            })
                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: cell.row.original.worktime_item,
                                field: 'time2',
                                value: cell.row.original.time2,
                              },
                              ob: 'worktime_item',
                            })
                          }}

                          /*                          to={{
                            pathname: '/pages/worktime/item',
                          }}
                          /*                        state={{
                          id: cell.row.original.worktime.id, //cell.row.original['worktime_id'],
                          arg: { parenthtml: '/pages/worktime/list' },
                        }}
  */
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                      cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

let columns3 = [];
let data3 = [];

let columns4 = [];
let data4 = [];

const Customers = () => {
  const customers_item = useSelector((state) => state.app.body.customers_item);
  //  const auth = useContext(AuthContext)
  const auth = useContext(AuthContext);
  const current = useSelector((state) => state.app.current);
  const app = useSelector((state) => state.app);
  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [HistoryOn, setHistoryOn] = useState(false);

  let maxwidth = "30em";

  if (HistoryOn) maxwidth = "80em";

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();
  const dispatch = useDispatch();

  const [subPages, setSubPages] = useState({ page: "main" });

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')
  const [startRefreshFilter, setStartRefreshFilter] = useState(true);
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  const item_id = body.item_id;

  const readonly = body.readonly;

  const getCustomerItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === "err") {
      console.log("Err", _result.result.message);
      return;
    }
    let { parent, item, getid = null } = _result && _result.result;
    dispatch({
      type: "fetch/item_add",
      payload: { item, getid }, //parent,
      ob: "store",
    });
    setSubPages({ page: "store" });
  };

  const setCustomerItem = function (_result, _parent) {
    //console.log(_result)
    if (_result && _result.result && _result.result.status === "err") {
      console.log("Err", _result.result.message);
      setSubPages({ page: "main" });
      return;
    }

    if (_result) _result.result.change = true;
    dispatch({
      type: "fetch/get_add",
      payload: _result && _result.result,
      ob: "customers_item",
    });
    setSubPages({ page: "main" });
  };

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result

    if (tableName === "Segments") {
      dispatch({
        type: "fetch/filter", //'fetch/get_add',
        payload: result,
        ob: "segment",
      });
    }

    if (tableName === "Users" && action.metod === "getUserName") {
      dispatch({
        type: "fetch/filter", //'fetch/get_add',
        payload: result,
        ob: "worker",
      });
    }

    if (tableName === "Users" && action.metod === "getUserSegments") {
      dispatch({
        type: "fetch/get_add",
        payload: result,
        ob: "usersegments",
      });
    }

    if (tableName === "Messages") {
      dispatch({
        type: "fetch/get_add",
        payload: result,
        ob: "customermessages",
      });
    }

    if (tableName === "WorkTimes") {
      dispatch({
        type: "fetch_current/get",
        payload: result,
        ob: "worktime",
      });
    }

    if (tableName === "Customers" && action.metod && action.metod === "get") {
      dispatch({
        type: "fetch/filter", //'fetch/get_add',
        payload: result,
        ob: "customers",
      });
    }
    if (
      tableName === "Customers" &&
      action.metod &&
      action.metod === "getOne"
    ) {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "customers_item",
      });
      dispatch({
        type: "fetch/get_add",
        payload: readonly,
        ob: "readonly",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true);
  };

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Segments",
        action: {
          //          metod: 'get',
        },
      },
      {
        model_call_nm: "Model_read",
        tableName: "Users",
        action: {
          metod: "getUserName",
        },
      },
      {
        model_call_nm: "Model_read",
        tableName: "Customers",
        action: {
          metod: "get",
        },
      },
      {
        model_call_nm: "Model_read",
        tableName: "Users",
        action: {
          metod: "getUserSegments",
          id: auth.userId,
        },
      },
      {
        model_call_nm: "Model_read",
        tableName: "Messages",
        where: { id: item_id },
        action: {
          metod: "getCustomerMessages",
        },
      },
      {
        model_call_nm: "Model_read",
        tableName: "WorkTimes",
        //where: { id: item_id },
        action: {
          metod: "get",
          // metod: 'getCustomerMessages',
        },
      },
    ],
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Customers",
        where: { id: item_id },
        action: {
          metod: "getOne",
          isBlocked: true,
        },
      },
    ],
  };

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client &&
            ws_client.deblock({ tableName: "Customers", id: item_id });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && startRefreshFilter) {
    setStartRefreshFilter(false);
    ws_client.open_process(paramsFilter);
  }

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    setStartRefresh(false);
    ws_client.open_process(params);
  }

  //END get all str from table Roles

  const config = auth.config;
  let URLAPI = getURLAPI(config);

  let language = "ru";
  if (access && access.user && access.user.language) {
    if (access.user.language === "ru") language = "ru";
    if (access.user.language === "ua") language = "uk";
    if (access.user.language === "en") language = "en";
  }

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  const handleChangeHistory = useCallback((event) => {
    setHistoryOn(!HistoryOn);
  });

  let date1;
  let date2;
  let typeworkid;
  let workid;

  let userworkers =
    access && access.user && access.user.workers && access.user.workers;
  //console.log('userworkers', userworkers)
  let userworkerid = [];
  for (let u in userworkers) {
    userworkerid.push(userworkers[u].workerid);
  }

  const AllWorkers = useSelector((state) => state.app.filter.worker_filter);
  const UserWorkers = access && access.user && access.user.workers;
  let Workers;
  if (isadmin) {
    Workers = AllWorkers;
  } else {
    if (UserWorkers) {
      Workers = [];
      for (let ii in UserWorkers) {
        Workers.push(UserWorkers[ii].user);
      }
    }
  }

  //  const [customersegments, setcustomersegments] = useState([])

  //Table2
  columns2 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.customers.fields.name"), //'Имя',
      accessor: "name",
    },
    {
      Header: translate("resources.customers.fields.tel1"), //'Телефон ',
      accessor: "tel1",
    },
    {
      Header: translate("resources.customers.fields.changed"), //'Изменено ',
      accessor: "datechange",
    },
    {
      Header: translate("resources.customers.fields.user"), //'User ',
      accessor: "userinfo",
    },
  ];

  if (!isXSmall) {
    /*    columns2.push({
      Header: translate('resources.customers.fields.tel2'), //'Телефон 2',
      accessor: 'tel2',
    })*/

    columns2.push({
      Header: translate("resources.customers.fields.comments"), //'Коментарий',
      accessor: "comments",
    });
  }

  data2 = [];

  let num2 = 1;
  if (customers_item && customers_item.historis) {
    const customers2 = customers_item.historis;

    for (var iw2 in customers2) {
      data2.push({
        num: num2++,
        name: customers2[iw2].name,
        tel1: customers2[iw2].tel1,
        tel2: customers2[iw2].tel2,
        comments: customers2[iw2].comments,
        customer: customers2[iw2],
        datechange: moment(customers2[iw2].datechange).format(
          "DD-MM-YY HH:mm:ss"
        ),

        userinfo:
          customers2[iw2].user &&
          customers2[iw2].user.login + "/" + customers2[iw2].user.firstName,
      });
    }
  }

  columns4 = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.store.fields.name"), //'Сообщение',
      accessor: "name",
    },
  ];

  data4 = [];

  const items = customers_item && customers_item.items && customers_item.items;

  let num4 = 1;
  for (var iw4 in items) {
    data4.push({
      num: num4++,
      name: items[iw4].stores && items[iw4].stores.name,
      items: items[iw4],
    });
  }

  function Table4({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  const vv = cell;
                  let parent = Object.assign(
                    {},
                    cell.row.original.customers_item
                  );
                  function clearptw(parent) {
                    if (parent && parent.getwt) {
                      parent.getwt = false; //обнуляем getwt для загрузки WT при возврате назад
                    }
                    return parent;
                  }
                  let storeid = cell.row.original.items.storeid;
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: storeid
                            ? cell.column.style
                            : { color: "blue", backgroundColor: "yellow" },
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "timeline" && (
                        <Button
                          //as={Link}
                          variant="outline-primary"
                          //style={{ marginBottom: 5 }}
                          style={{
                            pointerEvents: readonly ? "none" : "",
                          }}
                          onClick={async () => {
                            try {
                              await ws_client.wss_getItem({
                                tableName: "Heads",
                                //item: cell.row.original.customers_item,
                                where: cell.row.original.customers_item.id,
                                action: {
                                  metod: "getSupervisor",
                                  mode: "get",
                                  parent: clearptw(parent), //cell.row.original.customers_item,
                                  id: cell.row.original.items.id, //role_id,
                                },
                                //callback: getWork,
                              });
                            } catch (e) {
                              console.log("errr e", e);
                            }
                            //setSubPages({ page: 'role' })
                          }}
                        >
                          {cell.render("Cell")}
                        </Button>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  const getChange = () => {
    if (customers_item && customers_item.change === true) return "contained";
    else return "outlined";
  };

  const getAccessToChange = () => {
    return readonly || false;
  };

  const ready = () => {
    if (customers_item) return true;
    else return false;
  };

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth={maxwidth}>
            <Card>
              <CardContent>
                <>
                  <div
                    //            className="hstack gap-2"
                    style={{
                      marginTop: -20,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      //id="outlined-basic"
                      label="Клиент"
                      //variant="standard"
                      className="title h6"
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {translate("resources.customers.title")}
                    </Typography>

                    {(isadmin ||
                      getAccessCode("SHOW_LOGS", access && access.access)) && (
                      <FormControlLabel
                        style={{
                          //              marginRight: 15,
                          marginLeft: isXSmall ? 0 : 5,
                        }}
                        control={
                          <Switch
                            id={customers_item && customers_item.id}
                            name={"History"}
                            color="primary"
                            onChange={handleChangeHistory}
                            //                          onFocus={onFocus}
                            checked={HistoryOn}
                            //                      {...sanitizeInputRestProps(rest)}
                            //                      {...options}
                            //                      disabled={disabled}
                          />
                        }
                        label={translate("resources.switch.changed")}
                      />
                    )}
                    {readonly && (
                      <Typography
                        //id="outlined-basic"
                        label="Title"
                        //variant="standard"
                        className="title h6"
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          //marginRight: 275,
                          color: "red",
                        }}
                        //                      {translate('resources.workers.readonly')}
                      >
                        Readonly
                      </Typography>
                    )}
                  </div>
                  {customers_item && (
                    <>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          //            marginTop: -20,
                          display: "flex",
                          //                justifyContent: 'space-between',
                        }}
                      >
                        <TextField
                          id="outlined-basic1"
                          label={translate("resources.customers.fields.name")} //"Имя"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            //                  width: isXSmall ? 170 : 200,
                            marginRight: isXSmall ? 15 : 15,
                            marginBottom: isXSmall ? 10 : 10,
                            //                  display: 'flex',
                            justifyContent: "space-between",
                          }}
                          value={(customers_item && customers_item.name) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              customers_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: customers_item,
                                  field: "name",
                                  value: item.target.value,
                                },
                                ob: "customers_item",
                              });
                            }
                          }}
                        ></TextField>
                        <TextField
                          id="outlined-basic2"
                          label={translate("resources.customers.fields.tel1")} //"Телефон 1" //"Ведите номер телефона"
                          variant="outlined"
                          style={{
                            //                  width: isXSmall ? 170 : 200,
                            marginRight: isXSmall ? 5 : 15,
                            marginBottom: isXSmall ? 10 : 15,
                            //                  display: 'flex',
                            justifyContent: "space-between",
                          }}
                          value={(customers_item && customers_item.tel1) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              customers_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: customers_item,
                                  field: "tel1",
                                  value: item.target.value,
                                },
                                ob: "customers_item",
                              });
                            }
                          }}
                        />
                      </div>
                      <TextField
                        id="outlined-basic3"
                        label={translate("resources.customers.fields.comments")} //"Коментарии" //"Ведите коментарии"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          width: isXSmall ? 350 : 440,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value={
                          (customers_item && customers_item.comments) || ""
                        }
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            customers_item.change = true;
                            dispatch({
                              type: "app/set",
                              payload: {
                                id: customers_item,
                                field: "comments",
                                value: item.target.value,
                              },
                              ob: "customers_item",
                            });
                          }
                        }}
                      />
                    </>
                  )}
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    //variant="outlined"
                    sx={{ display: "inline-flex", alignItems: "center" }}
                    variant={getChange()} //"outline-success"
                    to={{
                      pathname: "/pages/customers/list",
                    }}
                    //state={customers_item}
                    disabled={getAccessToChange()}
                    onClick={async () => {
                      try {
                        if (!getAccessToChange())
                          await ws_client.wss_setItem({
                            tableName: "Customers",
                            item: customers_item,
                            call_nm: "Model_update",
                            where: { id: customers_item.id },
                            action: { metod: "set", userId: auth.userId },
                          });
                      } catch (e) {
                        console.log("errr e", e);
                      }
                    }}
                  >
                    {translate("resources.button.save")}
                  </Button>
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    sx={{
                      marginLeft: 2,
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                    to="/pages/customers/list"
                  >
                    {translate("resources.button.cancel")}
                  </Button>
                  {(isadmin ||
                    getAccessCode("SHOW_LOGS", access && access.access)) &&
                    HistoryOn && (
                      <>
                        {translate("resources.switch.changed")}
                        <Styles>
                          <Table2
                            columns={columns2}
                            data={data2}
                            /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                            getRowProps={(row) => ({
                              style: {
                                background: row.original.color, //index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                              },
                            })}
                            getCellProps={(cellInfo) => {
                              console.log("sssss");

                              return {
                                //            onClick: (req, res) => onClickCell(cellInfo, req, res),

                                style: {
                                  backgroundColor: `hsl(${
                                    120 * ((120 - cellInfo.value) / 120) * -1 +
                                    120
                                  }, 100%, 67%)`,
                                },
                              };
                            }}
                          />
                        </Styles>
                      </>
                    )}
                  {(isadmin ||
                    getAccessCode(
                      "CUSTOMERSTORE_VIEW",
                      access && access.access
                    )) && (
                    <Styles>
                      <br />
                      <Table4
                        columns={columns4}
                        data={data4}
                        getHeaderProps={
                          (nameTheme &&
                            nameTheme !== "dark" &&
                            ((row) => {
                              return {
                                style: {
                                  //                   background:
                                  //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                },
                              };
                            })) ||
                          ((row) => {
                            return {
                              style: {
                                color: "#ffffffb3",
                                //                    background:
                                //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                              },
                            };
                          })
                        }
                        /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                        getRowProps={
                          (nameTheme &&
                            nameTheme !== "dark" &&
                            ((row) => {
                              return {
                                style: {
                                  background:
                                    row.index % 2 === 0
                                      ? "rgba(0,0,0,.1)"
                                      : "white",
                                },
                              };
                            })) ||
                          ((row) => {
                            return {
                              style: {
                                background:
                                  row.index % 2 === 0
                                    ? "rgba(0,0,0,.1)"
                                    : "#616161e6", //'#ffffffb3',
                              },
                            };
                          })
                        }
                        getCellProps={(cellInfo) => ({
                          //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                          //                style: {
                          //                  backgroundColor: `hsl(${
                          //                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                          //                  }, 100%, 67%)`,
                          //                },
                        })}
                      />
                    </Styles>
                  )}{" "}
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth={maxwidth}>
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};
export default Customers;
