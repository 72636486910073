import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

//import {  ListGroup, Row, Col } from 'react-bootstrap'
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { useTable } from "react-table";
import { useMediaQuery } from "@mui/material";
import styled from "styled-components";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { Card, CardContent, Box } from "@mui/material";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";
import { AuthContext } from "../../../context/AuthContext";
import { getURLAPI } from "../../../utils/getURL";

import { default as Spinner } from "../../../components/Spinner";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

var columns = [];
var data = [];

const CronTasksList = () => {
  const body = useSelector((state) => state.app.body);
  const auth = useContext(AuthContext);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();

  const config = auth && auth.config;
  let URLAPI = getURLAPI(config);

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')
  //const [startRefreshFilter, setStartRefreshFilter] = useState(true);
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result

    if (tableName === "CronTasks") {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "crontasks",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    if (tableName === "ServerState") {
      console.log("PingDBF ", result.PingDBF);
      console.log("PingSMS ", result.PingSMS);
      //console.log("tasks ", result.tasks);
      let tasks = result.tasks;
      if (tasks) {
        //        console.log("tasks ", tasks);
        dispatch({
          type: "fetch/get_add",
          //'fetch/get_add',
          payload: result.tasks,
          ob: "tasks",
        });
      }
    }

    if (tableName === "CronTasks") {
      setStartRefresh(true);
    }
  };

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "CronTaskTypes",
        action: {
          metod: "get",
        },
      },
    ],
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "CronTasks",
        action: {
          metod: "get",
          //GetStateServer: true,
        },
      },
    ],
  };

  // close page
  useEffect(
    () => () => {
      try {
        dispatch({
          type: "fetch/get",
          payload: true,
          ob: "close",
        });
      } catch (err) {
        console.log("fetch close error ", err);
      }
    },
    []
  );

  //if (ws_client && ws_client.ws_login && startRefreshFilter) {
  //  setStartRefreshFilter(false);
  //  ws_client.open_process(paramsFilter);
  // }

  if (ws_client && ws_client.ws_login && startRefresh) {
    setStartRefresh(false);
    ws_client.open_process(params);
  }

  //END get all str from table Roles

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  //Table
  columns = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: translate("resources.crontasks.fields.name"), //'наименование',
      accessor: "name",
    },
    {
      Header: translate("resources.crontasks.fields.taskcode"), //'код задачи',
      accessor: "taskcode",
    },
  ];

  if (!isXSmall) {
    columns.push({
      Header: translate("resources.crontasks.fields.schedule"), //'Код',
      accessor: "schedule",
    });
    columns.push({
      Header: translate("resources.crontasks.fields.active"),
      accessor: "active",
    });
    columns.push({
      Header: translate("resources.crontasks.fields.needhistory"),
      accessor: "needhistory",
    });
    columns.push({
      Header: translate("resources.crontasks.fields.comments"), //'Коментарии',
      accessor: "comments",
    });
  }

  data = [];
  //          body.workplace.map((Item) => <ItemList Item={Item} />)}

  const findCronTasks = (item, crontaskstypesid) => {
    let rez = false;
    for (let t in crontaskstypesid)
      if (item.crontaskstypeid === crontaskstypesid[t].id) rez = true;

    return rez;
  };

  let crontasks = body.crontasks;
  let Tasks = body.tasks;

  if (Tasks) {
    console.log("Tasks", Tasks); //состояние задач на сервере натекущий момент
  }
  let num2 = 1;
  for (var iw2 in crontasks) {
    data.push({
      num: num2++,
      name: crontasks[iw2].name,
      taskcode: crontasks[iw2].taskcode,
      schedule: crontasks[iw2].schedule,
      active: crontasks[iw2].active,
      needhistory: crontasks[iw2].needhistory,
      comments: crontasks[iw2].comments,
      crontask_id: crontasks[iw2].id,
      crontask: crontasks[iw2],
      crontasks: crontasks,
      //itemsworks: items[iw].works,
    });
  }

  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  let v = cell.row.original;
                  let Checked = false;
                  let Checked2 = false;
                  if (v && v.active === true) {
                    Checked = true;
                  }
                  if (v && v.needhistory === true) {
                    Checked2 = true;
                  }

                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {cell.column.id === "active" && (
                        <>
                          <FormControlLabel
                            style={{
                              marginRight: 15,
                              marginLeft: 10,
                            }}
                            control={
                              <Checkbox
                                id={v && v.crontask_id}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={Checked}
                                //disabled={readonly}
                                color="primary"
                                //                              onChange={handleChangeSwitch}
                                /*
                                onChange={async (item) => {
                                  if (!readonly) {
                                    try {
                                      await ws_client.wss_setItem({
                                        tableName: 'Roles',
                                        item: role_item,
                                        call_nm: 'Model_update',
                                        where: {
                                          checked: Checked,
                                          unit_id: v.unit_id,
                                          //roleunit_id: v.roleunit_id,
                                        },
                                        action: {
                                          metod: 'setroleunits',
                                        },
                                        callback: setRoles,
                                      })
                                    } catch (e) {
                                      console.log('errr e', e)
                                    }
                                  }

                                }}
                                */
                              />
                            }
                            label={""}
                          />
                        </>
                      )}
                      {cell.column.id === "needhistory" && (
                        <>
                          <FormControlLabel
                            style={{
                              marginRight: 15,
                              marginLeft: 10,
                            }}
                            control={
                              <Checkbox
                                id={v && v.crontask_id + "h"}
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={Checked2}
                                //disabled={readonly}
                                color="primary"
                                //                              onChange={handleChangeSwitch}
                                /*
                                onChange={async (item) => {
                                  if (!readonly) {
                                    try {
                                      await ws_client.wss_setItem({
                                        tableName: 'Roles',
                                        item: role_item,
                                        call_nm: 'Model_update',
                                        where: {
                                          checked: Checked,
                                          unit_id: v.unit_id,
                                          //roleunit_id: v.roleunit_id,
                                        },
                                        action: {
                                          metod: 'setroleunits',
                                        },
                                        callback: setRoles,
                                      })
                                    } catch (e) {
                                      console.log('errr e', e)
                                    }
                                  }

                                }}
                                */
                              />
                            }
                            label={""}
                          />
                        </>
                      )}

                      {(cell.column.id !== "timeline" &&
                        cell.column.id !== "active" && (
                          <Link
                            as={Link}
                            variant="outline-primary"
                            //style={{ marginBottom: 5 }}

                            to={{
                              pathname: "/auth/crontasks/item",
                              /*                                      
                              state: {
                              action: {
                                mode: 'edit',
                                parent: cell.row.original.worktime_item,
                                id: cell.row.original.items.id,
                              },
                            },
  */
                              //items: cell.row.original.items,
                              //                            id: cell.row.original.items.id, //cell.row.original['worktime_id'],
                              //                            itemsworks: cell.row.original.items.works,
                              //                            mode: 'edit',
                              //parent: cell.row.original.worktime_item,
                            }}
                            state={{
                              id: cell.row.original.crontask_id,
                              //                          id: cell.row.original.role_id,
                              //                          id: cell.row.original.auth_item.id,
                              //                        role_id: cell.row.original.role_id,
                              //                            items: cell.row.original.items,
                            }}
                            onClick={() => {
                              dispatch({
                                type: "fetch/get", //_add',
                                payload: cell.row.original.crontask_id,
                                ob: "item_id",
                              });
                            }}
                          >
                            {cell.render("Cell")}
                          </Link>
                        )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  const ready = () => {
    if (body && body.crontasks) return true;
    else return false;
  };

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="80em">
            <Card>
              <CardContent>
                <>
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    //style={{ marginBottom: 10 }}
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        //backgroundColor: 'primary.dark',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    to={{
                      pathname: "/auth/crontasks/item",
                    }}
                    onClick={() => {
                      dispatch({
                        type: "fetch/get", //_add',
                        payload: -1, //новая запись!!!
                        ob: "item_id",
                      });
                    }}
                  >
                    {translate("resources.crontasks.addcrontask")}
                  </Button>{" "}
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    //style={{ marginBottom: 10 }}
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        //backgroundColor: 'primary.dark',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    to="/"
                  >
                    {translate("resources.button.return")}
                  </Button>
                  <Styles>
                    <br />
                    <Table
                      columns={columns}
                      data={data}
                      getHeaderProps={
                        (nameTheme &&
                          nameTheme !== "dark" &&
                          ((row) => {
                            return {
                              style: {
                                //                   background:
                                //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                              },
                            };
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              color: "#ffffffb3",
                              //                    background:
                              //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                            },
                          };
                        })
                      }
                      /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                      getRowProps={
                        (nameTheme &&
                          nameTheme !== "dark" &&
                          ((row) => {
                            return {
                              style: {
                                background:
                                  row.index % 2 === 0
                                    ? "rgba(0,0,0,.1)"
                                    : "white",
                              },
                            };
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              background:
                                row.index % 2 === 0
                                  ? "rgba(0,0,0,.1)"
                                  : "#616161e6", //'#ffffffb3',
                            },
                          };
                        })
                      }
                      getCellProps={(cellInfo) => ({
                        //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                        //            style: {
                        //              backgroundColor: `hsl(${
                        //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                        //              }, 100%, 67%)`,
                        //            },
                      })}
                    />
                  </Styles>
                  <br />
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="60em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default CronTasksList;
