import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";

import moment from "moment";

//import {  ListGroup, Row, Col } from 'react-bootstrap'
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { useTable } from "react-table";
import { useMediaQuery } from "@mui/material";
import styled from "styled-components";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { Card, CardContent, Box } from "@mui/material";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";
import { AuthContext } from "../../../context/AuthContext";
import { getURLAPI } from "../../../utils/getURL";
import { getUserSegments } from "../../../redux/sagas/fetch-local";

import { default as Spinner } from "../../../components/Spinner";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

function Table({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),

                  getHeaderProps(column),
                ])}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                let v = cell.row.original;

                return (
                  <td
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      //  getCellProps(cell),
                    ])}
                    align="center"
                    style={{
                      backgroundColor: cell.row.original.curentsession
                        ? "Yellow"
                        : "",
                    }}
                    //                    width="140px"
                  >
                    {(cell.column.id !== "timeline" && (
                      <Link
                        as={Link}
                        variant="outline-primary"
                        to={{
                          pathname: "/auth/session/item",
                          /*                                      
                            state: {
                            action: {
                              mode: 'edit',
                              parent: cell.row.original.worktime_item,
                              id: cell.row.original.items.id,
                            },
                          },
*/
                          //items: cell.row.original.items,
                          //                            id: cell.row.original.items.id, //cell.row.original['worktime_id'],
                          //                            itemsworks: cell.row.original.items.works,
                          //                            mode: 'edit',
                          //parent: cell.row.original.worktime_item,
                        }}
                        state={{
                          id: cell.row.original.unit_id,
                          //                          id: cell.row.original.role_id,
                          //                          id: cell.row.original.auth_item.id,
                          //                        role_id: cell.row.original.role_id,
                          //                            items: cell.row.original.items,
                        }}
                      >
                        {cell.render("Cell")}
                      </Link>
                    )) ||
                      cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

var columns = [];
var data = [];

const SessionList = () => {
  const body = useSelector((state) => state.app.body);
  const auth = useContext(AuthContext);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();

  const config = auth && auth.config;
  let URLAPI = getURLAPI(config);

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')
  const [startRefreshFilter, setStartRefreshFilter] = useState(true);
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result

    if (tableName === "Users" && action.metod === "get") {
      dispatch({
        type: "fetch/filter", //'fetch/get_add',
        payload: result,
        ob: "users",
      });
    }

    if (tableName === "Sessions_info") {
      //console.log('Sessions_info result ', result)
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result.sessions,
        ob: "sessions",
      });
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result.session,
        ob: "session",
      });
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result.blocked,
        ob: "blocked",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    if (tableName === "Sessions") {
      setStartRefresh(true);
    }
  };

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    handleSetRefresh: null, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Users",
        action: {
          metod: "get",
          GetStateServer: true,
        },
      },
    ],
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Sessions_info",
        tableName: "Sessions",
      },
    ],
  };

  // close page
  useEffect(
    () => () => {
      try {
        dispatch({
          type: "fetch/get",
          payload: true,
          ob: "close",
        });
      } catch (err) {
        console.log("fetch close error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && startRefreshFilter) {
    setStartRefreshFilter(false);
    ws_client.open_process(paramsFilter);
  }

  if (ws_client && ws_client.ws_login && startRefresh) {
    setStartRefresh(false);
    ws_client.open_process(params);
  }

  //END get all str from table Roles

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  const [user2, setUser2] = useState([]); //auth.Unitype || [])

  //Table
  columns = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: "user", //translate('resources.unit.fields.unit'), //'Объект',
      accessor: "user",
    },
    {
      Header: "start connect",
      accessor: "startdate",
    },
    {
      Header: "last connect",
      accessor: "lastdate",
    },
    {
      Header: "info: OS/Browser",
      accessor: "device",
    },
    {
      Header: "IP + local IP",
      accessor: "ip",
    },
    {
      Header: "isMobile",
      accessor: "isMobile",
    },
    {
      Header: "page blocked", //translate('resources.unit.fields.unit'), //'Объект',
      accessor: "blocked",
    },
  ];

  if (!isXSmall) {
    columns.push({
      Header: "socket_id", //translate('resources.unit.fields.code'), //'Код',
      accessor: "socket_id",
    });
  }

  data = [];
  let sessions = body.sessions;
  let session = body.session;
  let app = useSelector((state) => state.app);
  let Users = useSelector((state) => state.app.filter.users_filter);

  let Blocked = useSelector((state) => state.app.body.blocked);

  const getUserInfo = (id) => {
    let user =
      Users &&
      Users.filter((item) => {
        //        console.log('item', item)
        //        console.log('item.name', item.name)
        return item.id === id;
        //[item.name].contains('filter')
      });

    return user && user[0].login;
  };

  const getBlockedInfo = (id) => {
    let blocked =
      Blocked &&
      Blocked.filter((item) => {
        //        console.log('item', item)
        //        console.log('item.name', item.name)
        return item.session.socket_id === id;
        //[item.name].contains('filter')
      });

    return blocked.length === 0 ? "" : blocked[0].tableName;
  };

  let users = [];
  for (var ii in Users) {
    users.push({
      label: Users[ii].login,
      value: Users[ii].id,
    });
  }
  let num2 = 1;
  for (var iw2 in sessions) {
    data.push({
      num: num2++,
      user: getUserInfo(sessions[iw2].userId),
      blocked: getBlockedInfo(sessions[iw2].socket_id),
      userid: sessions[iw2].userId,
      device:
        sessions[iw2].cfg &&
        sessions[iw2].cfg.CFG &&
        sessions[iw2].cfg.CFG.os &&
        sessions[iw2].cfg.CFG.os.family +
          "  " +
          sessions[iw2].cfg.CFG.browser.family,
      ip:
        sessions[iw2].cfg &&
        sessions[iw2].cfg.ip + "  " + sessions[iw2].cfg.localIp,
      isMobile:
        sessions[iw2].cfg &&
        sessions[iw2].cfg.CFG &&
        sessions[iw2].cfg.CFG.isMobile
          ? "Yes"
          : "No",
      startdate: moment(sessions[iw2].startdate).format("DD.MM.YYYY HH:mm:ss"),
      lastdate: moment(sessions[iw2].lastdate).format("DD.MM.YYYY HH:mm:ss"),
      socket_id: sessions[iw2].socket_id,
      session_id: sessions[iw2].id,
      session: sessions[iw2],
      sessions: sessions,
      curentsession: sessions[iw2].socket_id === session.socket_id,
      //itemsworks: items[iw].works,
    });
  }
  const changeUser = (event, value) => {
    setUser2(value);
    //    auth.setWorker(value)
  };

  const ready = () => {
    if (body && body.session) return true;
    else return false;
  };

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="75em">
            <Card>
              <CardContent>
                <>
                  <Autocomplete
                    multiple
                    id="size-small-outlined" //      id="checkboxes-tags"
                    options={users}
                    onChange={changeUser}
                    size="small"
                    value={[...user2]}
                    //                defaultValue={(worker2 && worker2[0]) || []}
                    limitTags={4}
                    //              filterSelectedOptions
                    isOptionEqualToValue={(props, option) => {
                      let rez = false;
                      if (props.value === option.value) rez = true;
                      return rez;
                    }}
                    selectOnFocus
                    clearOnBlur
                    onFocus={(e) => {
                      //            console.log(e.target.readOnly)
                      return (e.target.readOnly = true);
                    }} //выключение клавиатуры при выборе      handleHomeEndKeys
                    //      disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      );
                    }}
                    style={{ width: isXSmall ? 210 : 400 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Выберите пользователя" //{translate('resources.unit.selectaccesstype')} //"Выберите Тип доступа"
                        variant="outlined"
                        //          variant="standard"
                        //          placeholder="Выберите Мастера"
                      />
                    )}
                  />
                  {/*
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    //variant="outlined"
                    sx={{ display: "inline-flex", alignItems: "center" }}
                    variant="outline-success"
                    to={{
                      pathname: "/auth/session/list",
                    }}
                    //state={unit_item}
                    //disabled={getAccessToChange()}
                    onClick={async () => {
                      let nums = [
                        /*               "01",
                        "02",
                        "03",
                        "04",
                        "05",
                        "06",
                        "07",
                        
                        "08",
                        "09",
                        "10",
                        "11",
                        "12",
                        "13",
                        "14",
                        "15",
                        "16",
                        "17",* /
                      ];
                      for (let i in nums) {
                        let Nums = [nums[i]];
                        let Parameters = {
                          nums: Nums,
                          startdate: "20170901",
                        };

                        try {
                          await ws_client.wss_setItem({
                            tableName: "Calendars",
                            call_nm: "Model_read",
                            action: {
                              metod: "GetAllTermins",
                              data: Parameters,
                            },
                          });
                        } catch (e) {
                          console.log("errr e", e);
                        }
                      }
                    }}
                  >
                    {"GetAllTermins"}
                  </Button>
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    //variant="outlined"
                    sx={{ display: "inline-flex", alignItems: "center" }}
                    variant="outline-success"
                    to={{
                      pathname: "/auth/session/list",
                    }}
                    //state={unit_item}
                    //disabled={getAccessToChange()}
                    onClick={async () => {
                      try {
                        await ws_client.wss_setItem({
                          tableName: "Customers",
                          call_nm: "Model_read",
                          action: { metod: "GetAllCustomers" },
                        });
                      } catch (e) {
                        console.log("errr e", e);
                      }
                    }}
                  >
                    {"GetAllCustomers"}
                  </Button>
                  */}
                  <Styles>
                    <br />
                    <Table
                      columns={columns}
                      data={data}
                      getHeaderProps={
                        (nameTheme &&
                          nameTheme !== "dark" &&
                          ((row) => {
                            return {
                              style: {
                                //                   background:
                                //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                              },
                            };
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              color: "#ffffffb3",
                              //                    background:
                              //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                            },
                          };
                        })
                      }
                      /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                      getRowProps={
                        (nameTheme &&
                          nameTheme !== "dark" &&
                          ((row) => {
                            return {
                              style: {
                                background:
                                  row.index % 2 === 0
                                    ? "rgba(0,0,0,.1)"
                                    : "white",
                              },
                            };
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              background:
                                row.index % 2 === 0
                                  ? "rgba(0,0,0,.1)"
                                  : "#616161e6", //'#ffffffb3',
                            },
                          };
                        })
                      }
                      getCellProps={(cellInfo) => ({
                        //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                        //            style: {
                        //              backgroundColor: `hsl(${
                        //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                        //              }, 100%, 67%)`,
                        //            },
                      })}
                    />
                  </Styles>
                  <br />
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="60em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default SessionList;
