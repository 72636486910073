import React from 'react'
import { URL_API, URL_WS } from '../globals'

export const getBASEURL = () => {
  let urlbase = new URL(document.baseURI)
  let baseurl = urlbase.protocol + '//' + urlbase.host //+ '/'
  return baseurl
}

export const getURLAPI = (config, typeurl) => {
  let rez = URL_API
  if (typeurl === 'URL_WS') rez = URL_WS

  if (config) {
    let URL_API1
    let URL_WS1
    let conf = config['config']
    for (let c in conf) {
      if (conf[c]['type'] === 'URL_API') {
        URL_API1 = conf[c]['url']
      }
      if (conf[c]['type'] === 'URL_WS') {
        URL_WS1 = conf[c]['url']
      }
    }

    if (typeurl) {
      if (typeurl === 'URL_API') {
        return URL_API1
      }
      if (typeurl === 'URL_WS') {
        return URL_WS1
      }
    } else {
      return URL_API1
    }
  }

  return rez
}

const GetURL = (url, config, typeurl) => {
  let URL_API
  if (config) {
    let conf = config['config']
    for (let c in conf) {
      if (conf[c]['type'] === 'URL_API') {
        URL_API = conf[c]['url']
      }
    }
  }

  let rez = url

  try {
    if (typeurl) {
      if (typeurl === 'URL_API') {
        return URL_API
      }
    } else {
      return URL_API
    }
    return rez
  } catch (error) {
    console.log(error)
    return rez
  }
}

export default GetURL
