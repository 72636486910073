import { Products } from "../../../types/Product";

import ProductCard from "../ProductCard";

//import styles from './ProductList.module.css';
import "./ProductList.module.css";

interface Props {
  products: Products;
  isPr3: boolean;
}

const ProductList = (props: Props) => {
  const { products, isPr3 } = props;
  return (
    <div
      className={"ProductList_productGrid"}
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "1.5rem",
        margin: "2rem 0",
      }}
      aria-label="Product list"
    >
      {products.map((product) => (
        <ProductCard product={product} key={product._id} isPr3={isPr3} />
      ))}
    </div>
  );
};

export default ProductList;
