import { useState, useCallback } from "react";
import { URL_API } from "../globals";
import axios from "axios";

import GetURL from "../utils/getURL";
export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /*
  function* getLogin(url, method = 'GET', body = null) {
    try {
      console.log(action)
      const request = yield fetch(url, {
        method,
        mode: 'cors',
        // credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      })

      const data = yield request.json()
      //      yield put({ type: 'fetch/item', payload: data.data, ob: name })
    } catch {}
  }
*/
  /*
  const asyncGetCall2 = async () => {
    try {
      const response = await fetch('http://localhost:3000/auth/login')
      const data = await response.json()
      // enter you logic when the fetch is successful
      console.log(data)
    } catch (error) {
      // enter your logic for when there is an error (ex. error toast)
      console.log(error)
    }
  }
*/

  //https://apiip.net/user/dashboard psw=ysystem
  const getIpInfo = useCallback(
    async function (ip) {
      // Set endpoint and your access key
      //const ip = '67.250.186.196'

      const urlapigetip = "https://api.db-ip.com/v2/free/self";
      const responseip = await axios.get(urlapigetip);

      /*      console.log(responseip) //responseip.ipAddress)
      console.log(
        responseip.data.ipAddress,
        responseip.data.city,
        responseip.data.countryName
      )
      */
      if (!ip) ip = responseip.data.ipAddress;
      const accessKey = "7fb897bc-679f-4332-897f-f82e4ec89a03";
      const url =
        "http://apiip.net/api/check?ip=" +
        responseip.data.ipAddress +
        "&accessKey=" +
        accessKey;

      // Make a request and store the response
      const response = await axios.get(url);
      const result = response.data;
      /*
result.city
result.countryName
50.5709
30.6643
https://www.google.com.ua/maps/@50.5709,30.6643,14z?hl=ru
*/
      // Output the "code" value inside "currency" object
      //      console.log(result)
      return { ip: responseip.data, dop: result };
    }
    //getIpInfo();
  );
  //     getIpInfo()

  const request = useCallback(
    async (url, method = "GET", body = null, headers = {}, URLAPI = null) => {
      setLoading(true);
      const login = body.login;
      const password = body.password;
      if (!URLAPI) URLAPI = URL_API;
      //    var tt = JSON.stringify({ login, password })

      //      const userId = JSON.parse(localStorage.userData).userId //localStorage.userData
      //      const token = '?token=' + JSON.parse(localStorage.userData).token //localStorage.userData
      //            'Authorization': `Bearer ${accessToken}`,

      try {
        if (body) {
          body = JSON.stringify({ login, password }); //JSON.stringify(body)
          //          headers['Content-Type'] = 'application/json'
          headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": "true",
            //'https://wed.home2020.keenetic.pro',
            //            'Access-Control-Allow-Origin': 'https://srv.home2020.keenetic.pro',
            // 'https://192.168.1.1',
            //            'Access-Control-Allow-Origin': '*', //https://srv.home2020.keenetic.pro',
            //            'Access-Control-Max-Age': '3600',
            //          'Access-Control-Allow-Headers': '*',
            "Access-Control-Allow-Methods": "GET, POST", //
            //            Connection: 'keep-alive',
            //'Access-Control-Allow-Method': 'GET, POST',
          };
        }
        /*
        const response1 = await fetch(`${URL_API}/jwt`, {
          method: 'GET',
          credentials: 'include', //this is what I need to tell the browser to include cookies
          mode: 'cors',
          //          body,
          headers,
        })
        const data1 = await response1.json()
        //        console.log(data1)
*/

        //let res = await fetch('https://api.db-ip.com/v2/free/self')

        const response = await fetch(`${URLAPI}/api/auth/login`, {
          //const response = await fetch(`${URL_API}/api/auth/login`, {
          method,
          credentials: "include", //this is what I need to tell the browser to include cookies
          mode: "cors",
          body,
          headers,
        });

        // получить значения всех cookies страницы
        //        var cookies = document.cookie
        // выведем куки в консоль браузера
        //        console.log('cookies', cookies)
        //        console.log('response', response)

        const data = await response.json();
        data.status = response.status;

        if (!response.ok) {
          throw new Error(data.message);
        }

        setLoading(false);

        return data;
      } catch (e) {
        setLoading(false);
        setError(e.message);
        throw e;
      }
    },
    []
  );

  const clearError = useCallback(() => setError(""), []);

  return {
    loading,
    error,
    request,
    clearError,
    getIpInfo,
    setLoading,
    setError,
  };
};
