import React, { useContext, useState, useCallback, useEffect } from "react";

import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";

import { IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Check from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView, { treeViewClasses } from "@mui/lab/TreeView";
import TreeItem, { useTreeItem, treeItemClasses } from "@mui/lab/TreeItem";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { styled as mstyled } from "@mui/material/styles";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import WarehouseIcon from "@mui/icons-material/Warehouse";

import { useTable } from "react-table";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Typography from "@material-ui/core/Typography";

import { Card, CardContent } from "@mui/material";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";
import { AuthContext } from "../../../context/AuthContext";
import { getURLAPI } from "../../../utils/getURL";

import { default as Spinner } from "../../../components/Spinner";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Styles2 = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

var columns = [];
var data = [];

const ProductVidList = () => {
  const app = useSelector((state) => state.app);
  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const auth = useContext(AuthContext);
  const dispatch = useDispatch();

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();

  let borderbox = "1px solid black";
  if (nameTheme) {
    borderbox = nameTheme !== "dark" ? "1px solid black" : "1px solid grey";
  }

  var vids = [];

  const [vid2, setvid2] = useState(auth.Worker || null); //[])

  const changevid = (event, newValue) => {
    setvid2(newValue);
    auth.setWorker(newValue);
  };

  const [ColumnOn, setupColumnOn] = useState();

  const handleChangeColumn = useCallback((event) => {
    setupColumnOn(!ColumnOn);
  });

  const [StockOn, setupStockOn] = useState();

  const handleChangeStock = useCallback((event) => {
    setupStockOn(!StockOn);
  });

  const [NumStockOn, setupNumStockOn] = useState(true);

  const handleChangeNumStock = useCallback((event) => {
    setupNumStockOn(!NumStockOn);
  });

  const [OptPriceOn, setupOptPriceOn] = useState(true);

  const handleChangeOptPriceOn = useCallback((event) => {
    setupOptPriceOn(!OptPriceOn);
  });

  const [selectedfolder, setupSelectedFolder] = useState();

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')
  const [startRefreshFilter, setStartRefreshFilter] = useState(true);
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result

    if (tableName === "Vts") {
      dispatch({
        type: "fetch/get_add", //'fetch/filter', //'fetch/get_add',
        payload: result,
        ob: "vids",
      });
    }

    if (tableName === "Tovs") {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "tovs",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true);
  };

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Vts",
        action: {
          metod: "get",
        },
      },
    ],
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Tovs",
        action: {
          metod: "get",
        },
      },
    ],
  };

  // close page
  useEffect(
    () => () => {
      try {
        dispatch({
          type: "fetch/get",
          payload: true,
          ob: "close",
        });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && startRefreshFilter) {
    setStartRefreshFilter(false);
    ws_client.open_process(paramsFilter);
  }

  if (ws_client && ws_client.ws_login && startRefresh) {
    setStartRefresh(false);
    ws_client.open_process(params);
  }

  //END get all str from table Roles

  //**************************************************************** */

  const StyledTreeItemRoot = mstyled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "&.Mui-expanded": {
        fontWeight: theme.typography.fontWeightRegular,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: "var(--tree-view-color)",
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 25,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
      },
    },
  }));

  function StyledTreeItem(props) {
    const {
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      labelText,
      Id,
      parentId,
      level,
      ...other
    } = props;

    const [contextMenu, setContextMenu] = React.useState(null);

    const handleContextMenu = (event) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    };

    const handleClose = () => {
      console.log("Menu Close click nodeId", Id);

      setContextMenu(null);
    };

    const handleAddGroup = () => {
      //      alert('Добавляем группу складов')
      console.log("Menu AddGroup click nodeId", Id);
      setContextMenu(null);
    };

    const handleDelGroup = () => {
      alert("Удаляем группу складов");
      console.log("Menu DelGroup click nodeId", Id);
      setContextMenu(null);
    };

    const handleAddWarehouse = () => {
      //      alert('Добавляем новый склад')
      console.log("Menu AddWarehouse click nodeId", Id);
      setContextMenu(null);
    };

    const handleEditWarehouse = () => {
      alert("Редактируем склад");
      console.log("Menu EditWarehouse click nodeId", Id);
      setContextMenu(null);
    };

    return (
      <>
        <StyledTreeItemRoot
          label={
            <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
              <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
              <div
                //onContextMenu={handleContextMenu}
                style={{ cursor: "context-menu" }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "inherit", flexGrow: 1 }}
                >
                  {labelText}
                </Typography>
                <Typography
                  variant="caption"
                  color="inherit"
                  /*
                <Menu
                  open={contextMenu !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenu !== null
                      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                      : undefined
                  }
                >
                  {(level === 0 || level === 1) && (
                    <>
                      <MenuItem
                        //onClick={handleAddGroup}
                        component={Link}
                        to="/pages/productvidfolders/item"
                        state={{
                          // id: Id, //cell.row.original['worktime_id'],
                          arg: {
                            parentid: Id,
                          },
                        }}
                      >
                        <ListItemIcon>
                          <AddCircleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        {translate('resources.productvid.add_group')}
                      </MenuItem>
                      <MenuItem
                        //onClick={handleDelGroup}
                        component={Link}
                        to="/pages/productvidfolders/item"
                        state={{
                          id: Id, //cell.row.original['worktime_id'],
                          //                         parentid: parentId,
                        }}
                      >
                        <ListItemIcon>
                          <Check fontSize="small" />
                        </ListItemIcon>
                        {translate('resources.productvid.edit_group')}
                      </MenuItem>
                    </>
                  )}
                  {level === 2 && (
                    <MenuItem
                      //onClick={handleDelGroup}
                      component={Link}
                      to="/pages/productvidfolders/item"
                      state={{
                        id: Id, //cell.row.original['worktime_id'],
                        //                         parentid: parentId,
                      }}
                    >
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                      {translate('resources.productvid.edit_group')}
                    </MenuItem>
                  )}
                  {level !== 3 && (
                    <MenuItem
                      //                      onClick={handleAddWarehouse}
                      component={Link}
                      to="/pages/productvid/item"
                      state={{
                        // id: Id, //cell.row.original['worktime_id'],
                        arg: {
                          vtid: Id,
                          vtname: labelText,
                        },
                      }}
                    >
                      <ListItemIcon>
                        <AddCircleOutlineIcon fontSize="small" />
                      </ListItemIcon>
                      {translate('resources.productvid.add_productvid')}
                    </MenuItem>
                  )}
                  {level === 3 && (
                    <>
                      <MenuItem
                        //                      onClick={handleEditWarehouse}
                        component={Link}
                        to="/pages/productvid/item"
                        state={{
                          id: Id, //cell.row.original['worktime_id'],
                        }}
                      >
                        <ListItemIcon>
                          <Check fontSize="small" />
                        </ListItemIcon>
                        {translate('resources.productvid.edit_productvid')}
                      </MenuItem>
                    </>
                  )}
                </Menu>
                */
                >
                  {labelInfo}
                </Typography>
              </div>
            </Box>
          }
          style={{
            "--tree-view-color": color,
            "--tree-view-bg-color": bgColor,
          }}
          {...other}
        />
      </>
    );
  }

  StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    Id: PropTypes.string,
    level: PropTypes.string,
  };

  const productvid = body.tovs; //productvid

  const productvidfolders = body.vids; //app.filter.productvidfolders_filter
  let folders = [
    {
      id: -1,
      name: "<Все>",
      fchild: [],
      wchild: [],
    },
  ];
  for (var f0 in productvidfolders) {
    if (!productvidfolders[f0].parentid)
      folders.push({
        id: productvidfolders[f0].id,
        name: productvidfolders[f0].name,
        fchild: [],
        wchild: [],
      });
  }

  for (var f1 in productvidfolders) {
    if (productvidfolders[f1].parentid)
      for (var fo1 in folders) {
        if (folders[fo1].id === productvidfolders[f1].parentid) {
          folders[fo1].fchild.push({
            id: productvidfolders[f1].id,
            name: productvidfolders[f1].name,
            fchild: [],
            wchild: [],
          });
        }
      }
  }

  for (var f2 in productvidfolders) {
    if (productvidfolders[f2].parentid)
      for (var fo2 in folders) {
        if (folders[fo2].fchild.length > 0) {
          for (var ch1 in folders[fo2].fchild) {
            if (
              folders[fo2].fchild[ch1].id === productvidfolders[f2].parentid
            ) {
              folders[fo2].fchild[ch1].fchild.push({
                id: productvidfolders[f2].id,
                name: productvidfolders[f2].name,
                fchild: [],
                wchild: [],
              });
            }
          }
        }
      }
  }

  for (var wh in productvid) {
    for (var ff in folders) {
      if (folders[ff].id === productvid[wh].vtid)
        folders[ff].wchild.push({
          id: productvid[wh].id,
          name: productvid[wh].name,
          art: productvid[wh].art,
        });

      if (folders[ff].fchild.length > 0) {
        for (var ff1 in folders[ff].fchild) {
          if (folders[ff].fchild[ff1].id === productvid[wh].vtid)
            folders[ff].fchild[ff1].wchild.push({
              id: productvid[wh].id,
              name: productvid[wh].name,
              art: productvid[wh].art,
            });

          if (folders[ff].fchild[ff1].fchild.length > 0) {
            for (var ff2 in folders[ff].fchild[ff1].fchild) {
              if (
                folders[ff].fchild[ff1].fchild[ff2].id === productvid[wh].vtid
              )
                folders[ff].fchild[ff1].fchild[ff2].wchild.push({
                  id: productvid[wh].id,
                  name: productvid[wh].name,
                  art: productvid[wh].art,
                });

              if (folders[ff].fchild[ff1].fchild[ff2].fchild.length > 0) {
                for (var ff3 in folders[ff].fchild[ff1].fchild[ff2].fchild) {
                  if (
                    folders[ff].fchild[ff1].fchild[ff2].fchild[ff3].id ===
                    productvid[wh].vtid
                  )
                    folders[ff].fchild[ff1].fchild[ff2].fchild[ff3].wchild.push(
                      {
                        id: productvid[wh].id,
                        name: productvid[wh].name,
                        art: productvid[wh].art,
                      }
                    );
                }
              }
            }
          }
        }
      }
    }
  }

  //************************************************************** */
  const [filter, setfilter] = useState(null);

  columns = [
    {
      Header: "№",
      accessor: "num",
    },
  ];

  if (ColumnOn) {
    columns.push({
      Header: "Вид ",
      accessor: "parentfolders",
    });

    columns.push({
      Header: "Подвид",
      accessor: "folders",
    });

    columns.push({
      Header: "Артикул",
      accessor: "art",
    });
  }

  columns.push({
    Header: translate("resources.productvid.fields.name"),
    accessor: "name",
  });

  if (OptPriceOn)
    columns.push({
      Header: translate("resources.price.fields.price3"),
      accessor: "price3",
    });

  if (!OptPriceOn)
    columns.push({
      Header: translate("resources.price.fields.price2"),
      accessor: "price2",
    });

  if (StockOn || !isXSmall) {
    if (NumStockOn) {
      columns.push({
        Header: translate("resources.price.fields.stock") + " 1",
        accessor: "stock1",
      });
    } else {
      columns.push({
        Header: translate("resources.price.fields.stock") + " 2",
        accessor: "stock2",
      });
    }
  }
  /*{
      Header: 'Коментарий',
      accessor: 'comments',
    },*/
  /*
    {
      Header: 'Тип',
      accessor: 'type',
    },
    
    {
      Header: 'Т',
      accessor: 'height',
    },
    {
      Header: 'Ш',
      accessor: 'width',F
    },
    {
      Header: 'Д',
      accessor: 'long',
    },
    */

  data = [];

  let num = 1;
  let productvids = body.tovs; //productvid

  if (selectedfolder && !isXSmall) {
    productvids = productvids.filter((item) => {
      //        console.log(item)

      return (
        (item.id === selectedfolder.item.id && selectedfolder.level === 3) ||
        (item.vtid === selectedfolder.item.id && selectedfolder.level === 1) ||
        (item.vts.parentid === selectedfolder.item.id &&
          selectedfolder.level === 0) ||
        selectedfolder.item.id === -1 ||
        false
      );

      //        return (
      //          item.unittypeid === unittypeid || unittypeid === null //&&
      //      (findTypeWorks(item, typeworkid) || typeworkid === null) &&
      //      (findWorks(item, workid) || workid === null) &&
      //      (item.customersid === customerid || customerid === null)
      //        )
    });
  }

  if (productvids && vid2 && isXSmall) {
    productvids = productvids.filter((item) => {
      //        console.log(item)

      return (
        //(item.id === vid2.value.id && vids.level === 3) ||
        (item.vtid === vid2.value && vid2.level === 1) ||
        (item.vts.parentid === vid2.value && vid2.level === 0) ||
        vid2.value === -1 ||
        false
      );

      //        return (
      //          item.unittypeid === unittypeid || unittypeid === null //&&
      //      (findTypeWorks(item, typeworkid) || typeworkid === null) &&
      //      (findWorks(item, workid) || workid === null) &&
      //      (item.customersid === customerid || customerid === null)
      //        )
    });
  }

  if (productvids) {
    /*
    console.log('vid2', vid2)
    console.log('customer2', customer2)
    console.log('date1', date1)
    console.log('date2', date2)
    console.log('workerid', workerid)
    console.log('customerid', customerid)
    //console.log(customer2['value']['0'].value)
    //workerid = null
    // customerid = null
    */
    if (filter)
      productvids = productvids.filter((item) => {
        //        console.log('item', item)
        //        console.log('item.name', item.name)
        return (
          (item.name &&
            item.name.toLowerCase().includes(filter.toLowerCase())) ||
          (item.lastName &&
            item.lastName.toLowerCase().includes(filter.toLowerCase())) ||
          (item.tel1 &&
            item.tel1.toLowerCase().includes(filter.toLowerCase())) ||
          (item.tel2 &&
            item.tel2.toLowerCase().includes(filter.toLowerCase())) ||
          (item.comments &&
            item.comments.toLowerCase().includes(filter.toLowerCase())) ||
          false
        );
        //[item.name].contains('filter')
      });
  }
  const get_price = (prices, plevelid) => {
    let price = "0";
    for (let i in prices) {
      if (prices[i].plevelid === plevelid) price = prices[i].price;
    }
    price = parseInt(price);

    return price;
  };

  for (var iw in productvids) {
    data.push({
      num: num++,
      name: productvids[iw].name,
      art: productvids[iw].art,
      price3: productvids[iw].prices && get_price(productvids[iw].prices, "5"), //опт 3
      price2: productvids[iw].prices && get_price(productvids[iw].prices, "4"), //опт 2
      parentfolders:
        productvids[iw].vts &&
        productvids[iw].vts.parent &&
        productvids[iw].vts.parent.name,
      folders: productvids[iw].vts && productvids[iw].vts.name,

      comments: productvids[iw].cmnt,
      productvid: productvids[iw],
    });
  }

  for (var i in productvidfolders) {
    vids.push({
      label: productvidfolders[i].parentid
        ? "*   " + productvidfolders[i].name
        : productvidfolders[i].name,
      value: productvidfolders[i].id,
      level: productvidfolders[i].parentid ? 1 : 0,
    });
  }

  //          body.productvids.map((Item) => <ItemList Item={Item} />)}

  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {((column.id === "price2" || column.id === "price3") && (
                    <Button
                      size="small"
                      //                        component={Link}
                      color="primary"
                      variant={OptPriceOn ? "contained" : "outlined"}
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                      onClick={(item) => {
                        handleChangeOptPriceOn();
                      }}
                    >
                      {column.render("Header")}
                    </Button>
                  )) ||
                    ((column.id === "stock1" || column.id === "stock2") && (
                      <Button
                        size="small"
                        //                        component={Link}
                        color="primary"
                        variant={NumStockOn ? "contained" : "outlined"}
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                        onClick={(item) => {
                          handleChangeNumStock();
                        }}
                      >
                        {column.render("Header")}
                      </Button>
                    )) ||
                    column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                      //align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "timeline" && (
                        <Link
                          as={Link}
                          variant="outline-primary"
                          //style={{ marginBottom: 5 }}

                          to={{
                            pathname: "/pages/productvid/item",
                          }}
                          state={{
                            id: cell.row.original.productvid.id, //cell.row.original['worktime_id'],
                          }}
                          onClick={() => {
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.productvid.id,
                              ob: "item_id",
                            });
                          }}
                        >
                          {cell.render("Cell")}
                        </Link>
                      )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <>
      <Box maxWidth="80em">
        <Card>
          <CardContent>
            <>
              <div
                //            className="hstack gap-2"
                style={{
                  marginTop: 0,
                  display: "flex",
                  //justifyContent: 'space-between',
                }}
              >
                {!isXSmall && (
                  <div>
                    <>
                      {false && (
                        <Box
                          sx={{
                            marginBottom: 0,
                            display: "flex",
                            alignItems: "center",
                            p: 0.5,
                            pr: 0,
                          }}
                        >
                          <div
                            //            className="hstack gap-2"
                            style={{
                              marginTop: 0,
                              display: "flex",
                              //            justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                marginTop: 1,
                                fontWeight: "inherit",
                                flexGrow: 1,
                              }}
                            >
                              {translate("resources.productvid.fields.folders")}
                            </Typography>
                            <div>
                              <Tooltip
                                title={translate(
                                  "resources.productvid.tooltip.add_rootfolder"
                                )} //"Добавление корневой Группы видов номенклатуры"
                                placement="right-end" //top-start"
                              >
                                <IconButton
                                  component={Link}
                                  to="/pages/productvidfolders/item"
                                  state={{
                                    // id: Id, //cell.row.original['worktime_id'],
                                    arg: {
                                      parentid: null,
                                    },
                                  }}
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div></div>
                          </div>
                        </Box>
                      )}
                    </>
                    <div style={{ borderSpacing: 0, border: borderbox }}>
                      <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{
                          height: 480,
                          flexGrow: 1,
                          width: 300,
                          //                maxWidth: 500,
                          overflowY: "auto",
                        }}
                      >
                        {folders.map((line0) => (
                          <StyledTreeItem
                            //ustomTreeItem
                            //StyledTreeItem
                            //id={line0.id}
                            nodeId={line0.id.toString()}
                            key={line0.id.toString()}
                            Id={line0.id.toString()}
                            parentId={line0.parentid}
                            //level={0}
                            labelText={line0.name}
                            labelIcon={FolderOpenIcon}
                            onClick={(item) => {
                              //                    console.log('onClick id', line0.id)
                              setupSelectedFolder({ level: 0, item: line0 });
                            }}
                            //                  onClick={(item) => {
                            //                    console.log('onClick id', line0.id)
                            //                  }}
                            /*                showbutton={true}
                handleadd={(item) => {
                  handleAdd(line0.id)
                }}
                handledel={(item) => {
                  handleDel(line0.id)
                }}
                */
                          >
                            {line0.fchild &&
                              line0.fchild.map((line1) => (
                                <StyledTreeItem
                                  //CustomTreeItem
                                  //StyledTreeItem
                                  nodeId={line1.id}
                                  key={line1.id}
                                  Id={line1.id}
                                  parentId={line1.parentid}
                                  //level={1}
                                  labelText={line1.name}
                                  labelIcon={FolderOpenIcon}
                                  onClick={(item) => {
                                    //                    console.log('onClick id', line0.id)
                                    setupSelectedFolder({
                                      level: 1,
                                      item: line1,
                                    });
                                  }}
                                  //                        onClick={(item) => {
                                  //                          console.log('onClick id', line1.id)
                                  //                        }}
                                  /*                      showbutton={true}
                      handleadd={(item) => {
                        handleAdd(line1.id)
                      }}
                      handledel={(item) => {
                        handleDel(line1.id)
                      }}
                      */
                                >
                                  {line1.fchild &&
                                    line1.fchild.map((line2) => (
                                      <>
                                        <StyledTreeItem
                                          //CustomTreeItem
                                          //StyledTreeItem
                                          nodeId={line2.id}
                                          key={line2.id}
                                          Id={line2.id}
                                          parentId={line2.parentid}
                                          //level={2}
                                          labelText={line2.name}
                                          labelIcon={FolderOpenIcon}
                                          onClick={(item) => {
                                            //                    console.log('onClick id', line0.id)
                                            setupSelectedFolder({
                                              level: 2,
                                              item: line2,
                                            });
                                          }}
                                          //                                onClick={(item) => {
                                          //                                  console.log('onClick id', line2.id)
                                          //                                }}
                                        >
                                          {line2.wchild &&
                                            line2.wchild.map((line3) => (
                                              <StyledTreeItem
                                                //CustomTreeItem
                                                //StyledTreeItem
                                                nodeId={line3.id}
                                                key={line3.id}
                                                Id={line3.id}
                                                parentId={line3.parentid}
                                                //level={3}
                                                labelText={
                                                  line3.art + " " + line3.name
                                                }
                                                labelIcon={WarehouseIcon}
                                                onClick={(item) => {
                                                  //                    console.log('onClick id', line0.id)
                                                  setupSelectedFolder({
                                                    level: 3,
                                                    item: line3,
                                                  });
                                                }}
                                                //                                      onClick={(item) => {
                                                //                                        console.log('onClick id', line3.id)
                                                //                                      }}
                                              ></StyledTreeItem>
                                            ))}
                                        </StyledTreeItem>
                                      </>
                                    ))}
                                  {line1.wchild &&
                                    line1.wchild.map((wline2) => (
                                      <StyledTreeItem
                                        //CustomTreeItem
                                        //StyledTreeItem
                                        nodeId={wline2.id}
                                        key={wline2.id}
                                        Id={wline2.id}
                                        //level={3}
                                        labelText={
                                          wline2.art + " " + wline2.name
                                        }
                                        labelIcon={WarehouseIcon}
                                        onClick={(item) => {
                                          //                    console.log('onClick id', line0.id)
                                          setupSelectedFolder({
                                            level: 3,
                                            item: wline2,
                                          });
                                        }}
                                        //                              onClick={(item) => {
                                        //                                console.log('onClick id', wline2.id)
                                        //                              }}
                                      ></StyledTreeItem>
                                    ))}
                                </StyledTreeItem>
                              ))}
                            {line0.wchild &&
                              line0.wchild.map((wline1) => (
                                <StyledTreeItem
                                  //CustomTreeItem
                                  //StyledTreeItem
                                  nodeId={wline1.id}
                                  key={wline1.id}
                                  Id={wline1.id}
                                  labelIcon={WarehouseIcon}
                                  //level={3}
                                  labelText={wline1.art + " " + wline1.name}
                                  onClick={(item) => {
                                    //                    console.log('onClick id', line0.id)
                                    setupSelectedFolder({
                                      level: 3,
                                      item: wline1,
                                    });
                                  }}
                                  //                        onClick={(item) => {
                                  //                          console.log('onClick id', wline1.id)
                                  //                        }}
                                ></StyledTreeItem>
                              ))}
                          </StyledTreeItem>
                        ))}
                      </TreeView>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <>
                    <Form>
                      <div
                        style={{
                          marginTop: 0,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {isXSmall && (
                          <Autocomplete
                            //multiple
                            id="size-small-outlined" //      id="checkboxes-tags"
                            options={vids}
                            onChange={changevid}
                            size="large" //                            "small"
                            style={{
                              fontSize: "xx-large",
                              width: isXSmall ? 210 : 400,
                            }}
                            value={vid2} //[...vid2]}
                            //                defaultValue={(vid2 && vid2[0]) || []}
                            limitTags={2}
                            //              filterSelectedOptions
                            isOptionEqualToValue={(props, option) => {
                              let rez = false;
                              if (props.value === option.value) rez = true;
                              return rez;
                            }}
                            selectOnFocus
                            clearOnBlur
                            onFocus={(e) => {
                              //            console.log(e.target.readOnly)
                              return (e.target.readOnly = true);
                            }} //выключение клавиатуры при выборе      handleHomeEndKeys
                            //      disableCloseOnSelect
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option, { selected }) => {
                              return (
                                <li {...props} style={{ fontSize: "x-large" }}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.label}
                                </li>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ fontSize: "xx-large" }}
                                label={translate("resources.price.changevid")} //'Выберите рабочее место' : 'Выбери мастера'}
                                variant="outlined"
                                //          variant="standard"
                                //          placeholder="Выберите Мастера"
                              />
                            )}
                          />
                        )}
                        <TextField
                          id="outlined-basic"
                          label="Поиск "
                          variant="outlined"
                          defaultValue={filter}
                          style={{
                            //marginLeft: 10,
                            marginBottom: 10,
                            width: 150,
                          }}
                          onChange={(item) => {
                            setfilter(item.target.value);
                          }}
                        />
                        {!isXSmall && (
                          <FormControlLabel
                            style={{
                              marginRight: isXSmall ? 0 : 15,
                              marginLeft: isXSmall ? 0 : 10,
                            }}
                            control={
                              <Switch
                                id={"switch-1"}
                                name={"column"}
                                color="primary"
                                onChange={handleChangeColumn}
                                //                          onFocus={onFocus}
                                checked={ColumnOn}
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                              />
                            }
                            label={"Вид + Арт."}
                          />
                        )}
                        {isXSmall && (
                          <FormControlLabel
                            style={{
                              marginRight: isXSmall ? 0 : 15,
                              marginLeft: isXSmall ? 0 : 10,
                            }}
                            control={
                              <Switch
                                id={"switch-1"}
                                name={"column"}
                                color="primary"
                                onChange={handleChangeStock}
                                //                          onFocus={onFocus}
                                checked={StockOn}
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                              />
                            }
                            label={translate("resources.price.fields.stock")}
                          />
                        )}
                      </div>
                      <Styles>
                        <Table
                          columns={columns}
                          data={data}
                          getHeaderProps={
                            (nameTheme &&
                              nameTheme !== "dark" &&
                              ((row) => {
                                return {
                                  style: {
                                    //                   background:
                                    //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                  },
                                };
                              })) ||
                            ((row) => {
                              return {
                                style: {
                                  color: "#ffffffb3",
                                  //                    background:
                                  //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                },
                              };
                            })
                          }
                          /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                          getRowProps={
                            (nameTheme &&
                              nameTheme !== "dark" &&
                              ((row) => {
                                return {
                                  style: {
                                    background:
                                      row.index % 2 === 0
                                        ? "rgba(0,0,0,.1)"
                                        : "white",
                                  },
                                };
                              })) ||
                            ((row) => {
                              return {
                                style: {
                                  background:
                                    row.index % 2 === 0
                                      ? "rgba(0,0,0,.1)"
                                      : "#616161e6", //'#ffffffb3',
                                },
                              };
                            })
                          }
                          getCellProps={(cellInfo) => ({
                            //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                            style: { fontSize: "xx-large" },
                          })}
                        />
                      </Styles>
                    </Form>
                    <br />
                  </>
                </div>
              </div>
            </>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ProductVidList;
