import React, {
  useState,
  ErrorInfo,
  ReactNode,
  ComponentType,
  HtmlHTMLAttributes,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import { TitleComponent } from "./Title";

import { AppBar as DefaultAppBar, AppBarProps } from "./AppBar";
import { Sidebar as DefaultSidebar } from "./Sidebar";
import { Menu as DefaultMenu, MenuProps } from "./Menu";
import { Error, ErrorProps } from "./Error";
import { SkipNavigationButton } from "./SkipNavigationButton";
import { useSidebarState } from "./useSidebarState";
import SubMenu from "./SubMenu";

//import { Button } from '@mui/material'
import Box from "@mui/material/Box";
import { UserMenu } from "./UserMenu";
import { MenuItemLink } from "./MenuItemLink";
//import DefaultIcon from '@mui/icons-material/ViewList'
//import BookIcon from '@mui/icons-material/Book'
//import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import StoreIcon from "@mui/icons-material/Store"; //import LabelIcon from '@mui/icons-material/Label'
import BusinessIcon from "@mui/icons-material/Business";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import RouteIcon from "@mui/icons-material/Route";
//import PendingActionsIcon from '@mui/icons-material/PendingActions'
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import AppsIcon from "@mui/icons-material/Apps";
import HiveIcon from "@mui/icons-material/Hive";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";

import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
//import ThreePIcon from '@mui/icons-material/ThreeP'
//import AppBlockingIcon from '@mui/icons-material/AppBlocking'
//import BlockIcon from '@mui/icons-material/Block'
import ContentCutIcon from "@mui/icons-material/ContentCut";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ConstructionIcon from "@mui/icons-material/Construction";
import CategoryIcon from "@mui/icons-material/Category";
import ApiIcon from "@mui/icons-material/Api";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import TitleIcon from "@mui/icons-material/Title";

import SyncAltIcon from "@mui/icons-material/SyncAlt";

import SendIcon from "@mui/icons-material/Send";

import MessageIcon from "@mui/icons-material/Message";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
//import TelegramIcon from '@mui/icons-material/Telegram'
//import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import ViberIcon, { TelegramIcon, WhatsAppIcon } from "./MessendgerIcon";
import { Typography, useMediaQuery, Theme } from "@mui/material";

import { DashboardMenuItem } from "./DashboardMenuItem";
import { useSelector } from "react-redux";

import { darkTheme, lightTheme } from "./Them/themes";
import { useTheme } from "./Them/useTheme";
import { defaultTheme } from "./Them/defaultTheme";
import { ThemeProvider } from "./Them/";
import { getAccessCode } from "../access";

import { useTranslate } from "../lib/translate/i18n/useTranslate";



export const Layout = (props: LayoutProps) => {
  const {
    appBar: AppBar = DefaultAppBar,
    children,
    className,
    theme, //= defaultTheme,
    //    dashboard,
    error: errorComponent,
    menu: Menu = DefaultMenu,
    sidebar: Sidebar = DefaultSidebar,
    title,
    ...rest
  } = props;

  const translate = useTranslate();

  const [open] = useSidebarState();
  let error: Error; //= <Error>{}
  const [errorInfo, setErrorInfo] = useState(); //<ErrorInfo>(null)
  const um = UserMenu;
  const handleError = (error: Error, info: ErrorInfo) => {
    //    setErrorInfo(info)
  };
  const [state, setState] = useState({
    menuCatalog: false,
    menuAdmin: false,
    menuMessanger: false,
    menuAddv: false,
  });
  const [Title, setTitle] = useState(title || "Dashboard"); //translate('pos.menu.diary')) //'Ежедневник')
  //const [theme1, setTheme] = useTheme()

  type MenuName = "menuCatalog" | "menuAdmin" | "menuAddv" | "menuMessanger";

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const access = useSelector((state: any) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  //  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      {theme && (
        <ThemeProvider theme={theme}>
          <StyledLayout className={clsx("layout", className)} {...rest}>
            <SkipNavigationButton />
            <div className={LayoutClasses.appFrame}>
              <AppBar open={open} title={Title} userMenu={true}></AppBar>
              <main className={LayoutClasses.contentWithSidebar}>
                <Sidebar>
                  <Menu hasDashboard={true}>
                    <Box
                      sx={{
                        width: open ? 200 : 50,
                        marginTop: 1,
                        marginBottom: 1,
                        transition: (theme) =>
                          theme.transitions.create("width", {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                          }),
                      }}
                    >
                      <DashboardMenuItem
                        onClick={() => setTitle("Dashboard")} //translate('pos.menu.diary'))}
                      ></DashboardMenuItem>
                      {(isadmin ||
                        getAccessCode(
                          "MENU_ORDERS",
                          access && access.access
                        )) && (
                        <MenuItemLink
                          key={"orders"}
                          to="/pages/order/list"
                          primaryText={translate("pos.menu.orders")} //"Поиск по записям"
                          leftIcon={<ListAltIcon />}
                          onClick={(item) =>
                            setTitle(translate("pos.menu.orders"))
                          }
                        />
                      )}
                      {(isadmin ||
                        getAccessCode(
                          "MENU_DIRECTORY",
                          access && access.access
                        )) && (
                        <SubMenu
                          handleToggle={() => handleToggle("menuCatalog")}
                          isOpen={state.menuCatalog}
                          name={translate("pos.menu.catalogs")} //"Справочники"
                          icon={<LibraryBooksIcon />} //<orders.icon />}
                          dense={true} //dense}
                        >
                          {(isadmin ||
                            getAccessCode(
                              "MENU_HEADS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"head_name"}
                              to="/pages/head/list"
                              primaryText={translate("pos.menu.head")} //"Сотрудники""Работники"
                              leftIcon={<PeopleAltIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.head"))
                              }
                            />
                          )}
                          {(isadmin ||
                            getAccessCode(
                              "MENU_SUPERVISORS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"supervisor_name"}
                              to="/pages/supervisor/list"
                              primaryText={translate("pos.menu.supervisor")} //"Сотрудники""Работники"
                              leftIcon={<PeopleIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.supervisor"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_MANAGERS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"manager_name"}
                              to="/pages/manager/list"
                              primaryText={translate("pos.menu.manager")} //"Сотрудники""Работники"
                              leftIcon={<PersonIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.manager"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_STORES",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"store_name"}
                              to="/pages/store/list"
                              primaryText={translate("pos.menu.stores")} //"Клиенты"
                              leftIcon={<StoreIcon />}
                              onClick={(item) =>
                                setTitle(translate("pos.menu.stores"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_CUSTOMERS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"customer_name1"}
                              to="/pages/customers/list"
                              primaryText={translate("pos.menu.clients")} //"Клиенты"
                              leftIcon={<BusinessIcon />}
                              onClick={(item) =>
                                setTitle(translate("pos.menu.clients"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_TOVUNITS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"tovunit_name"}
                              to="/pages/tovunit/list"
                              primaryText={translate("pos.menu.tovunits")} //"Клиенты"
                              leftIcon={<AutoAwesomeMotionIcon />}
                              onClick={(item) =>
                                setTitle(translate("pos.menu.tovunits"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_TOVS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"tov_name"}
                              to="/pages/tov/list"
                              primaryText={translate("pos.menu.tovs")} //"Клиенты"
                              leftIcon={<CategoryIcon />}
                              onClick={(item) =>
                                setTitle(translate("pos.menu.tovs"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_TYPEPRICE",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"typeprice_name"}
                              to="/pages/typeprice/list"
                              primaryText={translate("pos.menu.typeprices")} //"Клиенты"
                              leftIcon={<ConstructionIcon />}
                              onClick={(item) =>
                                setTitle(translate("pos.menu.typeprices"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_PRICE",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"price_name"}
                              to="/pages/price/list"
                              primaryText={translate("pos.menu.prices")} //"Клиенты"
                              leftIcon={<AttachMoneyIcon />}
                              onClick={(item) =>
                                setTitle(translate("pos.menu.prices"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_FORMPAY",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"formpay_name"}
                              to="/pages/formpay/list"
                              primaryText={translate("pos.menu.formpays")} //"Клиенты"
                              leftIcon={<HiveIcon />}
                              onClick={(item) =>
                                setTitle(translate("pos.menu.formpays"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_VIDPAY",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"vidpay_name"}
                              to="/pages/vidpay/list"
                              primaryText={translate("pos.menu.vidpays")} //"Клиенты"
                              leftIcon={<AppsIcon />}
                              onClick={(item) =>
                                setTitle(translate("pos.menu.vidpays"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_CUSTOMERS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"customer_name2"}
                              to="/pages/customers/list"
                              primaryText={translate("pos.menu.clients")} //"Клиенты"
                              leftIcon={<BusinessIcon />}
                              onClick={(item) =>
                                setTitle(translate("pos.menu.clients"))
                              }
                            />
                          )}
                          {(isadmin ||
                            getAccessCode(
                              "MENU_PRODUCTVID",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"productvid_name"}
                              to="/pages/productvid/list"
                              primaryText={translate("pos.menu.productvid")} //"Виды номенклатуры"
                              leftIcon={<AppsIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.productvid"))
                              }
                            />
                          )}
                          {(isadmin ||
                            getAccessCode(
                              "MENU_ROUTE",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"route"}
                              to="/pages/route/list"
                              primaryText={translate("pos.menu.route")} //"Поиск по записям"
                              leftIcon={<RouteIcon />}
                              onClick={(item) =>
                                setTitle(translate("pos.menu.route"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_WORKERS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"Users"}
                              to="/pages/workers/list"
                              primaryText={translate("pos.menu.workers")} //"Users"  users
                              leftIcon={<PeopleIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.workers"))
                              }
                            />
                          )}
                        </SubMenu>
                      )}
                      {(isadmin ||
                        getAccessCode(
                          "MENU_USERS",
                          access && access.access
                        )) && (
                        <SubMenu
                          handleToggle={() => handleToggle("menuAdmin")}
                          isOpen={state.menuAdmin}
                          name={translate("pos.menu.admin")} //"Админ"
                          icon={<AdminPanelSettingsIcon />} //<orders.icon />}
                          dense={true} //dense}
                        >
                          {(isadmin ||
                            getAccessCode(
                              "MENU_USERS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"Users"}
                              to="/auth/auth/list"
                              primaryText={translate("pos.menu.users")} //"Users"  users
                              leftIcon={<AccountCircleIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.users"))
                              }
                            />
                          )}
                          {(isadmin ||
                            getAccessCode(
                              "MENU_ROLE",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"role"}
                              to="/auth/role/list"
                              state={{ _scrollToTop: true }}
                              primaryText={translate("pos.menu.role")} //"Роли"
                              leftIcon={<ManageAccountsIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.role"))
                              }
                            />
                          )}
                          {(isadmin ||
                            getAccessCode(
                              "MENU_ACCESS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"unit"}
                              to="/auth/unit/list"
                              state={{ _scrollToTop: true }}
                              primaryText={translate("pos.menu.access")} //"Доступы" access
                              leftIcon={<SettingsIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.access"))
                              }
                            />
                          )}
                          {(isadmin ||
                            getAccessCode(
                              "MENU_ACCESS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"unittype"}
                              to="/auth/unittype/list"
                              state={{ _scrollToTop: true }}
                              primaryText={translate("pos.menu.accesstype")} //"Типы Доступа"
                              leftIcon={<TitleIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.accesstype"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_CRONTASKS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"crontasks"}
                              to="/auth/crontasks/list"
                              primaryText={translate("pos.menu.crontasks")} // "CRON задачи"
                              leftIcon={<AssignmentIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.crontasks"))
                              }
                            />
                          )}

                          {(isadmin ||
                            getAccessCode(
                              "MENU_SESSIONS",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"sessions"}
                              to="/auth/session/list"
                              primaryText={translate("pos.menu.sessions")} // "Удаленные записи"
                              leftIcon={<SyncAltIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.sessions"))
                              }
                            />
                          )}
                          {(isadmin ||
                            getAccessCode(
                              "MENU_RABBITMQ_ADMIN",
                              access && access.access
                            )) && (
                            <MenuItemLink
                              key={"amqpsettings"}
                              to="/auth/amqp/list"
                              state={{ _scrollToTop: true }}
                              primaryText={translate("pos.menu.amqpsettings")} //"Настройки доступа к RabbitMQ
                              leftIcon={<MessageIcon />}
                              onClick={() =>
                                setTitle(translate("pos.menu.amqpsettings"))
                              }
                            />
                          )}
                        </SubMenu>
                      )}
                    </Box>
                  </Menu>
                </Sidebar>
                <div id="main-content" className={LayoutClasses.content}>
                  <br />
                  {children}
                </div>
              </main>
            </div>
          </StyledLayout>
        </ThemeProvider>
      )}
    </>
  );

  /*
  return (
    <StyledLayout className={clsx('layout', className)} {...rest}>
      <SkipNavigationButton />
      <div className={LayoutClasses.appFrame}>
        <AppBar open={open} title={title} />
        <main className={LayoutClasses.contentWithSidebar}>
          <Sidebar>
            <Menu hasDashboard={!!dashboard} />
          </Sidebar>
          <div id="main-content" className={LayoutClasses.content}>
            <ErrorBoundary
              onError={handleError}
              fallbackRender={({ error, resetErrorBoundary }) => (
                <Error
                  error={error}
                  errorComponent={errorComponent}
                  errorInfo={errorInfo}
                  resetErrorBoundary={resetErrorBoundary}
                  title={title}
                />
              )}
            >
              {children}
            </ErrorBoundary>
          </div>
        </main>
      </div>
    </StyledLayout>
  )
*/
};
export interface CoreLayoutProps {
  children?: ReactNode;
  //  dashboard?: DashboardComponent;
  menu?: ComponentType<{
    hasDashboard?: boolean;
  }>;
  title?: TitleComponent;
}

export interface LayoutProps
  extends CoreLayoutProps,
    Omit<HtmlHTMLAttributes<HTMLDivElement>, "title"> {
  appBar?: ComponentType<AppBarProps>;
  className?: string;
  theme?: object;
  error?: ComponentType<ErrorProps>;
  menu?: ComponentType<MenuProps>;
  sidebar?: ComponentType<{ children: ReactNode }>;
}

export interface LayoutState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

const PREFIX = "RaLayout";
export const LayoutClasses = {
  appFrame: `${PREFIX}-appFrame`,
  contentWithSidebar: `${PREFIX}-contentWithSidebar`,
  content: `${PREFIX}-content`,
};

const StyledLayout = styled("div", {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  minHeight: "100vh",
  backgroundColor: theme.palette.background.default,
  position: "relative",
  minWidth: "fit-content",
  width: "100%",
  color: theme.palette.getContrastText(theme.palette.background.default),

  [`& .${LayoutClasses.appFrame}`]: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginTop: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(7),
    },
  },
  [`& .${LayoutClasses.contentWithSidebar}`]: {
    display: "flex",
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${LayoutClasses.content}`]: {
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: 0,
    padding: 0,
    [theme.breakpoints.up("xs")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(1),
    },
  },
}));
