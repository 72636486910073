export default {
  search: "Search:",
  totalRows: "Total rows:",
  rows: "Rows:",
  selected: "Selected",
  rowsPerPage: "", //"Rows per page:",
  page: "Page:",
  of: "of",
  prev: "Prev",
  next: "Next",
  columnVisibility: "Column visibility",
  noResults: "No Results found",
};
