import React, { useContext, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

//import {  ListGroup, Row, Col } from 'react-bootstrap'
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { useTable } from "react-table";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import { Avatar } from "@mui/material";
//import { useDispatch } from 'react-redux'
import { useTranslate } from "../../lib/translate/i18n/useTranslate";
import { Card, CardContent, Box } from "@mui/material";

import { AuthContext } from "../../context/AuthContext";
import { getURLAPI } from "../../utils/getURL";

import FormControlLabel from "@mui/material/FormControlLabel";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { default as Spinner } from "../../components/Spinner";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

var columns = [];
var data = [];

const AuthAdminList = () => {
  const body = useSelector((state) => state.app.body);

  /*
  const dispatch = useDispatch()

  if (!body.auth_item) {
    //циклится при возврате при сохранении работы - т.к. при возвратесохраняло в item

    dispatch({
      type: 'fetch/get',
      payload: {
        getdeletedwt: s,
      },
      ob: 'worktime_item',
    })

 
    dispatch({
      type: 'app/set',
      payload: {
        id: worktime_item,
        field: 'userId',
        value: auth.userId,
      },
      ob: 'worktime_item',
    })
  }
*/

  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const issuper = (access && access.issuper) || false;
  const idUser = access && access.user && access.user.id;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const auth1 = useContext(AuthContext);
  const dispatch = useDispatch();

  //START get all str from table Roles
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth1.ws_client;

  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_Item = function (_result) {
    const { tableName, result, readonly, action } = _result;
  };
  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result)
    dispatch({
      type: "fetch/get_add",
      payload: result,
      ob: "auth_list",
    });
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    //console.log('result handle1 ', result)

    setStartRefresh(true);
  };

  const params = {
    URL_WS: getURLAPI(auth1.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth1.userId, tocken: auth1.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Users",
        action: {
          metod: "getAvatar",
          //GetStateServer: true
        },
      },
    ],
  };

  //*********** */
  // close page
  useEffect(
    () => () => {
      try {
        dispatch({
          type: "fetch/get",
          payload: true,
          ob: "close",
        });
      } catch (err) {
        console.log("fetch close error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params);
    setStartRefresh(false);
  }

  //END get all str from table Roles

  let greybkcolor = "rgba(255,255,255,.3)";

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  columns = [
    {
      Header: "№",
      accessor: "num",
    },
    {
      Header: "login",
      accessor: "login",
    },
    {
      Header: translate("resources.auth.fields.name"), //'Имя',
      accessor: "fullName",
    },
    {
      Header: translate("resources.auth.fields.isworker"), //'Фамилия',
      accessor: "isworker",
    },
  ];

  if (isadmin) {
    columns.push({
      Header: "Type ",
      accessor: "istype",
    });
  }
  if (!isXSmall) {
    columns.push({
      Header: translate("resources.auth.fields.tel"), //'Телефон ',
      accessor: "phone",
    });

    columns.push({
      Header: "email",
      accessor: "email",
    });
    columns.push({
      Header: translate("resources.auth.fields.avatar"), //'Аватар',
      accessor: "avatar",
    });
  }

  data = [];

  let auth = body.auth_list; //body.avatar //body.auth

  //auth = body.avatar

  auth =
    auth &&
    auth.filter((item) => {
      //        console.log('item', item)
      //        console.log('item.name', item.name)
      return issuper || item.id === idUser || !item.isadmin || false;
      //[item.name].contains('filter')
    });

  let num = 1;

  for (var iw in auth) {
    data.push({
      num: num++,
      login: auth[iw].login,
      fullName: auth[iw].fullName,

      email: auth[iw].email,
      phone: auth[iw].phone, //worker && avatar[iw].worker.tel1,
      isworker: auth[iw].isworker,
      avatar: auth[iw].avatar && auth[iw].avatar.avatar,
      istype: auth[iw].isadmin ? "Admin" : "Сотрудник",
      //      role: auth[iw].role,
      auth: auth[iw],
    });
  }

  //        {body.auth && body.auth.map((Item) => <ItemList Item={Item} />)}

  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  const avatar = cell.row.original.avatar;
                  let UserAvatar = "";
                  if (avatar && avatar.data) {
                    UserAvatar = "";
                    for (let i in avatar.data) {
                      UserAvatar =
                        UserAvatar + String.fromCharCode(avatar.data[i]);
                    }
                  }
                  let v = cell.row.original;
                  let Checked = false;
                  if (v && v.isworker) {
                    Checked = true;
                  }

                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {cell.column.id === "avatar" && UserAvatar !== "" && (
                        <div>
                          <img
                            className="image-gallery-thumbnail-image"
                            src={UserAvatar}
                            height={"50px"}
                            width={"50px"} //                          style={(width = 100)}
                          ></img>
                        </div>
                      )}
                      {cell.column.id === "avatar" && UserAvatar === "" && (
                        <Avatar />
                      )}
                      {cell.column.id === "isworker" && (
                        <>
                          <FormControlLabel
                            style={{
                              marginRight: 15,
                              marginLeft: 10,
                            }}
                            control={
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={Checked}
                                color="primary"
                              />
                            }
                            label={""}
                          />
                        </>
                      )}

                      {cell.column.id !== "avatar" &&
                        cell.column.id !== "isworker" && (
                          <Link
                            as={Link}
                            variant="outline-primary"
                            //style={{ marginBottom: 5 }}

                            to={{
                              pathname: "/auth/auth/item",
                              //search: 'test',
                            }}
                            state={{
                              id: cell.row.original.auth.id, //cell.row.original['worktime_id'],
                            }}
                            onClick={() => {
                              dispatch({
                                type: "fetch/get_add",
                                payload: cell.row.original.auth.id,
                                ob: "item_id",
                              });
                            }}
                          >
                            {cell.render("Cell")}
                          </Link>
                        )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  const ready = () => {
    if (body && body.auth_list) return true;
    else return false;
  };

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="65em">
            <Card>
              <CardContent>
                <>
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    //style={{ marginBottom: 10 }}
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        //backgroundColor: 'primary.dark',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    to="/auth/auth/item"
                    onClick={() => {
                      dispatch({
                        type: "fetch/get", //_add',
                        payload: -1, //новая запись!!!
                        ob: "item_id",
                      });
                    }}
                  >
                    {translate("resources.button.add")}
                  </Button>{" "}
                  <Button
                    size="small"
                    component={Link}
                    color="primary"
                    variant="outlined"
                    //style={{ marginBottom: 10 }}
                    sx={{
                      display: "inline-flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: "primary.light",
                        //backgroundColor: 'primary.dark',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                    to="/"
                  >
                    {translate("resources.button.return")}
                  </Button>
                  <Styles>
                    <br />
                    <Table
                      columns={columns}
                      data={data}
                      getHeaderProps={
                        (nameTheme &&
                          nameTheme !== "dark" &&
                          ((row) => {
                            return {
                              style: {
                                //                   background:
                                //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                              },
                            };
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              color: "#ffffffb3",
                              //                    background:
                              //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                            },
                          };
                        })
                      }
                      /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                      getRowProps={
                        (nameTheme &&
                          nameTheme !== "dark" &&
                          ((row) => {
                            return {
                              style: {
                                background:
                                  row.index % 2 === 0
                                    ? "rgba(0,0,0,.1)"
                                    : "white",
                              },
                            };
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              background:
                                row.index % 2 === 0
                                  ? "rgba(0,0,0,.1)"
                                  : "#616161e6", //'#ffffffb3',
                            },
                          };
                        })
                      }
                      getCellProps={(cellInfo) => ({
                        //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                        //            style: {
                        //              backgroundColor: `hsl(${
                        //                120 * ((120 - cellInfo.value) / 120) * -1 + 120
                        //              }, 100%, 67%)`,
                        //            },
                      })}
                    />
                  </Styles>
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="65em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default AuthAdminList;
