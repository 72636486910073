import React from 'react'
import { useContext, Fragment, useCallback, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import TextField from '@mui/material/TextField'
import {
  useMediaQuery,
  Avatar,
  Box,
  Divider,
  Tabs,
  Tab,
  Theme,
} from '@mui/material'
import { useTable } from 'react-table'
import styled from 'styled-components'
import PeopleIcon from '@mui/icons-material/People'
import SettingsIcon from '@mui/icons-material/Settings'

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'

import { Card, CardContent } from '@mui/material'
import { useTranslate } from '../../../lib/translate/i18n/useTranslate'

import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { AuthContext } from '../../../context/AuthContext'
import { getURLAPI } from '../../../utils/getURL'

import { default as Spinner } from '../../../components/Spinner'

import Autocomplete from '@mui/material/Autocomplete'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const defaultPropGetter = () => ({})

function Table({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),

                  getHeaderProps(column),
                ])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                let v = cell.row.original

                return (
                  <td
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      //  getCellProps(cell),
                    ])}
                    align="center"
                    //                    width="140px"
                  >
                    {(cell.column.id !== 'timeline' && (
                      <Link
                        as={Link}
                        variant="outline-primary"
                        //style={{ marginBottom: 5 }}

                        to={{
                          pathname: '/auth/role/item-unit',
                          /*                                      
                            state: {
                            action: {
                              mode: 'edit',
                              parent: cell.row.original.worktime_item,
                              id: cell.row.original.items.id,
                            },
                          },
*/
                          //items: cell.row.original.items,
                          //                            id: cell.row.original.items.id, //cell.row.original['worktime_id'],
                          //                            itemsworks: cell.row.original.items.works,
                          //                            mode: 'edit',
                          //parent: cell.row.original.worktime_item,
                        }}
                        state={{
                          mode: 'get',
                          parent: cell.row.original.role_item,
                          id: cell.row.original.unit_id,
                          //                          id: cell.row.original.role_id,
                          //                          id: cell.row.original.auth_item.id,
                          //                        role_id: cell.row.original.role_id,
                          //                            items: cell.row.original.items,
                        }}
                      >
                        {cell.render('Cell')}
                      </Link>
                    )) ||
                      cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

var columns = []
var data = []

var columns1 = []
var data1 = []

const RoleItem = () => {
  const role_item = useSelector((state) => state.app.body.role_item)
  const app = useSelector((state) => state.app)
  const dispatch = useDispatch()
  const access = useSelector((state) => state.app.access.access)
  const isadmin = (access && access.isadmin) || false
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const body = useSelector((state) => state.app.body)

  const auth = useContext(AuthContext)

  const config = auth.config
  let URLAPI = getURLAPI(config)

  const nameTheme = access && access.user && access.user.theme
  const translate = useTranslate()

  //START get all str from table Roles
  const [startRefreshFilter, setStartRefreshFilter] = useState(true)
  const [startRefresh, setStartRefresh] = useState(false)

  //замыкание
  const ws_client = auth.ws_client //body.ws_client

  const item_id = body.item_id || (role_item && role_item.id)

  const readonly = body.readonly || false

  //  const [startWss, setstartWss] = useState(true)

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result
    //console.log('result handle1 ', result)
    if (tableName === 'Roles') {
      dispatch({
        type: 'fetch/get_add',
        payload: result,
        ob: 'role_item',
      })
      dispatch({
        type: 'fetch/get_add',
        payload: readonly,
        ob: 'readonly',
      })
    }
    if (tableName === 'Units') {
      dispatch({
        type: 'fetch/filter', //'fetch/get_add',
        payload: result,
        ob: 'unit',
      })
    }
    if (tableName === 'UnitTypes') {
      dispatch({
        type: 'fetch/filter', //'fetch/get_add',
        payload: result,
        ob: 'unittype',
      })
    }
  }

  const Refresh_handle = function (result, event, parent) {
    const { tableName, action, metod } = result
    /*    console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )
    */
    if (tableName === 'Roles') {
      setStartRefresh(true)
      //setStartRefreshFilter(true)
    }
  }

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: null, // Refresh_handle,
    //handleSetRefresh: null, //Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'Units',
        action: {
          metod: 'get',
        },
      },
      {
        model_call_nm: 'Model_read',
        tableName: 'UnitTypes',
        action: {
          metod: 'get',
        },
      },
    ],
  }

  const params = {
    URL_WS: getURLAPI(auth.config, 'URL_WS'), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    handleSetRefresh: null, // Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: 'Model_read',
        tableName: 'Roles',
        where: { id: item_id },
        action: {
          metod: 'getOne',
          isBlocked: true,
        },
      },
    ],
  }

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client && ws_client.deblock({ tableName: 'Roles', id: item_id })
      } catch (err) {
        console.log('deblock error ', err)
      }
    },
    []
  )

  if (ws_client && ws_client.ws_login && startRefreshFilter) {
    ws_client.open_process(paramsFilter)
    setStartRefreshFilter(false)
    setStartRefresh(true)
  }

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params) //startRefresh
    setStartRefresh(false)
    setStartRefreshFilter(false)
  }

  const setRoles = function (_result, _parent) {
    //    ws_client.open_process(params) //startRefresh
    //    setStartRefresh(true)
  }

  //END get all str from table Roles

  const [filter, setfilter] = useState(null)

  const [unittype2, setunittype] = useState()

  const changeUnittype = (event, value) => {
    setunittype(value)
  }

  let unittypes = []
  let Unittypes = app.filter.unittype_filter
  for (var s in Unittypes) {
    unittypes.push({
      label: Unittypes[s].name,
      value: Unittypes[s].id,
    })
  }

  const [Access2, setAccess] = useState()

  const changeAccess = (event, value) => {
    setAccess(value)
  }

  let Access = []
  Access.push({
    label: 'On',
    value: true,
  })
  Access.push({
    label: 'Off',
    value: false,
  })

  /*
  //tabs

  const [tabsValues, setTabs] = useState({ status: 'role' })

  const tabs = [
    { id: 'role', name: 'Роль', icon: <PeopleIcon /> }, //PersonIcon
    { id: 'access', name: 'Доступы / Роли', icon: <SettingsIcon /> },
  ]

  const handleChange = useCallback(
    (event, value) => {
      setTabs({ status: value })
    },
    [tabsValues] //displayedFilters, tabsValues, setFilters]
  )

  //tabs
*/
  columns = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.role.fields.unit'), //'Об`ект',
      accessor: 'unit',
    },
    {
      Header: translate('resources.role.fields.comments'), //'Коментарии',
      accessor: 'comments',
    },
    {
      Header: translate('resources.role.fields.access'), //'Доступ',
      accessor: 'access',
    },
    {
      Header: translate('resources.role.fields.active'), //'Активно',
      accessor: 'active',
    },
  ]

  data = []
  //          body.workplace.map((Item) => <ItemList Item={Item} />)}
  var cloneDeep = require('lodash.clonedeep')

  let units
  if (role_item && role_item.units) units = cloneDeep(role_item.units)
  let num = 1
  for (var iw in units) {
    data.push({
      num: num++,
      unit: units[iw].unit.name,
      comments: units[iw].unit.comments,
      access: units[iw].type_access,
      active: units[iw].unit.active,
      unit_id: units[iw].unitid,
      units: units[iw],
      role_item: role_item,
      //itemsworks: items[iw].works,
    })
  }

  columns1 = [
    {
      Header: '№',
      accessor: 'num',
    },
    {
      Header: translate('resources.role.fields.unit'), //'Об`ект',
      accessor: 'unit',
    },
    {
      Header: translate('resources.role.fields.access'), //'Доступ',
      accessor: 'access',
    },
    {
      Header: translate('resources.role.fields.code'), //'Код',
      accessor: 'code',
    },
    {
      Header: translate('resources.role.fields.type'), //'Тип',
      accessor: 'type',
    },
    {
      Header: translate('resources.role.fields.comments'), //'Коментарии',
      accessor: 'comments',
    },
  ]

  data1 = []
  //          body.workplace.map((Item) => <ItemList Item={Item} />)}

  let allunits

  if (app && app.filter) allunits = cloneDeep(app.filter.unit_filter)
  if (allunits && units)
    for (let i in allunits) {
      for (let j in units) {
        if (units[j].unit.id === allunits[i].id) {
          allunits[i].access = 'ok'
          allunits[i].roleunit_id = units[j].id
        }
      }
    }

  if (filter)
    allunits =
      allunits &&
      allunits.filter((item) => {
        //        console.log('item', item)
        //        console.log('item.name', item.name)
        return (
          (item.name &&
            item.name.toLowerCase().includes(filter.toLowerCase())) ||
          (item.code &&
            item.code.toLowerCase().includes(filter.toLowerCase())) ||
          (item.comments &&
            item.comments.toLowerCase().includes(filter.toLowerCase())) ||
          false
        )
        //[item.name].contains('filter')
      })

  if (Access2) {
    allunits =
      allunits &&
      allunits.filter((item) => {
        return (
          (item.access && item.access === 'ok' && Access2.value) ||
          (!item.access && !Access2.value) ||
          false
        )
        //[item.name].contains('filter')
      })
  }

  if (unittype2) {
    allunits =
      allunits &&
      allunits.filter((item) => {
        return (item.unittype && item.unittype.id === unittype2.value) || false
        //[item.name].contains('filter')
      })
  }

  let num1 = 1
  for (var iw1 in allunits) {
    data1.push({
      num: num1++,
      unit: allunits[iw1].name,
      comments: allunits[iw1].comments,
      type: allunits[iw1].unittype.name,
      code: allunits[iw1].code,
      access: allunits[iw1].access,
      unit_id: allunits[iw1].id,
      roleunit_id: allunits[iw1].roleunit_id,
      units: allunits[iw1],
      role_item: role_item,
      //itemsworks: items[iw].works,
    })
  }

  const handleChangeSwitch = useCallback((event) => {
    let rez = 0
    //    setDopInfoOn(!DopInfoOn)
  })

  function Table1({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      })

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  let v = cell.row.original
                  let Checked = false
                  if (v && v.access === 'ok') {
                    Checked = true
                  }
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {cell.column.id === 'access' && (
                        <>
                          <FormControlLabel
                            style={{
                              marginRight: 15,
                              marginLeft: 10,
                            }}
                            control={
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={Checked}
                                disabled={readonly}
                                /*                    <Switch
                                id={1}
                                name={'DopInfo'}*/
                                color="primary"
                                //                              onChange={handleChangeSwitch}
                                onChange={async (item) => {
                                  if (!readonly) {
                                    try {
                                      await ws_client.wss_setItem({
                                        tableName: 'Roles',
                                        item: role_item,
                                        call_nm: 'Model_update',
                                        where: {
                                          checked: Checked,
                                          unit_id: v.unit_id,
                                          //roleunit_id: v.roleunit_id,
                                        },
                                        action: {
                                          metod: 'setroleunits',
                                        },
                                        callback: setRoles,
                                      })
                                    } catch (e) {
                                      console.log('errr e', e)
                                    }
                                  }

                                  /*
                                  if (Checked) {
                                    setTimeout(() => {
                                      dispatch({
                                        type: 'FETCH_TBL',
                                        urlapi: URLAPI,
                                        id: role_item.id,
                                        mode: 'off',
                                        act: 'setroleunits',
                                        parent: role_item,
                                        item: v.units,
                                        roleunit_id: v.roleunit_id,
                                      })
                                    }, 50)
                                  } else {
                                    //set on
                                    setTimeout(() => {
                                      dispatch({
                                        type: 'FETCH_TBL',
                                        urlapi: URLAPI,
                                        id: role_item.id,
                                        mode: 'on',
                                        act: 'setroleunits',
                                        parent: role_item,
                                        item: v.units,
                                        roleunit_id: v.roleunit_id,
                                      })
                                    }, 50)
                                  }
                                  */
                                }}
                                //                          onFocus={onFocus}
                                //+                            checked={DopInfoOn}
                                //checked={Checked}
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                                /*
                                                          <div
                            className="vstack gap-2"
                            style={{ marginBottom: 5 }}
                          >
                            <div className="hstack gap-2">
                              <Autocomplete
                                //              multiple
                                id="size-small-outlined" //      id="checkboxes-tags"
                                options={tprocess}
                                onChange={changeProcess}
                                size="small"
                                value={tprocess2}
                                limitTags={2}
                                //              filterSelectedOptions
                                isOptionEqualToValue={(props, option) => {
                                  let rez = false
                                  if (props.value === option.value) rez = true
                                  return rez
                                }}
                                selectOnFocus
                                clearOnBlur
                                onFocus={(e) => {
                                  //            console.log(e.target.readOnly)
                                  return (e.target.readOnly = true)
                                }} //выключение клавиатуры при выборе      handleHomeEndKeys
                                //      disableCloseOnSelect
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option, { selected }) => {
                                  return (
                                    <li {...props}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.label}
                                    </li>
                                  )
                                }}
                                style={{ width: isXSmall ? 310 : 400 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Выбери Производственный цикл"
                                    variant="outlined"
                                    //          variant="standard"
                                    //          placeholder="Выберите Мастера"
                                  />
                                )}
                              />
                            </div>
                          </div>

                                */
                              />
                            }
                            label={''}
                          />
                        </>
                      )}

                      {cell.column.id !== 'access' && cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const getChange = () => {
    if (role_item && role_item.change === true) return 'contained'
    else return 'outlined'
  }
  const getAccessToChange = () => {
    return readonly || false
  }

  const ready = () => {
    if (role_item) return true
    else return false
  }

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="60em">
            <Card>
              <CardContent>
                <>
                  <div
                    //            className="hstack gap-2"
                    style={{
                      marginTop: -10,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      id="outlined-basic"
                      label={translate('resources.role.fields.role')} //"Роль"
                      //variant="standard"
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      //                    {translate('resources.product.title')}
                    >
                      {translate('resources.role.fields.role')}
                    </Typography>
                    {readonly && (
                      <Typography
                        id="outlined-basic"
                        label="Title"
                        //variant="standard"
                        //className="title h6"
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          //  marginRight: 275,
                          color: 'red',
                        }}
                        //                      {translate('resources.workers.readonly')}
                      >
                        Readonly
                      </Typography>
                    )}
                  </div>

                  {role_item && (
                    <div
                      //            className="hstack gap-2"
                      style={{
                        marginTop: -10,
                        display: 'flex',
                        //              justifyContent: 'space-between',
                      }}
                    >
                      <>
                        <TextField
                          id="outlined-basic"
                          label={translate('resources.role.fields.namerole')} //"Название роли"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 5,
                            marginRight: 15,
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          value={(role_item && role_item.name) || ''}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              role_item.change = true
                              dispatch({
                                type: 'app/set',
                                payload: {
                                  id: role_item,
                                  field: 'name',
                                  value: item.target.value,
                                },
                                ob: 'role_item',
                              })
                            }
                          }}
                        />

                        <TextField
                          id="outlined-basic"
                          label={translate('resources.role.fields.comments')} //"Коментарии"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            width: 480,
                            marginTop: 5,
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          value={(role_item && role_item.comments) || ''}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              role_item.change = true
                              dispatch({
                                type: 'app/set',
                                payload: {
                                  id: role_item,
                                  field: 'comments',
                                  value: item.target.value,
                                },
                                ob: 'role_item',
                              })
                            }
                          }}
                        />
                      </>
                    </div>
                  )}
                  <>
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      //variant="outlined"
                      sx={{ display: 'inline-flex', alignItems: 'center' }}
                      variant={getChange()} //"outline-success"
                      to={{
                        pathname: '/auth/role/List',
                      }}
                      //state={role_item}
                      disabled={getAccessToChange()}
                      onClick={async () => {
                        try {
                          await ws_client.wss_setItem({
                            tableName: 'Roles',
                            item: role_item,
                            call_nm: 'Model_update',
                            where: { id: role_item.id },
                            action: { metod: 'set' },
                          })
                        } catch (e) {
                          console.log('errr e', e)
                        }
                      }}
                    >
                      {translate('resources.button.save')}
                    </Button>{' '}
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      variant="outlined"
                      sx={{ display: 'inline-flex', alignItems: 'center' }}
                      //variant={getChange()} //"outline-success"
                      to={{
                        pathname: '/auth/role/list',
                      }}
                    >
                      {translate('resources.button.cancel')}
                    </Button>
                  </>

                  <div
                    //          className="hstack gap-3"
                    style={{
                      marginTop: 10,
                      //            width: 1240,
                      display: 'flex',
                      //          justifyContent: 'space-between',
                    }}
                  >
                    <TextField
                      id="outlined-basic"
                      label={translate('resources.button.search')} //                "Поиск"
                      variant="outlined"
                      defaultValue={filter}
                      style={{
                        marginBottom: 15,
                        width: 380,
                        //                  display: 'flex',
                        //                justifyContent: 'space-between',
                      }}
                      onChange={(item) => {
                        setfilter(item.target.value)
                      }}
                    />
                    <Autocomplete
                      //   multiple
                      id="size-small-outlined" //      id="checkboxes-tags"
                      options={Access}
                      onChange={changeAccess}
                      size="small"
                      value={Access2} //customerproducttypes]}
                      limitTags={4}
                      //              filterSelectedOptions
                      isOptionEqualToValue={(props, option) => {
                        let rez = false
                        if (props.value === option.value) rez = true
                        return rez
                      }}
                      selectOnFocus
                      clearOnBlur
                      onFocus={(e) => {
                        //            console.log(e.target.readOnly)
                        return (e.target.readOnly = true)
                      }} //выключение клавиатуры при выборе      handleHomeEndKeys
                      //      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 1 }}
                              checked={selected}
                            />
                            {option.label}
                          </li>
                        )
                      }}
                      //            style={{ width: isXSmall ? 350 : 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={translate('resources.role.fields.access')} //'Доступ'} //translate('resources.product.fields.vid')} //"Вид"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginLeft: 15,
                            marginRight: 15,
                            width: 280,
                            //    display: 'flex',
                            //    justifyContent: 'space-between',
                          }}
                          //          variant="standard"
                          //          placeholder="Выберите Мастера"
                        />
                      )}
                    />

                    <Autocomplete
                      //   multiple
                      id="size-small-outlined" //      id="checkboxes-tags"
                      options={unittypes}
                      onChange={changeUnittype}
                      size="small"
                      value={unittype2} //customerproducttypes]}
                      limitTags={4}
                      //              filterSelectedOptions
                      isOptionEqualToValue={(props, option) => {
                        let rez = false
                        if (props.value === option.value) rez = true
                        return rez
                      }}
                      selectOnFocus
                      clearOnBlur
                      onFocus={(e) => {
                        //            console.log(e.target.readOnly)
                        return (e.target.readOnly = true)
                      }} //выключение клавиатуры при выборе      handleHomeEndKeys
                      //      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              //    style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.label}
                          </li>
                        )
                      }}
                      //            style={{ width: isXSmall ? 350 : 500 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={translate('resources.role.fields.type')} //{'Тип'} //translate('resources.product.fields.vid')} //"Вид"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            width: 280,
                            //  display: 'flex',
                            //  justifyContent: 'space-between',
                          }}
                          //          variant="standard"
                          //          placeholder="Выберите Мастера"
                        />
                      )}
                    />
                  </div>

                  <Styles>
                    <Table1
                      columns={columns1}
                      data={data1}
                      getHeaderProps={
                        (nameTheme &&
                          nameTheme !== 'dark' &&
                          ((row) => {
                            return {
                              style: {
                                //                   background:
                                //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                              },
                            }
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              color: '#ffffffb3',
                              //                    background:
                              //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                            },
                          }
                        })
                      }
                      /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                      getRowProps={
                        (nameTheme &&
                          nameTheme !== 'dark' &&
                          ((row) => {
                            return {
                              style: {
                                background:
                                  row.index % 2 === 0
                                    ? 'rgba(0,0,0,.1)'
                                    : 'white',
                              },
                            }
                          })) ||
                        ((row) => {
                          return {
                            style: {
                              background:
                                row.index % 2 === 0
                                  ? 'rgba(0,0,0,.1)'
                                  : '#616161e6', //'#ffffffb3',
                            },
                          }
                        })
                      }
                      getCellProps={(cellInfo) => ({
                        //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                        //                style: {
                        //                  backgroundColor: `hsl(${
                        //                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                        //                  }, 100%, 67%)`,
                        //                },
                      })}
                    />
                  </Styles>
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="60em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  )
}

export default RoleItem
