import React, { useContext, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SelectControl from "../../../components/SelectControl";
import Typography from "@material-ui/core/Typography";
import TextField from "@mui/material/TextField";
import { useMediaQuery } from "@mui/material";

import { useCallback } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

import { Card, CardContent, Box } from "@mui/material";
import { useTranslate } from "../../../lib/translate/i18n/useTranslate";
import { AuthContext } from "../../../context/AuthContext";
import { getURLAPI } from "../../../utils/getURL";
import { getAccessCode } from "../../../access";

import { default as Spinner } from "../../../components/Spinner";

const CronTaskItem = () => {
  const crontask_item = useSelector((state) => state.app.body.crontask_item);
  const auth = useContext(AuthContext);
  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();

  const config = auth && auth.config;
  let URLAPI = getURLAPI(config);

  //START get all str from table Roles
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  const item_id = body.item_id;

  const readonly = body.readonly || false;

  //  const [startWss, setstartWss] = useState(true)

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result)
    dispatch({
      type: "fetch/get_add",
      payload: result,
      ob: "crontask_item",
    });
    dispatch({
      type: "fetch/get_add",
      payload: readonly,
      ob: "readonly",
    });
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true);
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    handleChangeData: Refresh_handle,
    //handleSetRefresh: Refresh_handle, //setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "CronTasks",
        where: { id: item_id },
        action: {
          metod: "getOne",
          isBlocked: true,
        },
      },
    ],
  };

  //deblock item whet close page
  useEffect(
    () => () => {
      try {
        if (!readonly)
          ws_client &&
            ws_client.deblock({ tableName: "CronTasks", id: item_id });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (item_id && ws_client && ws_client.ws_login && startRefresh) {
    ws_client.open_process(params);
    setStartRefresh(false);
  }

  //END get all str from table Roles

  let greybkcolor = "rgba(255,255,255,.3)";

  if (nameTheme) {
    greybkcolor =
      nameTheme !== "dark" ? "rgba(255,255,255,1.0)" : "rgba(255,255,255,.3)";
  }

  const dispatch = useDispatch();

  const getChange = () => {
    if (crontask_item && crontask_item.change === true) return "contained";
    else return "outlined";
  };

  const getAccessToChange = () => {
    return readonly || false;
  };

  const ready = () => {
    if (crontask_item) return true;
    else return false;
  };

  return (
    <>
      {ready() && (
        <>
          <Box maxWidth="32em">
            <Card>
              <CardContent>
                <>
                  <div
                    //            className="hstack gap-2"
                    style={{
                      marginTop: -20,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      //id="outlined-basic-title"
                      label="title"
                      //variant="standard"
                      className="title h6"
                      style={{
                        marginTop: 15,
                        marginBottom: 15,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {translate("resources.crontasks.title")}
                    </Typography>
                    {readonly && (
                      <Typography
                        //id="outlined-basic-ro"
                        label="readonly"
                        //variant="standard"
                        //className="title h6"
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          //  marginRight: 275,
                          color: "red",
                        }}
                        //                      {translate('resources.workers.readonly')}
                      >
                        Readonly
                      </Typography>
                    )}
                  </div>

                  {crontask_item && (
                    <>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          //marginTop: -20,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          id="outlined-basic-nm1"
                          label={translate("resources.crontasks.fields.name")} //"Название Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          value={(crontask_item && crontask_item.name) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              crontask_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: crontask_item,
                                  field: "name",
                                  value: item.target.value,
                                },
                                ob: "crontask_item",
                              });
                            }
                          }}
                        />

                        <TextField
                          id="outlined-basic-tc2"
                          label={translate(
                            "resources.crontasks.fields.taskcode"
                          )} //"Код Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          value={
                            (crontask_item && crontask_item.taskcode) || ""
                          }
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              crontask_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: crontask_item,
                                  field: "taskcode",
                                  value: item.target.value,
                                },
                                ob: "crontask_item",
                              });
                            }
                          }}
                        />
                      </div>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          //marginTop: -20,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          id="outlined-basic_schedule3"
                          label={translate(
                            "resources.crontasks.fields.schedule"
                          )} //"Код Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          value={
                            (crontask_item && crontask_item.schedule) || ""
                          }
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              crontask_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: crontask_item,
                                  field: "schedule",
                                  value: item.target.value,
                                },
                                ob: "crontask_item",
                              });
                            }
                          }}
                        />
                        {(isadmin ||
                          getAccessCode(
                            "SHOW_TASKSACTIVE",
                            access && access.access
                          )) && (
                          <FormControlLabel
                            style={{
                              //              marginRight: 15,
                              marginLeft: isXSmall ? 0 : 5,
                            }}
                            control={
                              <Switch
                                id={"switch1"}
                                name={"Active"}
                                color="primary"
                                //onChange={handleChangeActive}
                                onChange={(item, value) => {
                                  if (!getAccessToChange()) {
                                    crontask_item.change = true;
                                    dispatch({
                                      type: "app/set",
                                      payload: {
                                        id: crontask_item,
                                        field: "active",
                                        value: value,
                                      },
                                      ob: "crontask_item",
                                    });
                                  }
                                }}
                                disabled={getAccessToChange()}
                                //                          onFocus={onFocus}
                                checked={crontask_item && crontask_item.active}
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                              />
                            }
                            label={
                              crontask_item.active
                                ? "Active " + translate("resources.switch.on")
                                : "Active " + translate("resources.switch.off")
                            }
                          />
                        )}
                      </div>
                      <div
                        //            className="hstack gap-2"
                        style={{
                          //marginTop: -20,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          id="outlined-basic_params4"
                          label={translate("resources.crontasks.fields.params")} //"Код Доступа"
                          //variant="standard"
                          variant="outlined"
                          style={{
                            marginBottom: 15,
                            marginTop: 15,
                          }}
                          value={(crontask_item && crontask_item.params) || ""}
                          onChange={(item) => {
                            if (!getAccessToChange()) {
                              crontask_item.change = true;
                              dispatch({
                                type: "app/set",
                                payload: {
                                  id: crontask_item,
                                  field: "params",
                                  value: item.target.value,
                                },
                                ob: "crontask_item",
                              });
                            }
                          }}
                        />
                        {(isadmin ||
                          getAccessCode(
                            "SHOW_TASKSHISTORY",
                            access && access.access
                          )) && (
                          <FormControlLabel
                            style={{
                              //              marginRight: 15,
                              marginLeft: isXSmall ? 0 : 5,
                            }}
                            control={
                              <Switch
                                id={"switch2"}
                                name={"needhistory"}
                                color="primary"
                                //onChange={handleChangeActive}
                                onChange={(item, value) => {
                                  if (!getAccessToChange()) {
                                    crontask_item.change = true;
                                    dispatch({
                                      type: "app/set",
                                      payload: {
                                        id: crontask_item,
                                        field: "needhistory",
                                        value: value,
                                      },
                                      ob: "crontask_item",
                                    });
                                  }
                                }}
                                disabled={getAccessToChange()}
                                //                          onFocus={onFocus}
                                checked={
                                  crontask_item && crontask_item.needhistory
                                }
                                //                      {...sanitizeInputRestProps(rest)}
                                //                      {...options}
                                //                      disabled={disabled}
                              />
                            }
                            label={
                              crontask_item.needhystory
                                ? "History " + translate("resources.switch.on")
                                : "History " + translate("resources.switch.off")
                            }
                          />
                        )}
                      </div>
                      <TextField
                        id="outlined-basic-cmnt1"
                        label={translate("resources.crontasks.fields.comments")} //"Коментарии"
                        //variant="standard"
                        variant="outlined"
                        style={{
                          marginBottom: 15,
                          marginTop: 15,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value={(crontask_item && crontask_item.comments) || ""}
                        onChange={(item) => {
                          if (!getAccessToChange()) {
                            crontask_item.change = true;
                            dispatch({
                              type: "app/set",
                              payload: {
                                id: crontask_item,
                                field: "comments",
                                value: item.target.value,
                              },
                              ob: "crontask_item",
                            });
                          }
                        }}
                      />
                    </>
                  )}

                  <br />
                  <>
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      //variant="outlined"
                      sx={{ display: "inline-flex", alignItems: "center" }}
                      variant={getChange()} //"outline-success"
                      to={{
                        pathname: "/auth/crontasks/list",
                      }}
                      //state={crontask_item}
                      disabled={getAccessToChange()}
                      onClick={async () => {
                        try {
                          if (!getAccessToChange())
                            await ws_client.wss_setItem({
                              tableName: "CronTasks",
                              item: crontask_item,
                              call_nm: "Model_update",
                              where: { id: crontask_item.id },
                              action: { metod: "set" },
                            });
                        } catch (e) {
                          console.log("errr e", e);
                        }
                      }}
                    >
                      {translate("resources.button.save")}
                    </Button>{" "}
                    <Button
                      size="small"
                      component={Link}
                      color="primary"
                      variant="outlined"
                      sx={{ display: "inline-flex", alignItems: "center" }}
                      //variant={getChange()} //"outline-success"
                      to={{
                        pathname: "/auth/crontasks/list",
                      }}
                    >
                      {translate("resources.button.cancel")}
                    </Button>{" "}
                    <br />
                  </>
                </>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {!ready() && (
        <>
          <Box maxWidth="45em">
            <Card>
              <CardContent>
                <Spinner />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );
};

export default CronTaskItem;
