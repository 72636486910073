import { takeEvery, select, call, delay, put } from 'redux-saga/effects'
//import { LOCATION_CHANGE, push } from 'connected-react-router'
//import { ReduxRouterState } from '@lagunovsky/redux-react-router'
import { matchRoutes } from 'react-router-dom'
//import { matchRoutes, matchPath } from 'react-router-dom'
import { ROUTER_ON_LOCATION_CHANGED, push } from '../../redux-react-router' //'@lagunovsky/redux-react-router'
//import { UNSAFE_NavigationContext } from 'react-router-dom'

import {
  getList,
  getItem,
  setItem,
  delItem,
  getItem_item,
  getItem_item2,
  getFilter,
  setLogin,
  changePsw,
  getAccessUser,
  clearAccessUser,
  getAvatarUser,
  getMegaStorage,
  getImg,
  getListUser,
  getWorktime,
  getDeletedItem,
  setDeleted,
  getAvatars,
  getUserSegments,
  createMessage,
  getCustomerMessages,
  getWorktimeMessages,
  setMessage,
  setRoleUnits,
  setUserUnits,
} from './fetch-local'

const FETCH_LIST = 'FETCH_LIST'
const FETCH_TBL = 'FETCH_TBL'

const FETCH_ACCESS = 'FETCH_ACCESS'
const FETCH_MEGA = 'FETCH_MEGA'
const FETCH_GETIMG = 'FETCH_GETIMG'
const FETCH_GETWT = 'FETCH_GETWT'
const FETCH_FILTER = 'FETCH_FILTER'
const FETCH_NEWCUSTOMER = 'FETCH_NEWCUSTOMER'
const FETCH_MESSAGE = 'FETCH_MESSAGE'

function* go(path) {
  yield delay(1)
  yield put(push(path))
}

function* watch_location(action) {
  let router
  let {
    path,
    params = {},
    urlapi,
  } = yield select((state) => {
    router = state.router
    //    console.log('watch_location state.router', router)
    return {
      path: router.location.pathname,
      params: router.location.state,
      urlapi: router.location.urlapi,
    }
  })
  if (params === null) params = {}
  /*
  const { path, params = {} } = yield select((state) => ({
    path: state.router.location.pathname,
    params: state.router.location.state,
  }))
*/
  if (path !== '/') {
    let route2 = matchRoutes([{ path: '/:type/:ob/:mode' }], path)
    //    console.log('watch_location route2', route2)
    const type = route2 && route2[0].params.type
    const ob = route2 && route2[0].params.ob
    const mode = route2 && route2[0].params.mode

    const { id } = params

    /*
    const {
      params: { type, ob, mode },
    } = matchPath(path, {
      path: '/:type/:ob/:mode',
    })
    */
    //  console.log('watch_location type, ob, mode', type, ob, mode)

    if (
      type === 'pages' ||
      type === 'doc' ||
      type === 'auth' ||
      type === 'config'
    ) {
      if (mode === 'item') {
        //сохоанение id для загрузки item через Websocket rpc
        //yield put({ type: 'fetch/get_add', payload: id || null, ob: 'item_id' })

        if (
          ob !== 'customers' &&
          ob !== 'cloud' &&
          ob !== 'config' &&
          ob !== 'segment' &&
          ob !== 'typework' &&
          ob !== 'auth' &&
          ob !== 'role' &&
          ob !== 'viber' &&
          ob !== 'unit' &&
          ob !== 'unittype' &&
          ob !== 'worktime' &&
          ob !== 'worker' &&
          ob !== 'works' &&
          ob !== 'workingday' &&
          ob !== 'worktimestatus' &&
          ob !== 'workplace'
        ) {
          //yield getItem(urlapi, ob, params)
        }
        if (ob === 'auth') {
          //вторая табл для основной табл в списке
          //yield getList(urlapi, 'auth', 3) //в списке доступа работников workers в body добавить инфо по логинам auth
          // yield getUserSegments(urlapi, params) //в списке доступа  в body добавить  доступные группы
        }
        if (ob === 'auth') {
          //yield getAvatars(urlapi)
          //yield getFilter(urlapi, 'unit')
          //yield getFilter(urlapi, 'unittype')
        }
        if (ob === 'role') {
          //yield getFilter(urlapi, 'unit')
          //yield getFilter(urlapi, 'unittype')
        }

        if (ob === 'customers') {
          //yield getFilter(urlapi, 'segment')
          //yield getFilter(urlapi, 'worker')
          //yield getFilter(urlapi, 'customers') //для viberuserprofile
          //          yield getUserSegments(urlapi, {
          //            id: JSON.parse(localStorage.userData).userId,
          //          }) //в body добавить  доступные группы
        }

        if (ob === 'cloud') {
          //   yield getListUser(urlapi, 'cloud', params, 2) //список юзеров у которых есть в доступе  данное облако
          //  yield getFilter('worker')
        }
        if (false && ob === 'worktime') {
          yield put({
            type: 'app/set',
            payload: { field: 'parent', value: params.parent },
            ob: 'worktime_item',
          })
          yield put({
            type: 'app/set',
            payload: { field: 'parentid', value: params.parentid },
            ob: 'worktime_item',
          })
          yield put({
            type: 'app/set',
            payload: { field: 'parenthtml', value: params.parenthtml },
            ob: 'worktime_item',
          })
        }
      }
      if (mode.startsWith('item-')) {
        //yield getItem_item(urlapi, ob, mode, params)
        //        if (mode === 'item-work') yield getItem_image(ob, mode, params)  сделать - загрузку фото к раотам с облака
      }
      if (mode === 'itemwork') {
        //yield getItem_item2(urlapi, ob, mode, params)
      }
      /*      
      if (mode === 'list') {
        if (
          ob !== 'auth' &&
          ob !== 'cloud' &&
          ob !== 'role' &&
          ob !== 'unit' &&
          ob !== 'unittype' &&
          ob !== 'worktime' &&
          ob !== 'customers' &&
          ob !== 'segment' &&
          ob !== 'viber' &&
          ob !== 'workingday' &&
          ob !== 'workplace' &&
          ob !== 'worktimestatus' &&
          ob !== 'worker' &&
          ob !== 'works' &&
          ob !== 'typework'
        ) {
          yield getList(urlapi, ob, 1)
        }
        //avatars
        //if (ob === 'auth' || ob === 'worker') {yield getAvatars(urlapi)}

        //вторая табл для основной табл в списке
        //if (ob === 'worker') yield getList(urlapi, 'auth', 3) //в списке работников worker в body добавить иніфо по логинам auth

        //фильтры к спискам!!!
        //if (ob === 'unit') yield getFilter(urlapi, 'unittype') // в Доступах добавлен фильтр по Типам доступов

        if (ob === 'customers') {
          //          yield getFilter(urlapi, 'segment')
          // yield getUserSegments(urlapi, {
          //  id: JSON.parse(localStorage.userData).userId,
          // }) //в body добавить  доступные группы
          //          yield getSegments()
        }
      }
*/

      if (mode === 'set') {
        //yield setItem(urlapi, ob, params)
        //if (ob === 'worktime') yield call(go, `/`)
        //else yield call(go, `/${type}/${ob}/list`)
      }
      if (mode === 'save') {
        //yield setItem(urlapi, ob, params)
      }
      if (mode === 'del') {
        //yield delItem(urlapi, ob, params)
        //if (ob === 'worktime') yield call(go, `/`)
        //else yield call(go, `/${type}/${ob}/list`)
      }
      if (mode === 'profile') {
        //yield getItem(urlapi, ob, params)
      }
      if (mode === 'user') {
        //yield getItem(urlapi, 'auth', params)
      }

      if (mode === 'changepsw') {
        //yield changePsw(urlapi, ob, params)
        //        yield call(go, `/auth/auth/setpsw`)
      }

      if (ob === 'login' && mode === 'get') {
        //        yield put({ type: 'fetch/item', payload: Array(), ob: type })
        //        yield getLogin(type, params)
        //        yield call(go, `/${type}/${ob}/input`)
      }
      if (ob === 'login' && mode === 'input') {
        yield setLogin(urlapi, type, params)
        yield call(go, `/`)
        //        yield call(go, `/${type}/${ob}/get`)
      }
      if (ob === 'customers') {
        //        yield getList('worktime', 1)
      }
      if (mode === 'deleteditem') {
        //yield getDeletedItem(urlapi, ob, params)
      }
      if (mode === 'setdeleted') {
        //yield setDeleted(urlapi, ob, params)
      }
    }
  } else {
    //    yield getList('worktime', 1) //в корне читаем Записи
    //    yield getFilter('worker') // фильтр - работники
    //    yield put({ type: 'clear/filter', payload: {}, ob: '' })
    //yield getFilter(urlapi, 'workplace')
    //    yield getFilter('worker')
    //yield put({ type: 'fetch/filter', payload: data.data, ob: name })
  }
  if (path === '/pages/worktime/list') {
    //в поиске по Записям данніе для фильтров и( список  записей  зачем??? )
    //yield getList(urlapi, 'worktime', 2)
    /*    
    yield getFilter(urlapi, 'worker')
    yield getFilter(urlapi, 'customers')
    yield getFilter(urlapi, 'typework')
    yield getFilter(urlapi, 'works')
     */
  }
  if (path === '/pages/worktime/deleted') {
    //в поиске по Записям данніе для фильтров и( список  записей  зачем??? )
    //    yield getDeletedWorktime({ arg: 'deletedworktime' }, 2)
    //    yield getFilter(urlapi, 'worker')
    //    yield getFilter(urlapi, 'customers')
    //    yield getFilter(urlapi, 'typework')
    //    yield getFilter(urlapi, 'works')
  }

  if (path === '/pages/worktime/item-work') {
    //в поиске по Записям данніе для фильтров и( список  записей  зачем??? )
    //yield getFilter(urlapi, 'typework')
  }
  if (path === '/pages/worktime/item-work') {
    //в поиске по Записям данніе для фильтров и( список  записей  зачем??? )
    //yield getFilter(urlapi, 'typework')
  }
  if (path === '/pages/works/list') {
    //в поиске по Записям данніе для фильтров и( список  записей  зачем??? )
    //yield getFilter(urlapi, 'typework')
  }
  if (path === '/auth/auth/item-unit') {
    //в поиске по Записям данніе для фильтров и( список  записей  зачем??? )
    //yield getFilter(urlapi, 'unittype')
  }
  if (path === '/auth/role/item-unit') {
    //в поиске по Записям данніе для фильтров и( список  записей  зачем??? )
    //yield getFilter(urlapi, 'unittype')
  }
  if (path === '/pages/customers/item') {
    //в поиске по Записям данніе для фильтров и( список  записей  зачем??? )
    // yield getList(urlapi, 'worktime', 2)
  }
}

function* watch_fetch_getimg(action) {
  let { urlapi } = action
  yield getImg(urlapi, action)
}

function* watch_fetch_mega(action) {
  let { urlapi } = action
  yield getMegaStorage(urlapi, action)
}

function* watch_fetch_access(action) {
  let { urlapi, clearaccess } = action
  if (clearaccess) {
    yield clearAccessUser()
  } else {
    yield getAccessUser(urlapi, action)
    yield getAvatarUser(urlapi, action)
  }
}

function* watch_fetch_list(action) {
  let { urlapi } = action
  yield getList(urlapi, action.ob, action.mode || 2)
}
function* watch_fetch_filter(action) {
  let { urlapi } = action
  yield getFilter(urlapi, action.ob)
}
function* watch_fetch_getworktime(action) {
  let { urlapi } = action
  yield getWorktime(urlapi, action)
}

function* watch_fetch_message(action) {
  const { urlapi, id, firstmessage, act } = action
  if (act === 'create') yield createMessage(urlapi, action)
  if (act === 'set') yield setMessage(urlapi, action)
  if (act === 'getcustomermessages') yield getCustomerMessages(urlapi, action)
  if (act === 'getworktimemessages') yield getWorktimeMessages(urlapi, action)
}

function* watch_fetch_newcustomer(action) {
  const { urlapi, ob, arg } = action
  //  console.log(action)
  //  console.log(ob, arg)

  yield setItem(urlapi, ob, arg)
}

function* watch_fetch_tbl(action) {
  const { urlapi, act } = action
  //  console.log(action)

  if (act === 'setroleunits') yield setRoleUnits(urlapi, action)

  if (act === 'setuserunits') yield setUserUnits(urlapi, action)
}

export default function* rootSaga() {
  yield takeEvery(ROUTER_ON_LOCATION_CHANGED, watch_location)

  yield takeEvery(FETCH_TBL, watch_fetch_tbl)

  yield takeEvery(FETCH_GETWT, watch_fetch_getworktime)
  yield takeEvery(FETCH_NEWCUSTOMER, watch_fetch_newcustomer)

  yield takeEvery(FETCH_MESSAGE, watch_fetch_message)

  yield takeEvery(FETCH_FILTER, watch_fetch_filter)
  yield takeEvery(FETCH_LIST, watch_fetch_list)
  yield takeEvery(FETCH_GETIMG, watch_fetch_getimg)
  yield takeEvery(FETCH_ACCESS, watch_fetch_access)
  yield takeEvery(FETCH_MEGA, watch_fetch_mega)
}
