import React, { useContext, useState, useCallback, useEffect } from "react";

import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";

import ProductList from "../components/product/ProductList/ProductList";

import {
  ImageButton,
  ImageSrc,
  ImageBackdrop,
  Image,
  ImageMarked,
} from "../ext/ImageButton"; //material/Button";

import { Divider, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Check from "@mui/icons-material/Check";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView, { treeViewClasses } from "@mui/lab/TreeView";
import TreeItem, { useTreeItem, treeItemClasses } from "@mui/lab/TreeItem";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { styled as mstyled } from "@mui/material/styles";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import WarehouseIcon from "@mui/icons-material/Warehouse";

import { useTable } from "react-table";
import styled from "styled-components";
import { useMediaQuery } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Typography from "@material-ui/core/Typography";

import { Card, CardContent } from "@mui/material";
import { useTranslate } from "../lib/translate/i18n/useTranslate";
import { AuthContext } from "../context/AuthContext";
import { getURLAPI } from "../utils/getURL";

import { default as Spinner } from "../components/Spinner";

import GridTable from "../lib/react-grid-table/index";

import Paper from "@mui/material/Paper";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Draggable from "react-draggable";

import { Fancybox } from "../ext/Fancybox";

import Cart from "../containers/Cart";
import "../containers/Cart/cart.css";
import { handle_Cart } from "../containers/Cart/cartactions.js";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid #c3c3c3;
    border-radius: 10px;
    border-collapse: initial;
    tr {
      border-radius: 0px;

      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #c3c3c3;
      border-right: 1px solid #c3c3c3;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Styles2 = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const defaultPropGetter = () => ({});

var columns = [];
var data = [];

const ProductVidList = () => {
  const app = useSelector((state) => state.app);
  const navigation = useSelector((state) => state.navigation);
  const cart = useSelector((state) => state.cart);
  const body = useSelector((state) => state.app.body);
  const access = useSelector((state) => state.app.access.access);
  const isadmin = (access && access.isadmin) || false;
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  let isCartOpen = navigation.isCartOpen;

  const TOGGLE_CART = "src/Navigation/TOGGLE_CART";
  const toggleCart = () => {
    dispatch({
      type: TOGGLE_CART,
    });
  };

  useEffect(() => {
    try {
      handle_Cart(dispatch);
    } catch (err) {
      console.log("deblock error ", err);
    }
  }, []);

  const nameTheme = access && access.user && access.user.theme;
  const translate = useTranslate();

  let borderbox = "1px solid black";
  if (nameTheme) {
    borderbox = nameTheme !== "dark" ? "1px solid black" : "1px solid grey";
  }

  var vids = [];

  const [vid2, setvid2] = useState(auth.Worker || null); //[])

  const changevid = (event, newValue) => {
    setvid2(newValue);
    auth.setWorker(newValue);
  };

  const [ColumnOn, setupColumnOn] = useState();

  const handleChangeColumn = useCallback((event) => {
    setupColumnOn(!ColumnOn);
  });

  const [StockOn, setupStockOn] = useState();
  const [ShowFancybox, setShowFancybox] = React.useState(false);
  const [FancyboxFotos, setupFancyboxFotos] = useState(null);

  const [maxWidth, setmaxWidth] =
    React.useState("lg"); /*  "xs"  "sm"  "md"  "lg"  "xl"  */

  const handleChangeStock = useCallback((event) => {
    setupStockOn(!StockOn);
  });

  const [NumStockOn, setupNumStockOn] = useState(true);

  const handleChangeNumStock = useCallback((event) => {
    setupNumStockOn(!NumStockOn);
  });

  const [OptPriceOn, setupOptPriceOn] = useState(true);

  const handleChangeOptPriceOn = useCallback((event) => {
    setupOptPriceOn(!OptPriceOn);
  });

  const [ShowGalleryOn, setupShowGalleryOn] = useState(true);

  const handleChangeShowGalleryOn = useCallback((event) => {
    setupShowGalleryOn(!ShowGalleryOn);
  });

  const [selectedfolder, setupSelectedFolder] = useState();

  //START get all str from table Roles
  //console.log('Refresh!!! customers...')
  const [startRefreshFilter, setStartRefreshFilter] = useState(true);
  const [startRefresh, setStartRefresh] = useState(true);

  //замыкание
  const ws_client = auth.ws_client; //body.ws_client

  const Get_URL_API = function () {
    let result;
    let config = auth?.config?.config;
    for (let c in config) {
      if (config[c].type === "URL_API") result = config[c].url;
    }
    console.log(result);
    return result;
  };
  let URL_API = Get_URL_API();
  //  const item_id = body.item_id

  //  const readonly = body.readonly

  const GetData_handle = function (_result) {
    const { tableName, result, readonly, action } = _result;
    //console.log('result handle1 ', result

    if (tableName === "Vts") {
      dispatch({
        type: "fetch/get_add", //'fetch/filter', //'fetch/get_add',
        payload: result,
        ob: "vids",
      });
    }

    if (tableName === "Tovs") {
      dispatch({
        type: "fetch/get_add", //'fetch/get_add',
        payload: result,
        ob: "tovs",
      });
    }
  };

  const Refresh_handle = async function (result, event, parent) {
    const { tableName, action, metod } = result;
    /*console.log(
      'Refresh_handle ',
      event,
      new Date().toLocaleTimeString(),
      result
    )*/
    setStartRefresh(true);
  };

  const paramsFilter = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Vts",
        action: {
          metod: "get",
        },
      },
    ],
  };

  const params = {
    URL_WS: getURLAPI(auth.config, "URL_WS"), //get address WebSocket BK from config
    auth: { userId: auth.userId, tocken: auth.token },
    handleGetData: GetData_handle,
    //handleChangeData: Refresh_handle,
    handleSetRefresh: setStartRefresh,
    handleErrorRead: null,
    handleErrorChange: null,
    models: [
      {
        model_call_nm: "Model_read",
        tableName: "Tovs",
        action: {
          metod: "get",
        },
      },
    ],
  };

  // close page
  useEffect(
    () => () => {
      try {
        dispatch({
          type: "fetch/get",
          payload: true,
          ob: "close",
        });
      } catch (err) {
        console.log("deblock error ", err);
      }
    },
    []
  );

  if (ws_client && ws_client.ws_login && startRefreshFilter) {
    setStartRefreshFilter(false);
    ws_client.open_process(paramsFilter);
  }

  if (ws_client && ws_client.ws_login && startRefresh) {
    setStartRefresh(false);
    ws_client.open_process(params);
  }

  //END get all str from table Roles

  //**************************************************************** */

  const StyledTreeItemRoot = mstyled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "&.Mui-expanded": {
        fontWeight: theme.typography.fontWeightRegular,
      },
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: "var(--tree-view-color)",
      },
      [`& .${treeItemClasses.label}`]: {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 25,
      [`& .${treeItemClasses.content}`]: {
        paddingLeft: theme.spacing(2),
      },
    },
  }));

  function StyledTreeItem(props) {
    const {
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      labelText,
      Id,
      parentId,
      level,
      ...other
    } = props;

    const [contextMenu, setContextMenu] = React.useState(null);

    const handleContextMenu = (event) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    };

    const handleClose = () => {
      console.log("Menu Close click nodeId", Id);

      setContextMenu(null);
    };

    const handleAddGroup = () => {
      //      alert('Добавляем группу складов')
      console.log("Menu AddGroup click nodeId", Id);
      setContextMenu(null);
    };

    const handleDelGroup = () => {
      alert("Удаляем группу складов");
      console.log("Menu DelGroup click nodeId", Id);
      setContextMenu(null);
    };

    const handleAddWarehouse = () => {
      //      alert('Добавляем новый склад')
      console.log("Menu AddWarehouse click nodeId", Id);
      setContextMenu(null);
    };

    const handleEditWarehouse = () => {
      alert("Редактируем склад");
      console.log("Menu EditWarehouse click nodeId", Id);
      setContextMenu(null);
    };

    return (
      <>
        <StyledTreeItemRoot
          label={
            <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
              <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
              <div
                //onContextMenu={handleContextMenu}
                style={{ cursor: "context-menu" }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "inherit", flexGrow: 1 }}
                >
                  {labelText}
                </Typography>
                <Typography
                  variant="caption"
                  color="inherit"
                  /*
                <Menu
                  open={contextMenu !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenu !== null
                      ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                      : undefined
                  }
                >
                  {(level === 0 || level === 1) && (
                    <>
                      <MenuItem
                        //onClick={handleAddGroup}
                        component={Link}
                        to="/pages/productvidfolders/item"
                        state={{
                          // id: Id, //cell.row.original['worktime_id'],
                          arg: {
                            parentid: Id,
                          },
                        }}
                      >
                        <ListItemIcon>
                          <AddCircleOutlineIcon fontSize="small" />
                        </ListItemIcon>
                        {translate('resources.productvid.add_group')}
                      </MenuItem>
                      <MenuItem
                        //onClick={handleDelGroup}
                        component={Link}
                        to="/pages/productvidfolders/item"
                        state={{
                          id: Id, //cell.row.original['worktime_id'],
                          //                         parentid: parentId,
                        }}
                      >
                        <ListItemIcon>
                          <Check fontSize="small" />
                        </ListItemIcon>
                        {translate('resources.productvid.edit_group')}
                      </MenuItem>
                    </>
                  )}
                  {level === 2 && (
                    <MenuItem
                      //onClick={handleDelGroup}
                      component={Link}
                      to="/pages/productvidfolders/item"
                      state={{
                        id: Id, //cell.row.original['worktime_id'],
                        //                         parentid: parentId,
                      }}
                    >
                      <ListItemIcon>
                        <Check fontSize="small" />
                      </ListItemIcon>
                      {translate('resources.productvid.edit_group')}
                    </MenuItem>
                  )}
                  {level !== 3 && (
                    <MenuItem
                      //                      onClick={handleAddWarehouse}
                      component={Link}
                      to="/pages/productvid/item"
                      state={{
                        // id: Id, //cell.row.original['worktime_id'],
                        arg: {
                          vtid: Id,
                          vtname: labelText,
                        },
                      }}
                    >
                      <ListItemIcon>
                        <AddCircleOutlineIcon fontSize="small" />
                      </ListItemIcon>
                      {translate('resources.productvid.add_productvid')}
                    </MenuItem>
                  )}
                  {level === 3 && (
                    <>
                      <MenuItem
                        //                      onClick={handleEditWarehouse}
                        component={Link}
                        to="/pages/productvid/item"
                        state={{
                          id: Id, //cell.row.original['worktime_id'],
                        }}
                      >
                        <ListItemIcon>
                          <Check fontSize="small" />
                        </ListItemIcon>
                        {translate('resources.productvid.edit_productvid')}
                      </MenuItem>
                    </>
                  )}
                </Menu>
                */
                >
                  {labelInfo}
                </Typography>
              </div>
            </Box>
          }
          style={{
            "--tree-view-color": color,
            "--tree-view-bg-color": bgColor,
          }}
          {...other}
        />
      </>
    );
  }

  StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    Id: PropTypes.string,
    level: PropTypes.string,
  };

  const productvid = body.tovs; //productvid

  const productvidfolders = body.vids; //app.filter.productvidfolders_filter
  let folders = [
    {
      id: -1,
      name: "<Все>",
      fchild: [],
      wchild: [],
    },
  ];
  for (var f0 in productvidfolders) {
    if (!productvidfolders[f0].parentid)
      folders.push({
        id: productvidfolders[f0].id,
        name: productvidfolders[f0].name,
        fchild: [],
        wchild: [],
      });
  }

  for (var f1 in productvidfolders) {
    if (productvidfolders[f1].parentid)
      for (var fo1 in folders) {
        if (folders[fo1].id === productvidfolders[f1].parentid) {
          folders[fo1].fchild.push({
            id: productvidfolders[f1].id,
            name: productvidfolders[f1].name,
            fchild: [],
            wchild: [],
          });
        }
      }
  }

  for (var f2 in productvidfolders) {
    if (productvidfolders[f2].parentid)
      for (var fo2 in folders) {
        if (folders[fo2].fchild.length > 0) {
          for (var ch1 in folders[fo2].fchild) {
            if (
              folders[fo2].fchild[ch1].id === productvidfolders[f2].parentid
            ) {
              folders[fo2].fchild[ch1].fchild.push({
                id: productvidfolders[f2].id,
                name: productvidfolders[f2].name,
                fchild: [],
                wchild: [],
              });
            }
          }
        }
      }
  }

  for (var wh in productvid) {
    for (var ff in folders) {
      if (folders[ff].id === productvid[wh].vtid)
        folders[ff].wchild.push({
          id: productvid[wh].id,
          name: productvid[wh].name,
          art: productvid[wh].art,
        });

      if (folders[ff].fchild.length > 0) {
        for (var ff1 in folders[ff].fchild) {
          if (folders[ff].fchild[ff1].id === productvid[wh].vtid)
            folders[ff].fchild[ff1].wchild.push({
              id: productvid[wh].id,
              name: productvid[wh].name,
              art: productvid[wh].art,
            });

          if (folders[ff].fchild[ff1].fchild.length > 0) {
            for (var ff2 in folders[ff].fchild[ff1].fchild) {
              if (
                folders[ff].fchild[ff1].fchild[ff2].id === productvid[wh].vtid
              )
                folders[ff].fchild[ff1].fchild[ff2].wchild.push({
                  id: productvid[wh].id,
                  name: productvid[wh].name,
                  art: productvid[wh].art,
                });

              if (folders[ff].fchild[ff1].fchild[ff2].fchild.length > 0) {
                for (var ff3 in folders[ff].fchild[ff1].fchild[ff2].fchild) {
                  if (
                    folders[ff].fchild[ff1].fchild[ff2].fchild[ff3].id ===
                    productvid[wh].vtid
                  )
                    folders[ff].fchild[ff1].fchild[ff2].fchild[ff3].wchild.push(
                      {
                        id: productvid[wh].id,
                        name: productvid[wh].name,
                        art: productvid[wh].art,
                      }
                    );
                }
              }
            }
          }
        }
      }
    }
  }

  //************************************************************** */
  const [filter, setfilter] = useState(null);

  columns = [
    {
      Header: "№",
      accessor: "num",
    },
  ];

  if (ColumnOn) {
    columns.push({
      Header: "Вид ",
      accessor: "parentfolders",
    });

    columns.push({
      Header: "Подвид",
      accessor: "folders",
    });

    columns.push({
      Header: "Артикул",
      accessor: "art",
    });
  }

  columns.push({
    Header: translate("resources.productvid.fields.name"),
    accessor: "name",
  });

  columns.push({
    Header: OptPriceOn
      ? translate("resources.price.fields.price3")
      : translate("resources.price.fields.price2"),
    accessor: "price",
  });

  if (StockOn || !isXSmall) {
    if (NumStockOn) {
      columns.push({
        Header: translate("resources.price.fields.stock") + " 1",
        accessor: "stock1",
      });
    } else {
      columns.push({
        Header: translate("resources.price.fields.stock") + " 2",
        accessor: "stock2",
      });
    }
  }
  /*{
      Header: 'Коментарий',
      accessor: 'comments',
    },*/
  /*
    {
      Header: 'Тип',
      accessor: 'type',
    },
    
    {
      Header: 'Т',
      accessor: 'height',
    },
    {
      Header: 'Ш',
      accessor: 'width',F
    },
    {
      Header: 'Д',
      accessor: 'long',
    },
    */

  data = [];

  let num = 1;
  let productvids = body.tovs; //productvid

  if (selectedfolder && !isXSmall) {
    productvids = productvids.filter((item) => {
      //        console.log(item)

      return (
        (item.id === selectedfolder.item.id && selectedfolder.level === 3) ||
        (item.vtid === selectedfolder.item.id && selectedfolder.level === 1) ||
        (item.vts.parentid === selectedfolder.item.id &&
          selectedfolder.level === 0) ||
        selectedfolder.item.id === -1 ||
        false
      );

      //        return (
      //          item.unittypeid === unittypeid || unittypeid === null //&&
      //      (findTypeWorks(item, typeworkid) || typeworkid === null) &&
      //      (findWorks(item, workid) || workid === null) &&
      //      (item.customersid === customerid || customerid === null)
      //        )
    });
  }

  if (productvids && vid2 && isXSmall) {
    productvids = productvids.filter((item) => {
      //        console.log(item)

      return (
        //(item.id === vid2.value.id && vids.level === 3) ||
        (item.vtid === vid2.value && vid2.level === 1) ||
        (item.vts.parentid === vid2.value && vid2.level === 0) ||
        vid2.value === -1 ||
        false
      );

      //        return (
      //          item.unittypeid === unittypeid || unittypeid === null //&&
      //      (findTypeWorks(item, typeworkid) || typeworkid === null) &&
      //      (findWorks(item, workid) || workid === null) &&
      //      (item.customersid === customerid || customerid === null)
      //        )
    });
  }

  if (productvids) {
    /*
    console.log('vid2', vid2)
    console.log('customer2', customer2)
    console.log('date1', date1)
    console.log('date2', date2)
    console.log('workerid', workerid)
    console.log('customerid', customerid)
    //console.log(customer2['value']['0'].value)
    //workerid = null
    // customerid = null
    */
    if (filter)
      productvids = productvids.filter((item) => {
        //        console.log('item', item)
        //        console.log('item.name', item.name)
        return (
          (item.name &&
            item.name.toLowerCase().includes(filter.toLowerCase())) ||
          (item.lastName &&
            item.lastName.toLowerCase().includes(filter.toLowerCase())) ||
          (item.tel1 &&
            item.tel1.toLowerCase().includes(filter.toLowerCase())) ||
          (item.tel2 &&
            item.tel2.toLowerCase().includes(filter.toLowerCase())) ||
          (item.comments &&
            item.comments.toLowerCase().includes(filter.toLowerCase())) ||
          false
        );
        //[item.name].contains('filter')
      });
  }
  const get_price = (prices, plevelid) => {
    let price = "0";
    for (let i in prices) {
      if (prices[i].plevelid === plevelid) price = prices[i].price;
    }
    price = parseInt(price);

    return price;
  };

  for (var iw in productvids) {
    let fotos = productvids[iw].fotos;
    let isfoto = !fotos || fotos?.length > 0;
    let urlfoto = "";
    if (isfoto && fotos.length > 0) {
      //console.log(fotos);
      urlfoto =
        URL_API +
        "/files" +
        "?dirname=" +
        fotos[0].dirname +
        "&name=" +
        fotos[0].filename;
    }

    data.push({
      num: num++,
      _id: productvids[iw].id,
      name: productvids[iw].name,
      art: productvids[iw].art,
      price: OptPriceOn
        ? productvids[iw].prices && get_price(productvids[iw].prices, "5")
        : productvids[iw].prices && get_price(productvids[iw].prices, "4"), //опт 3
      //      price2: productvids[iw].prices && get_price(productvids[iw].prices, "4"), //опт 2
      parentfolders:
        productvids[iw].vts &&
        productvids[iw].vts.parent &&
        productvids[iw].vts.parent.name,
      folders: productvids[iw].vts && productvids[iw].vts.name,
      imageURL: urlfoto,
      aspectratio: productvids[iw].fotos
        ? productvids[iw].fotos[0]?.aspectratio
        : null,
      comments: productvids[iw].cmnt,
      productvid: productvids[iw],
    });
  }

  for (var i in productvidfolders) {
    vids.push({
      label: productvidfolders[i].parentid
        ? "*   " + productvidfolders[i].name
        : productvidfolders[i].name,
      value: productvidfolders[i].id,
      level: productvidfolders[i].parentid ? 1 : 0,
    });
  }

  //          body.productvids.map((Item) => <ItemList Item={Item} />)}

  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {(column.id === "price" && (
                    <Button
                      size="small"
                      //                        component={Link}
                      color="primary"
                      variant={OptPriceOn ? "contained" : "outlined"}
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                      onClick={(item) => {
                        handleChangeOptPriceOn();
                      }}
                    >
                      {column.render("Header")}
                    </Button>
                  )) ||
                    ((column.id === "stock1" || column.id === "stock2") && (
                      <Button
                        size="small"
                        //                        component={Link}
                        color="primary"
                        variant={NumStockOn ? "contained" : "outlined"}
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                        onClick={(item) => {
                          handleChangeNumStock();
                        }}
                      >
                        {column.render("Header")}
                      </Button>
                    )) ||
                    column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  let fotos = cell.row.original.productvid.fotos;
                  let isfoto = !fotos || fotos?.length > 0;
                  let urlfoto = "";
                  if (isfoto) {
                    console.log(fotos);
                    urlfoto =
                      URL_API +
                      "/files" +
                      "?dirname=" +
                      fotos[0].dirname +
                      "&name=" +
                      fotos[0].filename;
                  }
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                      //align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id !== "num" && (
                        <Link
                          as={Link}
                          variant="outline-primary"
                          //style={{ marginBottom: 5 }}

                          to={{
                            pathname: "/pages/productvid/item",
                          }}
                          state={{
                            id: cell.row.original.productvid.id, //cell.row.original['worktime_id'],
                          }}
                          onClick={() => {
                            dispatch({
                              type: "fetch/get", //_add',
                              payload: cell.row.original.productvid.id,
                              ob: "item_id",
                            });
                          }}
                        >
                          {cell.render("Cell")}
                        </Link>
                      )) ||
                        (cell.column.id === "num" && (
                          <ImageButton
                            focusRipple
                            sx={{
                              width: 100,
                              height: 100,
                              ml: 1,
                              alignItems: "center",
                              "&:hover": {
                                backgroundColor: "primary.light",
                                //backgroundColor: 'primary.dark',
                                opacity: [0.9, 0.8, 0.7],
                              },
                            }}
                            variant={isfoto ? "contained" : "outlined"}
                            onClick={() => {
                              if (fotos?.length > 0) {
                                setupFancyboxFotos(
                                  cell.row.original.productvid
                                ); //fotos);
                                setShowFancybox(true);
                              }
                            }}
                          >
                            <ImageSrc
                              style={{ backgroundImage: `url(${urlfoto})` }}
                            />
                            <ImageBackdrop className="MuiImageBackdrop-root" />
                            <Image>
                              <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                sx={(theme) => ({
                                  position: "relative",
                                  p: 4,
                                  pt: 2,
                                  pb: `calc(${theme.spacing(1)} + 6px)`,
                                })}
                              >
                                {fotos?.title}
                                <ImageMarked className="MuiImageMarked-root" />
                              </Typography>
                            </Image>

                            {cell.render("Cell")}
                          </ImageButton>
                        )) ||
                        cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  /*
      {FancyboxFotos?.length > 0 && (
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >

          {FancyboxFotos.map((item) => {
            console.log("Fancy item", item);
            return (
              <>
                <a
                  data-fancybox="gallery"
                  href="https://lipsum.app/id/60/1600x1200"
                >
                  <img
                    src="https://lipsum.app/id/60/200x150"
                    width="200"
                    height="150"
                  />
                </a>
              </>
            );
          })}
        </Fancybox>
      )}

*/
  const handleAlertClose = () => {
    setShowFancybox(false);
    //    setTitleAlertDialog("");
    //    setTextAlertDialog("");
    //    setOpenAlertDialog(false);
  };

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  function Cart1(props) {
    return (
      <>
        <div
          classname="Cart"
          style={{
            padding: "2rem 0",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div class="cart-header">
            <button
              aria-label="close the cart"
              class="input-btn md icon-only icon-left border-0"
              type="button"
              style={{ borderRadius: 3 }}
            >
              <div class="btn-icon">
                <span class="close-icon" aria-hidden="true"></span>
              </div>
            </button>

            <div class="cart-body">
              <div class="cart-list">
                <div class="item-box">
                  <div class="item-details">
                    <div class="container">
                      <div class="mb-2 align-items-center row">
                        <div class="pr-0 col-10">
                          <div class="d-flex align-items-center">
                            <img
                              class="item-image mr-2"
                              src="https://mernstore-bucket.s3.us-east-2.amazonaws.com/mbp-spacegray-select-202011_GEO_US.jpeg"
                            />
                            <a
                              class="item-link one-line-ellipsis"
                              href="/product/macbook-pro"
                            >
                              <h2 class="item-name one-line-ellipsis">
                                Macbook Laptop
                              </h2>
                            </a>
                          </div>
                        </div>
                        <div class="text-right col-2">
                          <button
                            aria-label="remove Macbook Laptop from cart"
                            class="input-btn md icon-only icon-left border-0"
                            type="button"
                            style={{ borderRadius: 3 }}
                          >
                            <div class="btn-icon">
                              <i class="icon-trash" aria-hidden="true"></i>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="mb-2 align-items-center row">
                        <div class="col-9">
                          <p class="item-label">price</p>
                        </div>
                        <div class="text-right col-3">
                          <p class="value price"> $2500</p>
                        </div>
                      </div>
                      <div class="mb-2 align-items-center row">
                        <div class="col-9">
                          <p class="item-label">quantity</p>
                        </div>
                        <div class="text-right col-3">
                          <p class="value quantity"> 1</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function ShowGallery(props) {
    return (
      <>
        <div
          classname="ProductOverview"
          style={{
            padding: "2rem 0",
          }}
        >
          <ProductList products={data} isPR3={OptPriceOn} />
        </div>
      </>
    );
  }

  function FancyboxDialog(props) {
    return (
      <>
        {/* Fancybox Dialog */}
        <Dialog
          open={ShowFancybox}
          maxWidth={maxWidth}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          onClose={handleAlertClose}
          PaperProps={{
            component: "form",
            onSubmit: async (event) => {
              event.preventDefault();
              //const formData = new FormData(event.currentTarget);
              //const formJson = Object.fromEntries(formData.entries());
              handleAlertClose();
            },
          }}
        >
          <DialogTitle
            style={{
              fontSize: "1.60rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          ></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                {FancyboxFotos.fotos?.map((item) => {
                  console.log("Fancy item", item);
                  let foto =
                    URL_API +
                    "/files" +
                    "?dirname=" +
                    item.dirname +
                    "&name=" +
                    item.filename;
                  console.log("Fancy foto", foto);

                  return (
                    <>
                      <a
                        data-fancybox="gallery"
                        href={foto} //"https://lipsum.app/id/60/1600x1200"
                      >
                        <img
                          src={foto} //"https://lipsum.app/id/60/200x150"
                          width="200"
                          height="150"
                        />
                      </a>
                    </>
                  );
                })}
              </Fancybox>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                ml: 1,
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "primary.light",
                  //backgroundColor: 'primary.dark',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              variant="contained"
              onClick={handleAlertClose}
            >
              Return
            </Button>
            {false && (
              <Button
                sx={{
                  ml: 1,
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "primary.light",
                    //backgroundColor: 'primary.dark',
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
                variant="contained"
                type="submit"
              >
                Ok
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <Box maxWidth="180em">
        <div
          style={{
            display:
              !FancyboxFotos?.fotos || FancyboxFotos?.fotos?.length === 0
                ? ""
                : "none",
          }}
        >
          <Card>
            <CardContent>
              <>
                <div
                  //            className="hstack gap-2"
                  style={{
                    marginTop: 0,
                    display: "flex",
                    //justifyContent: 'space-between',
                  }}
                >
                  {!isXSmall && (
                    <div>
                      <>
                        {false && (
                          <Box
                            sx={{
                              marginBottom: 0,
                              display: "flex",
                              alignItems: "center",
                              p: 0.5,
                              pr: 0,
                            }}
                          >
                            <div
                              //            className="hstack gap-2"
                              style={{
                                marginTop: 0,
                                display: "flex",
                                //            justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  marginTop: 1,
                                  fontWeight: "inherit",
                                  flexGrow: 1,
                                }}
                              >
                                {translate(
                                  "resources.productvid.fields.folders"
                                )}
                              </Typography>
                              <div>
                                <Tooltip
                                  title={translate(
                                    "resources.productvid.tooltip.add_rootfolder"
                                  )} //"Добавление корневой Группы видов номенклатуры"
                                  placement="right-end" //top-start"
                                >
                                  <IconButton
                                    component={Link}
                                    to="/pages/productvidfolders/item"
                                    state={{
                                      // id: Id, //cell.row.original['worktime_id'],
                                      arg: {
                                        parentid: null,
                                      },
                                    }}
                                  >
                                    <AddCircleOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div></div>
                            </div>
                          </Box>
                        )}
                      </>
                      <div style={{ borderSpacing: 0, border: borderbox }}>
                        <TreeView
                          aria-label="file system navigator"
                          defaultCollapseIcon={<ExpandMoreIcon />}
                          defaultExpandIcon={<ChevronRightIcon />}
                          sx={{
                            height: 480,
                            flexGrow: 1,
                            width: 300,
                            //                maxWidth: 500,
                            overflowY: "auto",
                          }}
                        >
                          {folders.map((line0) => (
                            <StyledTreeItem
                              //ustomTreeItem
                              //StyledTreeItem

                              nodeId={line0.id.toString()}
                              key={line0.id.toString()}
                              Id={line0.id.toString()}
                              parentId={
                                line0.parentid && line0.parentid.toString()
                              }
                              //level={0}
                              labelText={line0.name}
                              labelIcon={FolderOpenIcon}
                              onClick={(item) => {
                                //                    console.log('onClick id', line0.id)
                                setupSelectedFolder({ level: 0, item: line0 });
                              }}
                              //                  onClick={(item) => {
                              //                    console.log('onClick id', line0.id)
                              //                  }}
                              /*                showbutton={true}
                handleadd={(item) => {
                  handleAdd(line0.id)
                }}
                handledel={(item) => {
                  handleDel(line0.id)
                }}
                */
                            >
                              {line0.fchild &&
                                line0.fchild.map((line1) => (
                                  <StyledTreeItem
                                    //CustomTreeItem
                                    //StyledTreeItem
                                    nodeId={line1.id.toString()}
                                    key={line1.id.toString()}
                                    Id={line1.id.toString()}
                                    parentId={
                                      line1.parentid &&
                                      line1.parentid.toString()
                                    }
                                    //level={1}
                                    labelText={line1.name}
                                    labelIcon={FolderOpenIcon}
                                    onClick={(item) => {
                                      //                    console.log('onClick id', line0.id)
                                      setupSelectedFolder({
                                        level: 1,
                                        item: line1,
                                      });
                                    }}
                                    //                        onClick={(item) => {
                                    //                          console.log('onClick id', line1.id)
                                    //                        }}
                                    /*                      showbutton={true}
                      handleadd={(item) => {
                        handleAdd(line1.id)
                      }}
                      handledel={(item) => {
                        handleDel(line1.id)
                      }}
                      */
                                  >
                                    {line1.fchild &&
                                      line1.fchild.map((line2) => (
                                        <>
                                          <StyledTreeItem
                                            //CustomTreeItem
                                            //StyledTreeItem
                                            nodeId={line2.id.toString()}
                                            key={line2.id.toString()}
                                            Id={line2.id.toString()}
                                            parentId={line2.parentid}
                                            //level={2}
                                            labelText={line2.name}
                                            labelIcon={FolderOpenIcon}
                                            onClick={(item) => {
                                              //                    console.log('onClick id', line0.id)
                                              setupSelectedFolder({
                                                level: 2,
                                                item: line2,
                                              });
                                            }}
                                            //                                onClick={(item) => {
                                            //                                  console.log('onClick id', line2.id)
                                            //                                }}
                                          >
                                            {line2.wchild &&
                                              line2.wchild.map((line3) => (
                                                <StyledTreeItem
                                                  //CustomTreeItem
                                                  //StyledTreeItem
                                                  nodeId={line3.id.toString()}
                                                  key={line3.id.toString()}
                                                  Id={line3.id.toString()}
                                                  parentId={line3.parentid}
                                                  //level={3}
                                                  labelText={
                                                    line3.art + " " + line3.name
                                                  }
                                                  labelIcon={WarehouseIcon}
                                                  onClick={(item) => {
                                                    //                    console.log('onClick id', line0.id)
                                                    setupSelectedFolder({
                                                      level: 3,
                                                      item: line3,
                                                    });
                                                  }}
                                                  //                                      onClick={(item) => {
                                                  //                                        console.log('onClick id', line3.id)
                                                  //                                      }}
                                                ></StyledTreeItem>
                                              ))}
                                          </StyledTreeItem>
                                        </>
                                      ))}
                                    {line1.wchild &&
                                      line1.wchild.map((wline2) => (
                                        <StyledTreeItem
                                          //CustomTreeItem
                                          //StyledTreeItem
                                          nodeId={wline2.id.toString()}
                                          key={wline2.id.toString()}
                                          Id={wline2.id.toString()}
                                          //level={3}
                                          labelText={
                                            wline2.art + " " + wline2.name
                                          }
                                          labelIcon={WarehouseIcon}
                                          onClick={(item) => {
                                            //                    console.log('onClick id', line0.id)
                                            setupSelectedFolder({
                                              level: 3,
                                              item: wline2,
                                            });
                                          }}
                                          //                              onClick={(item) => {
                                          //                                console.log('onClick id', wline2.id)
                                          //                              }}
                                        ></StyledTreeItem>
                                      ))}
                                  </StyledTreeItem>
                                ))}
                              {line0.wchild &&
                                line0.wchild.map((wline1) => (
                                  <StyledTreeItem
                                    //CustomTreeItem
                                    //StyledTreeItem
                                    nodeId={wline1.id.toString()}
                                    key={wline1.id.toString()}
                                    Id={wline1.id.toString()}
                                    //level={3}
                                    labelText={wline1.art + " " + wline1.name}
                                    labelIcon={WarehouseIcon}
                                    onClick={(item) => {
                                      //                    console.log('onClick id', line0.id)
                                      setupSelectedFolder({
                                        level: 3,
                                        item: wline1,
                                      });
                                    }}
                                    //                        onClick={(item) => {
                                    //                          console.log('onClick id', wline1.id)
                                    //                        }}
                                  ></StyledTreeItem>
                                ))}
                            </StyledTreeItem>
                          ))}
                        </TreeView>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    <>
                      <Form>
                        <div
                          style={{
                            marginTop: 0,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {isXSmall && (
                            <Autocomplete
                              //multiple
                              id="size-small-outlined" //      id="checkboxes-tags"
                              options={vids}
                              onChange={changevid}
                              size="large" //                            "small"
                              value={vid2} //[...vid2]}
                              //                defaultValue={(vid2 && vid2[0]) || []}
                              limitTags={2}
                              //              filterSelectedOptions
                              isOptionEqualToValue={(props, option) => {
                                let rez = false;
                                if (props.value === option.value) rez = true;
                                return rez;
                              }}
                              selectOnFocus
                              clearOnBlur
                              onFocus={(e) => {
                                //            console.log(e.target.readOnly)
                                return (e.target.readOnly = true);
                              }} //выключение клавиатуры при выборе      handleHomeEndKeys
                              //      disableCloseOnSelect
                              getOptionLabel={(option) => option.label}
                              renderOption={(props, option, { selected }) => {
                                return (
                                  <li
                                    {...props}
                                    style={{ fontSize: "x-large" }}
                                  >
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.label}
                                  </li>
                                );
                              }}
                              style={{
                                fontSize: "xx-large",
                                width: isXSmall ? 210 : 400,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  style={{ fontSize: "xx-large" }}
                                  label={translate("resources.price.changevid")} //'Выберите рабочее место' : 'Выбери мастера'}
                                  variant="outlined"
                                  //          variant="standard"
                                  //          placeholder="Выберите Мастера"
                                />
                              )}
                            />
                          )}
                          <TextField
                            //id="outlined-basic"
                            label="Поиск "
                            variant="outlined"
                            defaultValue={filter}
                            style={{
                              //marginLeft: 10,
                              marginBottom: 10,
                              width: 150,
                            }}
                            onChange={(item) => {
                              setfilter(item.target.value);
                            }}
                          />
                          <Button
                            size="small"
                            //                        component={Link}
                            color="primary"
                            variant={!ShowGalleryOn ? "contained" : "outlined"}
                            sx={{
                              height: 40,
                              marginLeft: 2,
                              marginTop: 1,
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              //handleChangeShowGalleryOn();
                              setupShowGalleryOn(!ShowGalleryOn);
                            }}
                          >
                            #
                          </Button>
                          <Button
                            size="small"
                            //                        component={Link}
                            color="primary"
                            variant={OptPriceOn ? "contained" : "outlined"}
                            sx={{
                              height: 40,
                              marginLeft: 2,
                              marginTop: 1,
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              //handleChangeShowGalleryOn();
                              handleChangeOptPriceOn();
                            }}
                          >
                            Цена опт/розн
                          </Button>

                          {!isXSmall && (
                            <FormControlLabel
                              style={{
                                marginRight: isXSmall ? 0 : 15,
                                marginLeft: isXSmall ? 0 : 10,
                              }}
                              control={
                                <Switch
                                  id={"switch-1"}
                                  name={"column"}
                                  color="primary"
                                  onChange={handleChangeColumn}
                                  //                          onFocus={onFocus}
                                  checked={ColumnOn}
                                  //                      {...sanitizeInputRestProps(rest)}
                                  //                      {...options}
                                  //                      disabled={disabled}
                                />
                              }
                              label={"Вид + Арт."}
                            />
                          )}
                          {isXSmall && (
                            <FormControlLabel
                              style={{
                                marginRight: isXSmall ? 0 : 15,
                                marginLeft: isXSmall ? 0 : 10,
                              }}
                              control={
                                <Switch
                                  id={"switch-1"}
                                  name={"column"}
                                  color="primary"
                                  onChange={handleChangeStock}
                                  //                          onFocus={onFocus}
                                  checked={StockOn}
                                  //                      {...sanitizeInputRestProps(rest)}
                                  //                      {...options}
                                  //                      disabled={disabled}
                                />
                              }
                              label={translate("resources.price.fields.stock")}
                            />
                          )}
                        </div>
                        {!ShowGalleryOn && (
                          <Styles>
                            <Table
                              columns={columns}
                              data={data}
                              getHeaderProps={
                                (nameTheme &&
                                  nameTheme !== "dark" &&
                                  ((row) => {
                                    return {
                                      style: {
                                        //                   background:
                                        //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                                      },
                                    };
                                  })) ||
                                ((row) => {
                                  return {
                                    style: {
                                      color: "#ffffffb3",
                                      //                    background:
                                      //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                                    },
                                  };
                                })
                              }
                              /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
                              getRowProps={
                                (nameTheme &&
                                  nameTheme !== "dark" &&
                                  ((row) => {
                                    return {
                                      style: {
                                        background:
                                          row.index % 2 === 0
                                            ? "rgba(0,0,0,.1)"
                                            : "white",
                                      },
                                    };
                                  })) ||
                                ((row) => {
                                  return {
                                    style: {
                                      background:
                                        row.index % 2 === 0
                                          ? "rgba(0,0,0,.1)"
                                          : "#616161e6", //'#ffffffb3',
                                    },
                                  };
                                })
                              }
                              getCellProps={(cellInfo) => ({
                                //            onClick: (req, res) => onClickCell(cellInfo, req, res),
                                style: { fontSize: "xx-large" },
                              })}
                            />
                          </Styles>
                        )}
                        {ShowGalleryOn && <ShowGallery />}
                      </Form>
                      <br />
                    </>
                  </div>
                </div>
              </>
            </CardContent>
          </Card>
        </div>
        <div
          style={{
            marginTop: 0,
            display:
              !FancyboxFotos || FancyboxFotos?.fotos?.length === 0
                ? "none"
                : "",
          }}
        >
          <Card
            style={{
              marginTop: 0,
              width: "100%",
              height: 540,
              maxHeight: 700,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <CardContent>
              <div
                style={{
                  marginBottom: 20,
                  display: "flex",
                  justifyContent: "space-between",
                  //borderSpacing: 0,
                  //border: borderbox,
                }}
              >
                <div>{FancyboxFotos?.name}</div>
                <Button
                  sx={{
                    ml: 1,
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "primary.light",
                      //backgroundColor: 'primary.dark',
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setupFancyboxFotos(null);
                  }}
                >
                  Exit
                </Button>
              </div>
              <List
                style={{
                  maxHeight: "440px",
                  overflow: "auto",
                }}
              >
                <Fancybox
                  options={{
                    Hash: false,
                    Thumbs: false,
                    compact: false,
                    contentClick: "toggleCover",
                    wheel: "slide",
                    //helpers: { title: "top" },
                    Toolbar: {
                      //*** */
                      items: {
                        title: {
                          tpl: `<div style=margin-left:-200px;margin-right:200px;margin-top:10px>test</div>`,
                          click: () => {
                            window.open(
                              `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                window.location.href
                              )}&t=${encodeURIComponent(document.title)}`,
                              "",
                              "left=0,top=0,width=600,height=300,menubar=no,toolbar=no,resizable=yes,scrollbars=yes"
                            );
                          },
                        },
                      },
                      //*** */
                      display: {
                        left: ["infobar"],
                        middle: ["zoomIn", "zoomOut", "toggle1to1"],
                        right: ["slideshow", "close"],
                      },
                    },
                    Images: {
                      protected: true,
                      Panzoom: {
                        maxScale: 6,
                        panMode: "mousemove",
                        mouseMoveFactor: 1.1,
                        mouseMoveFriction: 0.12,
                      },
                    },
                    Carousel: {
                      infinite: false,
                    },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {FancyboxFotos?.fotos?.map((item) => {
                      console.log("Fancy item", item);
                      let foto =
                        URL_API +
                        "/files" +
                        "?dirname=" +
                        item.dirname +
                        "&name=" +
                        item.filename;
                      console.log("Fancy foto", foto);

                      return (
                        <>
                          <div
                            style={{
                              marginTop: 10,
                              marginLeft: 10,
                              borderSpacing: 0,
                              border: borderbox,
                            }}
                          >
                            <a
                              data-fancybox="gallery"
                              data-src={foto}
                              data-caption={
                                item.comments +
                                "  (" +
                                get_price(FancyboxFotos.prices, "5") +
                                "/" +
                                get_price(FancyboxFotos.prices, "4") +
                                ")"
                              }
                            >
                              <img
                                src={foto}
                                alt={FancyboxFotos?.name}
                                width="200"
                                height="250"
                              />
                            </a>
                            <Divider />
                            <div
                              style={{
                                marginTop: 5,
                                marginLeft: 5,
                                marginBottom: 5,
                              }}
                            >
                              {item.comments +
                                "  (" +
                                get_price(FancyboxFotos.prices, "5") +
                                "/" +
                                get_price(FancyboxFotos.prices, "4") +
                                ")"}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Fancybox>
              </List>
            </CardContent>
          </Card>
        </div>
      </Box>
      {/*      <FancyboxDialog /> */}
      {/* hidden cart drawer */}
      <div
        className={isCartOpen ? "mini-cart-open" : "hidden-mini-cart"}
        aria-hidden={isCartOpen ? false : true}
      >
        <div className="mini-cart">
          <Cart dispatch={dispatch} />
        </div>
        <div
          className={
            isCartOpen ? "drawer-backdrop dark-overflow" : "drawer-backdrop"
          }
          onClick={toggleCart}
        />
      </div>
    </>
  );
};

export default ProductVidList;
