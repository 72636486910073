import React, { useState, useEffect } from 'react'
import { Form, Modal, ListGroup, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useTable } from 'react-table'
import styled from 'styled-components'
import { useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useTranslate } from '../lib/translate/i18n/useTranslate'

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`

const defaultPropGetter = () => ({})

var columns = []
var data = []

const selectItem = (select, Item) => {
  select(Item)
}

const SelectProductVidFolders = ({ field, title, placeholder, value }) => {
  const name = 'productvidfolders'

  const access = useSelector((state) => state.app.access.access)
  const isadmin = (access && access.isadmin) || false
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const nameTheme = access && access.user && access.user.theme
  const translate = useTranslate()

  let greybkcolor = 'rgba(255,255,255,.3)'

  if (nameTheme) {
    greybkcolor =
      nameTheme !== 'dark' ? 'rgba(255,255,255,1.0)' : 'rgba(255,255,255,.3)'
  }
  const [modalShow, setModalShow] = React.useState(false)
  const [filter, setFilter] = useState('')

  let viewtValue = field && field[name] && field[name].name
  if (field && field[name] && field[name].lastName)
    viewtValue = viewtValue + ' ' + field[name].lastName

  const dispatch = useDispatch()
  useEffect(() => {
    modalShow && dispatch({ type: 'FETCH_LIST', ob: name })
  }, [modalShow])

  const select = (Item) => {
    dispatch({
      type: 'app/set',
      payload: {
        id: field,
        field: `${name}id`,
        value: Item,
        ob: name,
      },
      ob: value,
    })

    setModalShow(false)
  }

  const list = useSelector((state) => state.app.current[name]) || []

  let SelectProductVidFolders = list //access && access.user && access.user.SelectProductVidFolders && access.user.SelectProductVidFolders

  let userSelectWarehousefolders =
    access &&
    access.user &&
    access.user.SelectProductVidFolders &&
    access.user.SelectProductVidFolders
  //console.log('userSelectWarehousefolders', userSelectWarehousefolders)
  let userSelectWarehousefolderid = []
  for (let u in userSelectWarehousefolders) {
    userSelectWarehousefolderid.push(
      userSelectWarehousefolders[u].SelectWarehousefolderid
    )
  }
  //console.log('userSelectWarehousefolderid', userSelectWarehousefolderid)
  //console.log('SelectProductVidFolders', SelectProductVidFolders)

  /*
  if (!isadmin)
    SelectProductVidFolders = SelectProductVidFolders.filter((item) => {
      let rez = false
      for (let ii in userSelectWarehousefolderid)
        if (item.id === userSelectWarehousefolderid[ii]) {
          rez = true
        }
      //    console.log('userSelectWarehousefolderid.find(item.id)', userSelectWarehousefolderid, item.id)
      return rez //userSelectWarehousefolderid.find(item.id)
      //[item.name].contains('filter')
    })
*/
  //  console.log('SelectProductVidFolders', SelectProductVidFolders)

  if (filter)
    SelectProductVidFolders = SelectProductVidFolders.filter((item) => {
      //      console.log('item', item)
      //      console.log('item.name', item.name)
      return (
        (item.name && item.name.toLowerCase().includes(filter.toLowerCase())) ||
        (item.lastName &&
          item.lastName.toLowerCase().includes(filter.toLowerCase())) ||
        (item.tel1 && item.tel1.toLowerCase().includes(filter.toLowerCase())) ||
        (item.tel2 && item.tel2.toLowerCase().includes(filter.toLowerCase())) ||
        //        (item.cloudtype.name && item.cloudtype.name.includes(filter)) ||
        false
      )
      //[item.name].contains('filter')
    })

  columns = [
    {
      Header: 'Имя',
      accessor: 'name',
    },
    {
      Header: 'Фамилия',
      accessor: 'lastName',
    },
  ]

  /*
    {
      Header: 'Должность',
      accessor: 'jobTitle',
    },

*/

  if (!isXSmall) {
    columns.push({
      Header: 'Телефоны',
      accessor: 'tel',
    })
  }
  data = []

  //  const SelectProductVidFolders = body.SelectWarehousefolder
  let num = 1
  let user
  let tel2
  for (var iw in SelectProductVidFolders) {
    tel2 = ''
    if (SelectProductVidFolders[iw].tel2)
      tel2 = ' / ' + SelectProductVidFolders[iw].tel2
    data.push({
      num: num++,
      name: SelectProductVidFolders[iw].name,
      lastName: SelectProductVidFolders[iw].lastName,
      jobTitle: SelectProductVidFolders[iw].jobTitle,
      tel: (SelectProductVidFolders[iw].tel1 || '') + tel2,
      SelectWarehousefolder: SelectProductVidFolders[iw],
    })
  }

  //************************* */
  function Table({
    columns,
    data,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
  }) {
    // Use the state and functions returned from useTable to build your UI
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      })

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // Return an array of prop objects and react-table will merge them appropriately
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),

                    getHeaderProps(column),
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // Merge user row props in
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Return an array of prop objects and react-table will merge them appropriately
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        //  getCellProps(cell),
                      ])}
                      align="center"
                      //                    width="140px"
                    >
                      {(cell.column.id === 'name' && (
                        <Button
                          size="small"
                          color="primary"
                          variant="outlined"
                          sx={{ display: 'inline-flex', alignItems: 'center' }}
                          onClick={(req, res) => {
                            const SelectWarehousefolder =
                              cell.row.original.SelectWarehousefolder
                            //                            console.log(cloud.id)
                            //cell.row.original['worktime_id'],

                            dispatch({
                              type: 'app/set',
                              payload: {
                                id: field,
                                field: `${name}id`,
                                value: SelectWarehousefolder,
                                ob: name,
                              },
                              ob: value,
                            })

                            setModalShow(false)
                          }} //onClickCell(cellInfo, req, res),
                          //                        onClick
                        >
                          {cell.render('Cell')}
                        </Button>
                      )) ||
                        cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby={`contained-modal-${name}-vcenter`}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={`contained-modal-${name}-vcenter`}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            id="outlined-basic"
            label="Введите поисковый запрос"
            //variant="standard"
            variant="outlined"
            style={{
              marginBottom: 15,
              background: nameTheme !== 'dark' ? 'rgba(0,0,0,.1)' : '#616161e6', //'#ffffffb3',

              display: 'flex',
              justifyContent: 'space-between',
            }}
            defaultValue={filter}
            onChange={(item) => setFilter(item.target.value)}
          />
          <Styles>
            <Table
              columns={columns}
              data={data}
              getHeaderProps={
                (nameTheme &&
                  nameTheme !== 'dark' &&
                  ((row) => {
                    return {
                      style: {
                        //                   background:
                        //                   row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                      },
                    }
                  })) ||
                ((row) => {
                  return {
                    style: {
                      color: '#ffffffb3',
                      background:
                        nameTheme !== 'dark' ? 'rgba(0,0,0,.1)' : '#616161e6', //'#ffffffb3',
                      //                    background:
                      //                    row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#ffffffb3', //'#616161e6', //'#ffffffb3',
                    },
                  }
                })
              }
              /*          
          getHeaderProps={(column) => ({
            onClick: () => alert('Header!'),
          })}
          getColumnProps={(column) => ({
            onClick: () => alert('Column!'),
          })}
          */
              getRowProps={
                (nameTheme &&
                  nameTheme !== 'dark' &&
                  ((row) => {
                    return {
                      style: {
                        background:
                          row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
                      },
                    }
                  })) ||
                ((row) => {
                  return {
                    style: {
                      background:
                        row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : '#616161e6', //'#ffffffb3',
                    },
                  }
                })
              }
              getCellProps={(cellInfo) => ({
                //                onClick: (req, res) => {
                //                  console.log(cellInfo)
                //                }, //onClickCell(cellInfo, req, res),
                /*                style: {
                  backgroundColor: `hsl(${
                    120 * ((120 - cellInfo.value) / 120) * -1 + 120
                  }, 100%, 67%)`,
                },
              */
              })}
            />
          </Styles>
        </Modal.Body>
      </Modal>

      <TextField
        readOnly
        id="outlined-basic"
        label={placeholder}
        //variant="standard"
        variant="outlined"
        style={{
          marginBottom: 15,
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: greybkcolor,
        }}
        defaultValue={viewtValue || ''} //field && field[name] && field[name].name}
        value={viewtValue || ''} //field && field[name] && field[name].name}
        onClick={() => setModalShow(true)}
        onFocus={(e) => (e.target.readOnly = true)} //выключение клавиатуры при выборе значения
      />
    </>
  )
}

export default SelectProductVidFolders
