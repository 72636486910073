/**
 *
 * CartList
 *
 */

import React from "react";

import { Link } from "react-router-dom";
//import { Container, Row, Col } from 'reactstrap';

import Button from "../../Common/Button";

const CartList = (props) => {
  const { cartItems, handleRemoveFromCart, dispatch } = props;

  const handleProductClick = () => {
    props.toggleCart();
  };

  return (
    <div className="cart-list">
      {cartItems.map((item, index) => (
        <div key={index} className="item-box">
          <div className="item-details">
            <div class="container">
              <div className="mb-2 align-items-center row">
                <div className="pr-0 col-10">
                  <div className="d-flex align-items-center">
                    <img
                      className="item-image mr-2"
                      src={`${
                        item.imageUrl
                          ? item.imageUrl
                          : "/images/placeholder-image.png"
                      }`}
                    />

                    <Link
                      to={`/product/${item.slug}`}
                      className="item-link one-line-ellipsis"
                      onClick={handleProductClick}
                    >
                      <h2 className="item-name one-line-ellipsis">
                        {item.name}
                      </h2>
                    </Link>
                  </div>
                </div>
                <div xs="2" className="text-right col-1">
                  <Button
                    borderless
                    variant="empty"
                    ariaLabel={`remove ${item.name} from cart`}
                    icon={<i className="icon-trash" aria-hidden="true" />}
                    onClick={() => {
                      handleRemoveFromCart(item, dispatch);
                    }}
                  />
                </div>
              </div>
              <div className="mb-2 align-items-center row">
                <div xs="9" class="col-9">
                  <p className="item-label">price</p>
                </div>
                <div xs="3" className="text-right col-3">
                  <p className="value price">{` ${item?.totalPrice} грн.`}</p>
                </div>
              </div>
              <div className="mb-2 align-items-center row">
                <div xs="9" class="col-9">
                  <p className="item-label">quantity</p>
                </div>
                <div xs="3" className="text-right col-3">
                  <p className="value quantity">{` ${item.quantity}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CartList;
